import { ProtectedRestApi } from "./";
import { ItContract, ItSupportTicket, ServiceResult } from "../models";
import { ItContractCreatePayload, ItContractUpdatePayload, ItContractClosePayload, ItScheduledTicketSolvePayload, ItSupportTicketCreatePayload, ItSupportTicketSolvePayload } from "../payloads";

export class ItContractApi extends ProtectedRestApi {
    private static baseUrl = "/api/it-contract";

    public getMy(): Promise<ServiceResult<ItContract[]>> {
        return this.httpGet(`${ItContractApi.baseUrl}/my`) as Promise<ServiceResult<ItContract[]>>;
    }

    public getByClient(clientId: number): Promise<ServiceResult<ItContract[]>> {
        return this.httpGet(`${ItContractApi.baseUrl}/by-client/${clientId}`) as Promise<ServiceResult<ItContract[]>>;
    }

    public getByTechnician(technicianId: number): Promise<ServiceResult<ItContract[]>> {
        return this.httpGet(`${ItContractApi.baseUrl}/by-technician/${technicianId}`) as Promise<ServiceResult<ItContract[]>>;
    }

    public getAll(): Promise<ServiceResult<ItContract[]>> {
        return this.httpGet(`${ItContractApi.baseUrl}/all`) as Promise<ServiceResult<ItContract[]>>;
    }

    public getAllIncludingEnded(): Promise<ServiceResult<ItContract[]>> {
        return this.httpGet(`${ItContractApi.baseUrl}/all-including-ended`) as Promise<ServiceResult<ItContract[]>>;
    }

    public create(payload: ItContractCreatePayload): Promise<ServiceResult<ItContract>> {
        return this.httpPut(`${ItContractApi.baseUrl}`, payload) as Promise<ServiceResult<ItContract>>;
    }

    public update(id: number, payload: ItContractUpdatePayload): Promise<ServiceResult<ItContract>> {
        return this.httpPost(`${ItContractApi.baseUrl}/${id}`, payload) as Promise<ServiceResult<ItContract>>;
    }

    public close(id: number, payload: ItContractClosePayload): Promise<ServiceResult<number>> {
        return this.httpPost(`${ItContractApi.baseUrl}/${id}/close`, payload) as Promise<ServiceResult<number>>;
    }

    public delete(id: number): Promise<ServiceResult<number>> {
        return this.httpDelete(`${ItContractApi.baseUrl}/${id}`) as Promise<ServiceResult<number>>;
    }

    public createSupportTicket(contractId: number, payload: ItSupportTicketCreatePayload): Promise<ServiceResult<ItSupportTicket>> {
        return this.httpPut(`${ItContractApi.baseUrl}/${contractId}/support-ticket`, payload) as Promise<ServiceResult<ItSupportTicket>>;
    }

    public solveSupportTicket(contractId: number, ticketId: number, payload: ItSupportTicketSolvePayload): Promise<ServiceResult<ItSupportTicket>> {
        return this.httpPost(`${ItContractApi.baseUrl}/${contractId}/support-ticket/${ticketId}/solve`, payload) as Promise<ServiceResult<ItSupportTicket>>;
    }

    public solveScheduledTicket(contractId: number, ticketId: number, payload: ItScheduledTicketSolvePayload): Promise<ServiceResult<ItSupportTicket>> {
        return this.httpPost(`${ItContractApi.baseUrl}/${contractId}/scheduled-ticket/${ticketId}/solve`, payload) as Promise<ServiceResult<ItSupportTicket>>;
    }
}

import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, CardBody, CardTitle, Dropdown, DropdownToggle, Tooltip } from 'reactstrap';
import { ItScheduledTicketSolvePayload } from "../payloads";
import { ItContract, ItSupportTicket, ItTicketState, HistoryItemType } from "../models";
import { getFriendlyNameFromItContractType, getIndicatorClassFromHistoryItemType, getIndicatorClassFromItTicket, getTooltipTextFromHistoryItemType, getTooltipTextFromItTicket } from "../utils/EnumUtils";
import moment from 'moment';
import { getItContractDeviceCount, MAX_IT_CONTRACT_TYPE_LENGTH_DISPLAY } from '../utils';
import { isMobileOnly } from "react-device-detect";
import Datetime from 'react-datetime';
import ItSupportTicketModal from './ItSupportTicketModal';

interface ScheduledItTicketCardPropsType {
    isExpanded: boolean;
    ticket: ItSupportTicket;
    contract: ItContract;
    expandCollapse: () => void;
    handleSolveClick: (payload: ItScheduledTicketSolvePayload) => void;
}

interface ScheduledItTicketCardState {
    blackAndWhiteCounter: number;
    colorCounter: number | null;
    signedByClient: boolean;
    solvedAt: Date;
    note: string;
    validationErrors: string[];
    ticketIndicatorTooltipOpen: boolean;
    contractNumberTooltipOpen: boolean;
    contractTypeTooltipOpen: boolean;
    deviceCountTooltipOpen: boolean;
    maintenanceIntervalTooltipOpen: boolean;
    memoTooltipOpen: boolean;
    historyItemIndicatorTooltipOpen: number;
    ticketHistoryItem: ItSupportTicket | null;
    ticketHistoryItemModalOpen: boolean;
    itDeviceModelExpanded: boolean;
    contractTypeExpanded: boolean;
}

export class ScheduledItTicketCard extends React.PureComponent<ScheduledItTicketCardPropsType, ScheduledItTicketCardState> {
    constructor(props: ScheduledItTicketCardPropsType) {
        super(props);

        this.state = {
            blackAndWhiteCounter: 0,
            colorCounter: null,
            signedByClient: false,
            solvedAt: props.ticket.solvedAt != null ? new Date(props.ticket.solvedAt) : new Date(),
            note: "",
            validationErrors: [],
            ticketIndicatorTooltipOpen: false,
            contractNumberTooltipOpen: false,
            contractTypeTooltipOpen: false,
            deviceCountTooltipOpen: false,
            maintenanceIntervalTooltipOpen: false,
            memoTooltipOpen: false,
            historyItemIndicatorTooltipOpen: NaN,
            ticketHistoryItem: null,
            ticketHistoryItemModalOpen: false,
            itDeviceModelExpanded: false,
            contractTypeExpanded: false
        }
    }

    public render() {
        const contractTypeLong = getFriendlyNameFromItContractType(this.props.contract.contractType).length > MAX_IT_CONTRACT_TYPE_LENGTH_DISPLAY;

        const addressSecondSpaceIndex = this.props.contract.client.address.indexOf(" ", this.props.contract.client.address.indexOf(" ") + 1);
        const firstAddressChunk = addressSecondSpaceIndex >= 0 ? this.props.contract.client.address.substr(0, addressSecondSpaceIndex).trim() : this.props.contract.client.address.substr(addressSecondSpaceIndex + 1).trim();
        let secondAddressChunk = "";
        if (addressSecondSpaceIndex >= 0) {
            secondAddressChunk = this.props.contract.client.address.substr(addressSecondSpaceIndex + 1).trim();
        }

        const isSolved = this.props.ticket.isSolved;

        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <CardTitle className="mb-0">
                            {isMobileOnly &&
                                <div className="h4 d-flex justify-content-between pb-1 mobile row border-bottom lansoft-border-success lansoft-border-full">
                                    <div />
                                    <div className="d-flex align-items-center mb-3">
                                        <div id={`it-support-ticket-ticket-indicator-${this.props.ticket.id}-${this.props.contract.id}`} className={`mr-1 ticket-indicator bg-${getIndicatorClassFromItTicket(this.props.ticket)}`}></div>
                                        <Tooltip placement="top" isOpen={this.state.ticketIndicatorTooltipOpen} target={`it-support-ticket-ticket-indicator-${this.props.ticket.id}-${this.props.contract.id}`} toggle={this.toggleTicketIndicatorTooltip}>
                                            {getTooltipTextFromItTicket(this.props.ticket)}
                                        </Tooltip>
                                        <span id={`it-contract-it-contract-number-${this.props.contract.id}`}>{this.props.contract.contractNumber}</span>
                                        <Tooltip placement="top" isOpen={this.state.contractNumberTooltipOpen} target={`it-contract-it-contract-number-${this.props.contract.id}`} toggle={this.toggleContractNumberTooltip}>
                                            Szerződésszám
                                        </Tooltip>
                                    </div>
                                    <div className="cursor-pointer mr-3" onClick={() => this.handleExpandClick()}><FontAwesomeIcon icon={["fas", this.props.isExpanded ? "chevron-up" : "chevron-down"]} className="mr-1" /></div>
                                </div>
                            }
                            <div className={`h4 d-flex justify-content-between flex-same-width-container ${isMobileOnly ? "mobile row pt-3" : "mt-4 mb-4 pb-2"}`}>
                                {!isMobileOnly && <div className={isMobileOnly ? "col-6" : ""}>
                                    <div className={`d-flex align-items-center ${isMobileOnly ? "mb-3" : ""}`}>
                                        <div id={`it-support-ticket-ticket-indicator-${this.props.ticket.id}-${this.props.contract.id}`} className={`mr-1 ticket-indicator bg-${getIndicatorClassFromItTicket(this.props.ticket)}`}></div>
                                        <Tooltip placement="top" isOpen={this.state.ticketIndicatorTooltipOpen} target={`it-support-ticket-ticket-indicator-${this.props.ticket.id}-${this.props.contract.id}`} toggle={this.toggleTicketIndicatorTooltip}>
                                            {getTooltipTextFromItTicket(this.props.ticket)}
                                        </Tooltip>
                                        <span id={`it-contract-contract-number-${this.props.contract.id}`}>{this.props.contract.contractNumber}</span>
                                        <Tooltip placement="top" isOpen={this.state.contractNumberTooltipOpen} target={`it-contract-contract-number-${this.props.contract.id}`} toggle={this.toggleContractNumberTooltip}>
                                            Szerződésszám
                                        </Tooltip>
                                    </div>
                                </div>}
                                <div className={isMobileOnly ? "col-6" : ""}>
                                    <div className={`d-flex ${isMobileOnly ? "mb-3" : ""}`}>
                                        <span id={`it-contract-it-maintenance-interval-${this.props.contract.id}`}>{this.props.contract.maintenanceIntervalMonths == null ? `W/${this.props.contract.maintenanceIntervalWeeks}` : `G/${this.props.contract.maintenanceIntervalMonths}`}</span>
                                        <Tooltip placement="top" isOpen={this.state.maintenanceIntervalTooltipOpen} target={`it-contract-it-maintenance-interval-${this.props.contract.id}`} toggle={this.toggleMaintenanceIntervalTooltip}>
                                            Megjelenés gyakorisága
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className={isMobileOnly ? "col-6 mb-3 d-flex" : ""}>
                                    <div onClick={this.handleContractTypeExpandClick} className={isMobileOnly ? "d-flex justify-content-end" : "cursor-pointer"}>
                                        <div id={`it-contract-it-contract-type-${this.props.contract.id}`} className="d-inline-block">
                                            {!contractTypeLong && <React.Fragment>{getFriendlyNameFromItContractType(this.props.contract.contractType)}</React.Fragment>}
                                            {contractTypeLong && <React.Fragment>
                                                {!this.state.contractTypeExpanded && <React.Fragment>
                                                    {getFriendlyNameFromItContractType(this.props.contract.contractType).substring(0, MAX_IT_CONTRACT_TYPE_LENGTH_DISPLAY - 2) + "..."}
                                                </React.Fragment>}
                                                {this.state.contractTypeExpanded && <React.Fragment>
                                                    {getFriendlyNameFromItContractType(this.props.contract.contractType).split(" ").map((fragment, index) =>
                                                        <span className="nowrap d-block" key={`contract-type-fragment-${index}`}>{fragment}</span>)
                                                    }
                                                </React.Fragment>}
                                            </React.Fragment>}
                                        </div>
                                        <Tooltip placement="top" isOpen={this.state.contractTypeTooltipOpen} target={`it-contract-it-contract-type-${this.props.contract.id}`} toggle={this.toggleContractTypeTooltip}>
                                            Szerződés típusa
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className={isMobileOnly ? "col-6 mb-3 d-flex" : ""}>
                                    <div className={isMobileOnly ? "text-right" : ""}>
                                        <div id={`it-contract-device-count-${this.props.contract.id}`} className="d-inline-block">
                                            {getItContractDeviceCount(this.props.contract)} eszköz
                                        </div>
                                        <Tooltip placement="top" isOpen={this.state.deviceCountTooltipOpen} target={`it-contract-device-count-${this.props.contract.id}`} toggle={this.toggleDeviceCountTooltip}>
                                            Eszközök száma
                                    </Tooltip>
                                    </div>
                                </div>
                                <div className={`flex-half-width-column ${isMobileOnly ? "mobile col" : ""}`}>
                                    <div className={`d-flex ${!isMobileOnly ? "justify-content-end" : ""}`}>
                                        <div id={`it-contract-memo-${this.props.contract.id}`}>
                                            {this.props.contract.memo}
                                        </div>
                                        <Tooltip placement="top" isOpen={this.state.memoTooltipOpen} target={`it-contract-memo-${this.props.contract.id}`} toggle={this.toggleMemoTooltip}>
                                            Memo
                                        </Tooltip>
                                        {!isMobileOnly && <div className="cursor-pointer ml-3" onClick={() => this.handleExpandClick()}><FontAwesomeIcon icon={["fas", this.props.isExpanded ? "chevron-up" : "chevron-down"]} className="mr-1" /></div>}
                                    </div>
                                </div>
                            </div>

                            <div className="mt-1 pt-1 border-top lansoft-border-success"></div>

                            {isMobileOnly && <div className="col-12 mb-2 mt-2 text-center">
                                <div className="h5 no-bottom-margin">
                                    Technikus: {this.props.contract.technician.name}
                                </div>
                            </div>}

                            <div className={isMobileOnly ? "" : "pt-4"}>
                                <div className={`d-flex justify-content-between flex-same-width-container ${isMobileOnly ? "mobile row w-100 align-center-margin" : ""}`}>
                                    <div className={isMobileOnly ? "col-12 mb-4 no-left-padding" : ""}>
                                        <div>
                                            <div className="h5 mb-3"><strong>{this.props.contract.client.name}</strong></div>
                                            <div className="h6 mb-3">
                                                {addressSecondSpaceIndex >= 0 ?
                                                    <div>
                                                        <div>{firstAddressChunk}</div>
                                                        <div>{secondAddressChunk}</div>
                                                    </div>
                                                    :
                                                    <div>
                                                        {this.props.contract.client.address}
                                                    </div>
                                                }
                                            </div>
                                            <div className="h6">{this.props.contract.client.contactPhoneNumber}</div>
                                        </div>
                                    </div>
                                    <div className={isMobileOnly ? "col-6 no-left-padding" : ""}>
                                        <div><label className="label-90 mr-2">PC:</label><strong>{this.props.contract.pcCount}</strong></div>
                                        <div><label className="label-90 mr-2">Notebook:</label><strong>{this.props.contract.notebookCount}</strong></div>
                                        <div><label className="label-90 mr-2">Hálózati eszköz:</label><strong>{this.props.contract.networkingDeviceCount}</strong></div>
                                    </div>
                                    <div className={isMobileOnly ? "col-6 no-left-padding" : ""}>
                                        <div><label className="label-90 mr-2">Szerver:</label><strong>{this.props.contract.serverCount}</strong></div>
                                        <div><label className="label-90 mr-2">NAS:</label><strong>{this.props.contract.nasCount}</strong></div>
                                        <div><label className="label-90 mr-2">Egyéb eszköz:</label><strong>{this.props.contract.miscellaneousCount}</strong></div>
                                    </div>
                                    {!isMobileOnly &&
                                        <div>
                                            <div className="h5">
                                                <div className="d-flex">Technikus: {this.props.contract.technician.name}</div>
                                            </div>
                                        </div>
                                    }

                                    <div className="flex-half-width-column align-items-end">
                                        <div>
                                            <Button className={isSolved ? "lansoft-success" : "lansoft-danger"} disabled={isSolved} onClick={() => this.handleExpandClick(true)}>
                                                {isSolved ? moment(this.props.ticket.solvedAt).format("YYYY.MM.DD") : "Megoldás"}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardTitle>

                        {this.props.isExpanded && <div className={`border-top lansoft-border-success ${isMobileOnly ? "mt-2 pt-2 pb-3" : "mt-3 pt-2 pb-2"}`}>
                            <div className={`d-flex justify-content-between mt-3 ${isMobileOnly ? "row" : "flex-same-width-container"}`}>
                                <div className={`form-group ${isMobileOnly ? "col-6" : ""}`}>
                                    <div className={isMobileOnly ? "it-support-ticket-card-input-container" : ""}>
                                        <label className={isMobileOnly ? "it-support-ticket-card-label-container" : ""}>Megoldás</label>
                                        <Dropdown disabled>
                                            <DropdownToggle disabled>
                                                Szerződés szerinti karbantartás
                                        </DropdownToggle>
                                        </Dropdown>
                                    </div>
                                    <div className="form-group mt-2">
                                        <label htmlFor={`inputSolvedAt-${this.props.ticket.id}`}>Megoldva</label>
                                        <Datetime
                                            closeOnClickOutside={true}
                                            closeOnSelect={true}
                                            dateFormat="YYYY.MM.DD"
                                            timeFormat={false}
                                            utc={true}
                                            value={this.state.solvedAt}
                                            inputProps={{ disabled: this.props.ticket.solvedAt != null }}
                                            onChange={this.handleSolvedAtChange} />
                                    </div>
                                </div>
                                <div className="flex-double-width-column"></div>
                                <div className={isMobileOnly ? "col-6" : ""}>
                                    <div className={`form-group ${isMobileOnly ? "it-support-ticket-card-input-container" : ""}`}>
                                        <label htmlFor="inputNote">Munkalapon megjegyzés</label>
                                        <textarea maxLength={250} rows={4} className="form-control message-textarea border" id="inputNote" autoComplete="off" placeholder="Megjegyzés" value={this.state.note} onChange={this.handleNoteChange} />
                                    </div>
                                    <div className={`form-check mt-3 ${isMobileOnly ? "it-support-ticket-card-checkbox-container" : ""}`}>
                                        <input type="checkbox" className={`form-check-input`} id="inputSignedByClient" checked={this.state.signedByClient} onChange={this.handleSignedByClientChange} />
                                        <label className="form-check-label" htmlFor="inputSignedByClient">Ügyfél által aláírva</label>
                                    </div>
                                    <div className={`mt-4 ${isMobileOnly ? "" : "d-flex justify-content-end"}`}>
                                        <Button color="primary" className="lansoft-primary" onClick={this.handleSolveClick} disabled={!this.isValid()}>Elküld</Button>
                                    </div>
                                </div>
                                <div className="flex-half-width-column"></div>
                            </div>
                        </div>}

                        {this.props.isExpanded && <div className={`border-top lansoft-border-success ${isMobileOnly ? "pt-3" : "pt-3"}`}>
                            <div className="h5">Előzmények</div>
                            {this.props.contract.tickets.length === 0 && <div>Nem található elem.</div>}
                            {this.props.contract.tickets.length > 0 &&
                                <React.Fragment>
                                    <table className="table history-table">
                                        <thead>
                                            <tr>
                                                <th scope="col"></th>
                                                <th scope="col">Dátum</th>
                                                {!isMobileOnly && <th scope="col">Ügyfél kérése</th>}
                                                {!isMobileOnly && <th scope="col">Megoldás</th>}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.contract.tickets.map((ticket, index) => {
                                                const messageToTechnician = ticket.messageToTechnician;
                                                const messageToClient = ticket.messageToClient;

                                                return (
                                                    <tr key={`contract-history-row-${index}`} className="cursor-pointer" onClick={() => this.onTicketHistoryItemClick(ticket)}>
                                                        <td>
                                                            <div id={`scheduled-contract-history-item-indicator-${index}`} className={`contract-history-item-indicator bg-${getIndicatorClassFromHistoryItemType(ticket.isSolved ? (ticket.state === ItTicketState.Solved ? HistoryItemType.SolvedTicket : HistoryItemType.SuspendedTicket) : HistoryItemType.OpenTicket)}`}></div>
                                                            <Tooltip placement="top" isOpen={this.state.historyItemIndicatorTooltipOpen === index} target={`scheduled-contract-history-item-indicator-${index}`} toggle={() => this.toggleHistoryItemIndicatorTooltipOpen(index)}>
                                                                {getTooltipTextFromHistoryItemType(ticket.isSolved ? (ticket.state === ItTicketState.Solved ? HistoryItemType.SolvedTicket : HistoryItemType.SuspendedTicket) : HistoryItemType.OpenTicket)}
                                                            </Tooltip>
                                                        </td>
                                                        <td>{moment(ticket.createdAt).format(isMobileOnly ? "MM.DD" : "YYYY.MM.DD HH:mm")}</td>
                                                        {!isMobileOnly && <td>{messageToTechnician}</td>}
                                                        {!isMobileOnly && <td>{messageToClient}</td>}
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    <div>
                                        <Button color="primary" className="lansoft-primary" onClick={() => this.handleDownloadHistoryExportClick(this.props.contract.id)}>Mentés Excelbe</Button>
                                    </div>
                                </React.Fragment>
                            }
                        </div>}
                    </CardBody>
                </Card>

                <ItSupportTicketModal
                    ticket={this.state.ticketHistoryItem}
                    open={this.state.ticketHistoryItemModalOpen}
                    close={this.closeTicketHistoryItemModal}
                />
            </React.Fragment>

        );
    }

    private handleContractTypeExpandClick = () => {
        this.setState({
            contractTypeExpanded: !this.state.contractTypeExpanded
        });
    }

    private onTicketHistoryItemClick = (ticket: ItSupportTicket) => {
        this.setState({
            ticketHistoryItem: ticket,
            ticketHistoryItemModalOpen: true
        });
    }

    private closeTicketHistoryItemModal = () => {
        this.setState({
            ticketHistoryItemModalOpen: false
        });
    }

    private handleSolvedAtChange = (date: any) => {
        this.setState({
            solvedAt: moment.utc(date).startOf('day').toDate()
        });
    }

    private handleDownloadHistoryExportClick = (contractId: number) => {
        window.location.href = `/api/excel/it-contract/${contractId}/history`;
    }

    private handleSignedByClientChange = (e: any) => {
        this.setState({
            signedByClient: e.target.checked
        }, () => this.validate(true));
    }

    private handleBlackAndWhiteCounterChange = (e: any) => {
        this.setState({
            blackAndWhiteCounter: parseInt(e.target.value.replace(/\D/, ""))
        });
    }

    private handleColorCounterChange = (e: any) => {
        this.setState({
            colorCounter: parseInt(e.target.value.replace(/\D/, ""))
        });
    }

    private handleNoteChange = (e: any) => {
        this.setState({
            note: e.target.value
        });
    }

    private handleExpandClick = (value?: boolean) => {
        if (value == null || value !== this.props.isExpanded) {
            this.props.expandCollapse();
        }
    }

    private handleSolveClick = () => {
        const payload = {
            blackAndWhiteCounter: this.state.blackAndWhiteCounter,
            colorCounter: this.state.colorCounter,
            messageToClient: this.state.note,
            solvedAt: this.state.solvedAt
        };

        this.props.handleSolveClick(payload);
    }

    private isValid = () => {
        return this.validate();
    }

    private validate = (shouldSetState: boolean = false) => {
        const validationErrors: string[] = [];

        if (!this.state.signedByClient) {
            validationErrors.push("Csak ügyfél által aláírt karbantartás küldhető be.");
        }

        if (shouldSetState) {
            this.setState({
                validationErrors: validationErrors
            });
        }

        return validationErrors.length === 0;
    }

    private toggleHistoryItemIndicatorTooltipOpen = (index: number) => {
        this.setState({
            historyItemIndicatorTooltipOpen: this.state.historyItemIndicatorTooltipOpen === index ? NaN : index
        });
    }

    private toggleMemoTooltip = () => {
        this.setState({
            memoTooltipOpen: !this.state.memoTooltipOpen
        });
    }

    private toggleMaintenanceIntervalTooltip = () => {
        this.setState({
            maintenanceIntervalTooltipOpen: !this.state.maintenanceIntervalTooltipOpen
        });
    }

    private toggleDeviceCountTooltip = () => {
        this.setState({
            deviceCountTooltipOpen: !this.state.deviceCountTooltipOpen
        });
    }

    private toggleContractTypeTooltip = () => {
        this.setState({
            contractTypeTooltipOpen: !this.state.contractTypeTooltipOpen
        });
    }

    private toggleContractNumberTooltip = () => {
        this.setState({
            contractNumberTooltipOpen: !this.state.contractNumberTooltipOpen
        });
    }

    private toggleTicketIndicatorTooltip = () => {
        this.setState({
            ticketIndicatorTooltipOpen: !this.state.ticketIndicatorTooltipOpen
        });
    }
}

export default ScheduledItTicketCard;

import * as React from 'react';
import { MenuItem } from "../models";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DropdownToggle, DropdownMenu } from 'reactstrap';
import HoverDropdown from "./HoverDropdown";
import { Link } from "react-router-dom";

interface NavMenuItemGroupProps {
    menuItem: MenuItem;
    isLast?: boolean;
    items: MenuItem[];
    right?: boolean;
    className?: string;
    backgroundColor?: string;
    mobile?: boolean;
}

export const NavMenuItemGroup = (props: NavMenuItemGroupProps) => {
    const getAnchorTag = (item: MenuItem) => {
        const children = <React.Fragment>
            {item.customIcon == null && <FontAwesomeIcon icon={["fas", item.icon]} className="fa-fw nav-menu-item-dropdown-icon" />}
            {item.customIcon != null && <img src={item.customIcon} className="nav-menu-dropdown-custom-icon" alt="nav-menu-custom-icon" />}
            <span className="ml-2">{item.text}</span>
        </React.Fragment>

        return (
            item.target == null || item.target.startsWith("/Identity") ?
                <a className="nav-link nav-menu-item-link d-flex align-items-start align-items-center dropdown-item" role="menuitem" href={item.target || ""}>
                    {children}
                </a>
                :
                <Link className="nav-link nav-menu-item-link d-flex align-items-start align-items-center dropdown-item" role="menuitem" to={item.target || ""}>
                    {children}
                </Link>
        );
    }

    return (
        <React.Fragment>
            <HoverDropdown inNavbar>
                <DropdownToggle className={`nav-link nav-menu-item-link d-flex ${props.mobile ? "" : "flex-column"} align-items-center text-white ${props.className == null ? "" : props.className}`} style={{ backgroundColor: props.backgroundColor == null ? "transparent" : props.backgroundColor }} nav caret>
                    {props.menuItem.customIcon == null && <FontAwesomeIcon icon={["fas", props.menuItem.icon]} className={`fa-fw nav-menu-item-icon ${props.mobile ? "mobile" : ""} ${props.mobile ? (props.isLast ? "ml-0-75 mr-2" : "ml-3 mr-2") : ""}`} />}
                    {props.menuItem.customIcon != null && <img src={props.menuItem.customIcon} className={`nav-menu-custom-icon ${props.mobile ? (props.isLast ? "ml-0-75 mr-2" : "ml-3 mr-2") : ""}`} alt="nav-menu-custom-icon" />}
                    <span>{props.menuItem.text}</span>
                </DropdownToggle>
                <DropdownMenu right={props.right}>
                    {props.items.map((item, index) => {
                        return (
                            <React.Fragment key={`menu-item-group-item-${index}`}>
                                <div data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown">
                                    {getAnchorTag(item)}
                                </div>
                            </React.Fragment>
                        );
                    })}
                </DropdownMenu>
            </HoverDropdown>
            {!props.isLast && <span className={`navbar-vertical-divider ${props.mobile ? "mobile" : ""}`} />}
        </React.Fragment>
    );
}

export default NavMenuItemGroup;

import { ProtectedRestApi } from "./";
import { ItTicketType, ServiceResult } from "../models";
import { ItTicketTypeCreatePayload, ItTicketTypeUpdatePayload } from "../payloads";

export class ItTicketTypeApi extends ProtectedRestApi {
    private static baseUrl = "/api/it-ticket-type";

    public getAll(): Promise<ServiceResult<ItTicketType[]>> {
        return this.httpGet(`${ItTicketTypeApi.baseUrl}/all`) as Promise<ServiceResult<ItTicketType[]>>;
    }

    public create(payload: ItTicketTypeCreatePayload): Promise<ServiceResult<ItTicketType>> {
        return this.httpPut(`${ItTicketTypeApi.baseUrl}`, payload) as Promise<ServiceResult<ItTicketType>>;
    }

    public update(id: number, payload: ItTicketTypeUpdatePayload): Promise<ServiceResult<ItTicketType>> {
        return this.httpPost(`${ItTicketTypeApi.baseUrl}/${id}`, payload) as Promise<ServiceResult<ItTicketType>>;
    }

    public delete(id: number): Promise<ServiceResult<number>> {
        return this.httpDelete(`${ItTicketTypeApi.baseUrl}/${id}`) as Promise<ServiceResult<number>>;
    }
}

import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CopierTicketSolutionType } from "../models";
import { Button } from "reactstrap";

interface CopierTicketSolutionTypeItemProps {
    className?: string;
    ticketSolutionType: CopierTicketSolutionType;
    editCopierTicketSolutionType?: () => void;
    deleteCopierTicketSolutionType?: () => void;
}

export const CopierTicketSolutionTypeItem = (props: CopierTicketSolutionTypeItemProps) => (
    <div className={`d-flex align-items-center justify-content-between flex-same-width-container ${(props.className != null ? props.className : "")}`}>
        <div>
            <div className="h5 mb-0">{props.ticketSolutionType.name}</div>
        </div>
        <div>
            <div className="d-flex justify-content-end">
                {props.editCopierTicketSolutionType != null && <Button color="primary" className="lansoft-primary" onClick={props.editCopierTicketSolutionType}><FontAwesomeIcon icon={["fas", "pencil-alt"]} /></Button>}
                {props.deleteCopierTicketSolutionType != null && <Button color="danger" className="ml-3 lansoft-danger" onClick={props.deleteCopierTicketSolutionType}><FontAwesomeIcon icon={["fas", "trash-alt"]} /></Button>}
            </div>
        </div>
    </div>
);

export default CopierTicketSolutionTypeItem;

import { ProtectedRestApi } from "./";
import { CopierSupportTicket, ServiceResult } from "../models";

export class CopierSupportTicketApi extends ProtectedRestApi {
    private static baseUrl = "/api/copier-support-ticket";

    public getAllForHistory(): Promise<ServiceResult<CopierSupportTicket[]>> {
        return this.httpGet(`${CopierSupportTicketApi.baseUrl}/all-for-history`) as Promise<ServiceResult<CopierSupportTicket[]>>;
    }

    public getActive(onlyMine: boolean): Promise<ServiceResult<CopierSupportTicket[]>> {
        return this.httpGet(`${CopierSupportTicketApi.baseUrl}/active/${onlyMine ? "own" : "all"}`) as Promise<ServiceResult<CopierSupportTicket[]>>;
    }

    public getInactive(onlyMine: boolean): Promise<ServiceResult<CopierSupportTicket[]>> {
        return this.httpGet(`${CopierSupportTicketApi.baseUrl}/inactive/${onlyMine ? "own" : "all"}`) as Promise<ServiceResult<CopierSupportTicket[]>>;
    }

    public getScheduled(onlyMine: boolean): Promise<ServiceResult<CopierSupportTicket[]>> {
        return this.httpGet(`${CopierSupportTicketApi.baseUrl}/scheduled/${onlyMine ? "own" : "all"}`) as Promise<ServiceResult<CopierSupportTicket[]>>;
    }
}

import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CopierContract } from "../models";
import { Button } from "reactstrap";

interface CopierContractAdminItemProps {
    className?: string;
    contract: CopierContract;
    editContract?: () => void;
    closeContract?: () => void;
    deleteContract?: () => void;
    viewContract?: () => void;
    mobile?: boolean;
}

export const CopierContractAdminItem = (props: CopierContractAdminItemProps) => (
    <div className={`d-flex align-items-center justify-content-between flex-same-width-container ${(props.className != null ? props.className : "")}`}>
        <div>
            <div className="h5 mb-0 d-flex text-ellipsis"><div>{props.contract.contractNumber}</div><div className="ml-2">{props.contract.endDate != null && <FontAwesomeIcon icon={["fas", "lock"]} />}</div></div>
        </div>
        {!props.mobile && <div className="h6 no-bottom-margin">{props.contract.client.name}</div>}
        <div>
            <div className="d-flex justify-content-end">
                <Button color="warning" className={`lansoft-warning ${props.closeContract == null || props.contract.endDate != null ? " invisible" : ""}`} onClick={props.closeContract} data-toggle="tooltip" data-placement="bottom" title="Lezárás"><FontAwesomeIcon icon={["fas", "lock"]} /></Button>
                {props.viewContract != null && <Button color="success" className="lansoft-success ml-3" onClick={props.viewContract}><FontAwesomeIcon icon={["fas", "eye"]} /></Button>}
                <Button color="primary" className={`lansoft-primary ml-3 ${props.editContract == null ? "invisible" : ""}`} onClick={props.editContract} data-toggle="tooltip" data-placement="bottom" title="Szerkesztés"><FontAwesomeIcon icon={["fas", "pencil-alt"]} /></Button>
                <Button color="danger" className={`lansoft-danger ml-3 ${props.deleteContract == null ? " invisible" : ""}`} onClick={props.deleteContract} data-toggle="tooltip" data-placement="bottom" title="Törlés"><FontAwesomeIcon icon={["fas", "trash-alt"]} /></Button>
            </div>
        </div>
    </div>
);

export default CopierContractAdminItem;

import * as React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { ApplicationState } from '../store';
import * as LanSoftStore from '../store/LanSoftStore';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';
import NavMenuTechnician from './NavMenuTechnician';
import authService from '../components/api-authorization/AuthorizeService';
import CookieConsent from "react-cookie-consent";
import { isMobileOnly } from "react-device-detect";

type LayoutPropsType = LanSoftStore.LanSoftState
    & typeof LanSoftStore.actionCreators
    & RouteComponentProps<{}>
    & { children?: React.ReactNode };

interface LayoutState {
    userReceived: boolean;
    notLoggedIn: boolean;
}

class Layout extends React.PureComponent<LayoutPropsType, LayoutState> {
    constructor(props: LayoutPropsType) {
        super(props);

        this.state = {
            userReceived: false,
            notLoggedIn: false
        };
    }

    componentDidMount() {
        authService.isAuthenticated().then(authenticated => {
            this.setState({
                userReceived: true
            });

            if (authenticated) {
                this.props.getLoggedInUser();
            } else {
                this.setState({
                    notLoggedIn: true
                });
            }
        });
    }

    render() {
        const user = this.props.loggedInUser;
        const navMenu = user == null ? null : (
            user.isAdmin || user.isTechnician ? <NavMenuTechnician />
                : <NavMenu />
        );

        if (user != null) {
            if (!user.isTechnician && !user.isAdmin && this.props.location.pathname.toLowerCase().startsWith("/admin")) {
                return <Redirect to="/" />
            }

            if ((user.isTechnician || user.isAdmin) && this.props.location.pathname.toLowerCase().startsWith("/my")) {
                return <Redirect to="/" />
            }
        }

        const mainContainerClass = isMobileOnly && (user == null || (!user.isAdmin && !user.isTechnician)) ? "mobile-user-main-container" : "";

        return (
            <React.Fragment>
                {navMenu}
                <Container className={mainContainerClass}>
                    {this.state.userReceived && this.props.children}
                </Container>
                <CookieConsent
                    buttonText="Rendben"
                    style={{ backgroundColor: "rgba(0, 173, 239, 0.8)", marginBottom: "80px" }}
                    buttonStyle={{ backgroundColor: "rgb(41, 221, 209)", color: "white" }}
                >
                    A weboldal használatával hozzájárulok a sütik (cookie-k) használatához a felhasználói élmény fokozása érdekében.
                </CookieConsent>
                <footer className="footer border-top text-muted">
                    <div className="container d-flex flex-column justify-content-center align-items-center h-100">
                        <div className="row">
                            <div className="col col-md-6 text-center">&copy; 2021 Lansoft Trade Informatikai Kft.{ !isMobileOnly && " |"}</div>
                            <div className="col col-md-6 text-center"><a href="https://www.lansoft.hu">www.lansoft.hu</a> | <a href="mailto:mail@lansoft.hu">mail@lansoft.hu</a></div>
                        </div>
                        <div className="row">
                            <div className="col col-md-6 text-center no-padding">4025 Debrecen, Külső vásártér 18.{!isMobileOnly && " |"}</div>
                            <div className="col col-md-6 text-center">Tel.: +36 52 502-490 | Fax: +36 52 502-492</div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        );
    }
}

export default compose(
    withRouter,
    connect(
        (state: ApplicationState) => state.lanSoft,
        LanSoftStore.actionCreators
    )
)(Layout) as React.ComponentType;

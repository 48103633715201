import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as LanSoftStore from '../store/LanSoftStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { LoadingIndicator, ToastContainer } from "./common";
import { CopierContractType, ItContractType, ItContractMaintenanceIntervalType, CopierContract, ItContract } from "../models";
import { getEnumValues, getFriendlyNameFromCopierContractType, getFriendlyNameFromItContractType, getNumberInputValue } from "../utils";
import { CopierContractClosePayload, CopierContractCreatePayload, CopierContractUpdatePayload, ItContractClosePayload, ItContractCreatePayload, ItContractUpdatePayload } from "../payloads";
import CopierContractAdminItem from "./CopierContractAdminItem";
import ItContractAdminItem from "./ItContractAdminItem";
import CopierContractDetailsModal from "./CopierContractDetailsModal";
import ItContractDetailsModal from "./ItContractDetailsModal";
import Datetime from 'react-datetime';
import moment from 'moment';
import { isMobileOnly } from 'react-device-detect';

type ContractsAdminPropsType = LanSoftStore.LanSoftState
    & typeof LanSoftStore.actionCreators
    & RouteComponentProps<{}>;

interface ContractsAdminState {
    result: boolean | null;
    toastOpen: boolean;
    createCopierContractModalOpen: boolean;
    viewCopierContractIndex: number;
    editCopierContractIndex: number;
    closeCopierContractIndex: number;
    deleteCopierContractIndex: number;
    copierContractClientDropdownOpen: boolean;
    copierContractTechnicianDropdownOpen: boolean;
    createItContractModalOpen: boolean;
    viewItContractIndex: number;
    editItContractIndex: number;
    closeItContractIndex: number;
    deleteItContractIndex: number;
    itContractClientDropdownOpen: boolean;
    itContractTechnicianDropdownOpen: boolean;
    itContractContractTypeDropdownOpen: boolean;
    itContractMaintenanceIntervalType: ItContractMaintenanceIntervalType;
    itContractMaintenanceIntervalMonthsDropdownOpen: boolean;
    itContractMaintenanceIntervalWeeksDropdownOpen: boolean;
    copierContractDeviceDropdownOpen: boolean;
    copierContractContractTypeDropdownOpen: boolean;
    copierContractMaintenanceIntervalMonthsDropdownOpen: boolean;
    indefiniteEndDate: boolean;
    copierContractCreatePayload: CopierContractCreatePayload;
    copierContractUpdatePayload: CopierContractUpdatePayload;
    copierContractClosePayload: CopierContractClosePayload;
    validationErrors: string[];
    indefiniteEndDateIt: boolean;
    itContractCreatePayload: ItContractCreatePayload;
    itContractUpdatePayload: ItContractUpdatePayload;
    itContractClosePayload: ItContractClosePayload;
    validationErrorsIt: string[];
    blackAndWhitePrintPriceRaw: string;
    colorPrintPriceRaw: string;
    search: string;
    filteredCopierContracts: CopierContract[];
    filteredItContracts: ItContract[];
}

class ContractsAdmin extends React.PureComponent<ContractsAdminPropsType, ContractsAdminState> {
    constructor(props: ContractsAdminPropsType) {
        super(props);

        this.state = {
            result: null,
            toastOpen: false,
            createCopierContractModalOpen: false,
            viewCopierContractIndex: NaN,
            editCopierContractIndex: NaN,
            closeCopierContractIndex: NaN,
            deleteCopierContractIndex: NaN,
            copierContractClientDropdownOpen: false,
            copierContractTechnicianDropdownOpen: false,
            createItContractModalOpen: false,
            viewItContractIndex: NaN,
            editItContractIndex: NaN,
            closeItContractIndex: NaN,
            deleteItContractIndex: NaN,
            itContractClientDropdownOpen: false,
            itContractTechnicianDropdownOpen: false,
            itContractContractTypeDropdownOpen: false,
            itContractMaintenanceIntervalType: ItContractMaintenanceIntervalType.Weeks,
            itContractMaintenanceIntervalMonthsDropdownOpen: false,
            itContractMaintenanceIntervalWeeksDropdownOpen: false,
            copierContractDeviceDropdownOpen: false,
            copierContractContractTypeDropdownOpen: false,
            copierContractMaintenanceIntervalMonthsDropdownOpen: false,
            indefiniteEndDate: false,
            validationErrors: [],
            copierContractCreatePayload: {
                contractNumber: "",
                clientId: 0,
                technicianId: 0,
                deviceId: 0,
                memo: "",
                contractType: CopierContractType.None,
                blackAndWhiteCounter: 0,
                colorCounter: null,
                maintenanceIntervalMonths: null,
                monthlyLeasePrice: 0,
                includedBlackAndWhitePrints: 0,
                blackAndWhitePrintPrice: 0,
                colorPrintPrice: 0,
                startDate: moment.utc(new Date()).startOf('day').toDate(),
                endDate: null
            },
            copierContractUpdatePayload: {
                contractNumber: "",
                technicianId: 0,
                memo: ""
            },
            copierContractClosePayload: {
                endDate: moment.utc(new Date()).startOf('day').toDate()
            },
            indefiniteEndDateIt: false,
            validationErrorsIt: [],
            itContractCreatePayload: {
                contractNumber: "",
                clientId: 0,
                technicianId: 0,
                memo: "",
                contractType: ItContractType.None,
                maintenanceIntervalWeeks: null,
                maintenanceIntervalMonths: null,
                pcCount: 0,
                notebookCount: 0,
                networkingDeviceCount: 0,
                serverCount: 0,
                nasCount: 0,
                miscellaneousCount: 0,
                startDate: moment.utc(new Date()).startOf('day').toDate(),
                endDate: null
            },
            itContractUpdatePayload: {
                contractNumber: "",
                technicianId: 0,
                pcCount: 0,
                notebookCount: 0,
                networkingDeviceCount: 0,
                serverCount: 0,
                nasCount: 0,
                miscellaneousCount: 0,
                memo: ""
            },
            itContractClosePayload: {
                endDate: moment.utc(new Date()).startOf('day').toDate()
            },
            blackAndWhitePrintPriceRaw: "0",
            colorPrintPriceRaw: "0",
            search: "",
            filteredCopierContracts: [],
            filteredItContracts: []
        }
    }

    componentDidMount() {
        this.props.getAllCopierContracts();
        this.props.getAllItContracts();
        this.props.getAllClients();
        this.props.getAllCopierDevices();
    }

    UNSAFE_componentWillReceiveProps(nextProps: ContractsAdminPropsType) {
        if (nextProps.result !== this.props.result && nextProps.result != null) {
            this.setState({
                result: nextProps.result,
                toastOpen: true
            });

            window.setTimeout(() => {
                this.setState({
                    toastOpen: false
                }, () => {
                    this.props.clearResult();
                });
            }, 3000);
        }

        if (nextProps.copierContracts.length !== this.props.copierContracts.length) {
            this.setState({
                filteredCopierContracts: this.filterCopierContracts(nextProps.copierContracts, this.state.search)
            });
        }

        if (nextProps.itContracts.length !== this.props.itContracts.length) {
            this.setState({
                filteredItContracts: this.filterItContracts(nextProps.itContracts, this.state.search)
            });
        }
    }

    public render() {
        return (
            <React.Fragment>
                <LoadingIndicator show={this.props.loading > 0} />
                <div className={`d-flex justify-content-between ${isMobileOnly ? "row" : ""}`}>
                    <h4 className={`mb-3 ${isMobileOnly ? "col-12" : ""}`}><FontAwesomeIcon icon={["fas", "file-contract"]} className="lansoft-text-success" /> <span className="lansoft-underline-success">Szerződések {`(${this.state.filteredCopierContracts.length + this.state.filteredItContracts.length})`}</span></h4>
                    <div className={`form-group form-inline no-bottom-margin ${isMobileOnly ? "col-12" : ""}`}>
                        <input type="text" className="form-control" autoComplete="off" placeholder="Keresés" value={this.state.search} onChange={this.handleSearchChange} />
                    </div>
                </div>

                <div className="tabbable full-width-tabs tickets-tabs mt-4">
                    <ul className="nav nav-tabs mb-3 d-flex justify-content-start align-items-center" id="contracts-tab-list" role="tablist">
                        <li className="d-flex">
                            <a className="nav-link active" id="copier-contracts-tab" data-toggle="pill" href="#copier-contracts" role="tab" aria-controls="copier" aria-selected="true">Másolástechnikai ({this.state.filteredCopierContracts.length})</a>
                        </li>
                        <li className="d-flex">
                            <a className="nav-link" id="it-contracts-tab" data-toggle="pill" href="#it-contracts" role="tab" aria-controls="it" aria-selected="false">Informatikai ({this.state.filteredItContracts.length})</a>
                        </li>
                    </ul>
                </div>

                <div className="tab-content" id="contracts-tab-content">
                    <div className="tab-pane fade show active" id="copier-contracts" role="tabpanel" aria-labelledby="copier-contracts-tab">
                        <div className="d-flex justify-content-end">
                            <Button onClick={this.handleCreateCopierContractClick} color="success" className="lansoft-success">
                                <FontAwesomeIcon icon={["fas", "plus"]} /> Új másolástechnikai szerződés
                            </Button>
                        </div>

                        {this.state.filteredCopierContracts.length === 0 &&
                            <div className="d-flex justify-content-center text-white font-italic mt-4">
                                <span>Nem található szerződés.</span>
                            </div>
                        }
                        {this.state.filteredCopierContracts.length > 0 &&
                            <ul className="list-group mt-3 diagonal-background-flat diagonal-background-lansoft-primary position-relative no-left-border-radius">
                                {this.state.filteredCopierContracts.map((contract, index) =>
                                    <li className="list-group-item" key={`copier-contract-list-item-${index}`}>
                                        <CopierContractAdminItem
                                            contract={contract}
                                            editContract={() => this.handleEditCopierContractClick(index)}
                                            closeContract={() => this.handleCloseCopierContractClick(index)}
                                            deleteContract={() => this.handleDeleteCopierContractClick(index)}
                                            viewContract={() => this.handleViewCopierContractClick(index)}
                                            mobile={isMobileOnly}
                                        />
                                    </li>
                                )}
                            </ul>
                        }
                    </div>
                    <div className="tab-pane fade" id="it-contracts" role="tabpanel" aria-labelledby="it-contracts-tab">
                        <div className="d-flex justify-content-end">
                            <Button onClick={this.handleCreateItContractClick} color="success" className="lansoft-success">
                                <FontAwesomeIcon icon={["fas", "plus"]} /> Új informatikai szerződés
                            </Button>
                        </div>

                        {this.state.filteredItContracts.length === 0 &&
                            <div className="d-flex justify-content-center text-white font-italic mt-4">
                                <span>Nem található szerződés.</span>
                            </div>
                        }
                        {this.state.filteredItContracts.length > 0 &&
                            <ul className="list-group mt-3 diagonal-background-flat diagonal-background-lansoft-primary position-relative no-left-border-radius">
                                {this.state.filteredItContracts.map((contract, index) =>
                                    <li className="list-group-item" key={`it-contract-list-item-${index}`}>
                                        <ItContractAdminItem
                                            contract={contract}
                                            editContract={() => this.handleEditItContractClick(index)}
                                            closeContract={() => this.handleCloseItContractClick(index)}
                                            deleteContract={() => this.handleDeleteItContractClick(index)}
                                            viewContract={() => this.handleViewItContractClick(index)}
                                            mobile={isMobileOnly}
                                        />
                                    </li>
                                )}
                            </ul>
                        }
                    </div>
                </div>

                {this.getCreateCopierContractModal()}
                {this.getViewCopierContractModal()}
                {this.getEditCopierContractModal()}
                {this.getCloseCopierContractModal()}
                {this.getDeleteCopierContractModal()}
                {this.getCreateItContractModal()}
                {this.getViewItContractModal()}
                {this.getEditItContractModal()}
                {this.getCloseItContractModal()}
                {this.getDeleteItContractModal()}
                <ToastContainer open={this.state.toastOpen} color={this.state.result ? "success" : "danger"} success={this.state.result === true} />
            </React.Fragment>

        );
    }

    private getCreateCopierContractModal = () => {
        return (
            <Modal isOpen={!this.props.loading && this.state.createCopierContractModalOpen} toggle={this.handleCancelCreateCopierContractClick} className="modal-dialog-centered">
                <ModalHeader className="lansoft-success" toggle={this.handleCancelCreateCopierContractClick}>Másolástechnikai szerződés létrehozása</ModalHeader>
                <ModalBody>
                    <div className="form-group">
                        <label htmlFor="inputNewCopierContractContractNumber">Szerződésszám</label>
                        <input type="text" className="form-control" id="inputNewCopierContractContractNumber" autoComplete="off" placeholder="Szerződésszám" value={this.state.copierContractCreatePayload.contractNumber} onChange={this.handleNewCopierContractContractNumberChange} />
                    </div>

                    <div className="form-group mt-3 mb-1">
                        <label>Ügyfél</label>
                        <Dropdown isOpen={this.state.copierContractClientDropdownOpen} toggle={this.toggleCopierContractClientDropdown}>
                            <DropdownToggle className="w-100 lansoft-success" caret>
                                {this.state.copierContractCreatePayload.clientId === 0 ? "Válassz" : this.props.clients.find(x => x.id === this.state.copierContractCreatePayload.clientId)!!.name}
                            </DropdownToggle>
                            <DropdownMenu className="w-100 scrollable-dropdown-menu">
                                {this.props.clients.map((client, index) =>
                                    <DropdownItem active={this.state.copierContractCreatePayload.clientId === client.id} onClick={() => this.handleNewCopierContractClientChange(client.id)} key={`new-copier-contract-client-dropdown-item-${index}`}>{client.name}</DropdownItem>
                                )}
                            </DropdownMenu>
                        </Dropdown>
                    </div>

                    <div className="form-group mt-3 mb-1">
                        <label>Technikus</label>
                        <Dropdown isOpen={this.state.copierContractTechnicianDropdownOpen} toggle={this.toggleCopierContractTechnicianDropdown}>
                            <DropdownToggle className="w-100 lansoft-success" caret>
                                {this.state.copierContractCreatePayload.technicianId === 0 ? "Válassz" : this.props.technicians.find(x => x.id === this.state.copierContractCreatePayload.technicianId)!!.name}
                            </DropdownToggle>
                            <DropdownMenu className="w-100 scrollable-dropdown-menu">
                                {this.props.technicians.map((technician, index) =>
                                    <DropdownItem active={this.state.copierContractCreatePayload.technicianId === technician.id} onClick={() => this.handleNewCopierContractTechnicianChange(technician.id)} key={`new-copier-contract-technician-dropdown-item-${index}`}>{technician.name}</DropdownItem>
                                )}
                            </DropdownMenu>
                        </Dropdown>
                    </div>

                    <div className="form-group mt-3 mb-1">
                        <label>Gép</label>
                        <Dropdown isOpen={this.state.copierContractDeviceDropdownOpen} toggle={this.toggleCopierContractDeviceDropdown}>
                            <DropdownToggle className="w-100 lansoft-success" caret>
                                {this.state.copierContractCreatePayload.deviceId === 0 ? "Válassz" : `${this.props.copierDevices.find(x => x.id === this.state.copierContractCreatePayload.deviceId)!!.serialNumber} (${this.props.copierDevices.find(x => x.id === this.state.copierContractCreatePayload.deviceId)!!.model.name})`}
                            </DropdownToggle>
                            <DropdownMenu className="w-100 scrollable-dropdown-menu">
                                {this.props.copierDevices.map((device, index) =>
                                    <DropdownItem active={this.state.copierContractCreatePayload.deviceId === device.id} onClick={() => this.handleNewCopierContractDeviceChange(device.id)} key={`new-copier-contract-device-dropdown-item-${index}`}>{`${device.serialNumber} (${device.model.name})`}</DropdownItem>
                                )}
                            </DropdownMenu>
                        </Dropdown>
                    </div>

                    <div className="form-group">
                        <label htmlFor="inputNewCopierContractMemo">Memo</label>
                        <input type="text" className="form-control" id="inputNewCopierContractMemo" autoComplete="off" placeholder="Memo" value={this.state.copierContractCreatePayload.memo} onChange={this.handleNewCopierContractMemoChange} />
                    </div>

                    <div className="form-group mt-3">
                        <label>Típus</label>
                        <Dropdown isOpen={this.state.copierContractContractTypeDropdownOpen} toggle={this.toggleCopierContractContractTypeDropdown}>
                            <DropdownToggle className="w-100 lansoft-success" caret>
                                {this.state.copierContractCreatePayload.contractType === CopierContractType.None ? "Válassz" : `${getFriendlyNameFromCopierContractType(this.state.copierContractCreatePayload.contractType)}`}
                            </DropdownToggle>
                            <DropdownMenu className="w-100">
                                {getEnumValues(CopierContractType).filter(x => x !== CopierContractType.None).map((contractType, index) =>
                                    <DropdownItem active={this.state.copierContractCreatePayload.contractType === contractType} onClick={() => this.handleNewCopierContractTypeChange(contractType)} key={`new-copier-contract-type-dropdown-item-${index}`}>{getFriendlyNameFromCopierContractType(contractType)}</DropdownItem>
                                )}
                            </DropdownMenu>
                        </Dropdown>
                    </div>

                    <div className="form-group mt-3">
                        <label htmlFor="inputNewCopierContractBlackAndWhiteCounter">Fekete-fehér számláló</label>
                        <input type="text" className="form-control" id="inputNewCopierContractBlackAndWhiteCounter" autoComplete="off" placeholder="Fekete-fehér számláló" value={getNumberInputValue(this.state.copierContractCreatePayload.blackAndWhiteCounter)} onChange={this.handleNewCopierContractBlackAndWhiteCounterChange} />
                    </div>

                    {this.state.copierContractCreatePayload.deviceId !== 0 && this.props.copierDevices.find(x => x.id === this.state.copierContractCreatePayload.deviceId)!!.model.color &&
                        <div className="form-group mt-3">
                            <label htmlFor="inputNewCopierContractColorCounter">Színes számláló</label>
                            <input type="text" className="form-control" id="inputNewCopierContractColorCounter" autoComplete="off" placeholder="Színes számláló" value={getNumberInputValue(this.state.copierContractCreatePayload.colorCounter || 0)} onChange={this.handleNewCopierContractColorCounterChange} />
                        </div>
                    }

                    {this.state.copierContractCreatePayload.contractType === CopierContractType.Monthly &&
                        <div className="form-group mt-3">
                            <label>Megjelenés gyakorisága</label>
                            <Dropdown isOpen={this.state.copierContractMaintenanceIntervalMonthsDropdownOpen} toggle={this.toggleCopierContractMaintenanceIntervalMonthsDropdown}>
                                <DropdownToggle className="w-100 lansoft-success lansoft-success" caret>
                                    {this.state.copierContractCreatePayload.maintenanceIntervalMonths == null || this.state.copierContractCreatePayload.maintenanceIntervalMonths === 0 ? "Válassz" : `${this.state.copierContractCreatePayload.maintenanceIntervalMonths} hónap (G/${this.state.copierContractCreatePayload.maintenanceIntervalMonths})`}
                                </DropdownToggle>
                                <DropdownMenu className="w-100">
                                    {Array.from({ length: 12 }, (_, i) => i + 1).map((months, index) =>
                                        <DropdownItem active={this.state.copierContractCreatePayload.maintenanceIntervalMonths === months} onClick={() => this.handleNewCopierContractMaintenanceIntervalMonthsChange(months)} key={`new-copier-contract-maintenance-interval-months-dropdown-item-${index}`}>{`${months} hónap (G/${months})`}</DropdownItem>
                                    )}
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    }

                    {(this.state.copierContractCreatePayload.contractType === CopierContractType.Lease || this.state.copierContractCreatePayload.contractType === CopierContractType.Monthly) &&
                        <div className="form-group mt-3">
                            <label htmlFor="inputNewCopierContractMonthlyLeasePrice">Bérleti díj</label>
                            <input type="text" className="form-control" id="inputNewCopierContractMonthlyLeasePrice" autoComplete="off" placeholder="Bérleti díj" value={getNumberInputValue(this.state.copierContractCreatePayload.monthlyLeasePrice)} onChange={this.handleNewCopierContractMonthlyLeasePriceChange} />
                        </div>
                    }

                    {this.state.copierContractCreatePayload.contractType === CopierContractType.Lease &&
                        <div className="form-group mt-3">
                            <label htmlFor="inputNewCopierContractIncludedBlackAndWhitePrints">Benne foglalt fekete-fehér nyomat</label>
                            <input type="text" className="form-control" id="inputNewCopierContractIncludedBlackAndWhitePrints" autoComplete="off" placeholder="Benne foglalt fekete-fehér nyomat" value={getNumberInputValue(this.state.copierContractCreatePayload.includedBlackAndWhitePrints)} onChange={this.handleNewCopierContractIncludedBlackAndWhitePrintsChange} />
                        </div>
                    }

                    {(this.state.copierContractCreatePayload.contractType === CopierContractType.Lease || this.state.copierContractCreatePayload.contractType === CopierContractType.AllRoundOperation) &&
                        <div className="form-group mt-3">
                            <label htmlFor="inputNewCopierContractBlackAndWhitePrintPrice">Fekete-fehér nyomatdíj</label>
                            <input type="text" className="form-control" id="inputNewCopierContractBlackAndWhitePrintPrice" autoComplete="off" placeholder="Fekete-fehér nyomatdíj" value={this.state.blackAndWhitePrintPriceRaw} onChange={this.handleNewCopierContractBlackAndWhitePrintPriceChange} />
                        </div>
                    }

                    {this.state.copierContractCreatePayload.deviceId !== 0 && this.props.copierDevices.find(x => x.id === this.state.copierContractCreatePayload.deviceId)!!.model.color && (this.state.copierContractCreatePayload.contractType === CopierContractType.Lease || this.state.copierContractCreatePayload.contractType === CopierContractType.AllRoundOperation) &&
                        <div className="form-group mt-3">
                            <label htmlFor="inputNewCopierContractColorPrintPrice">Színes nyomatdíj</label>
                            <input type="text" className="form-control" id="inputNewCopierContractColorPrintPrice" autoComplete="off" placeholder="Színes nyomatdíj" value={this.state.colorPrintPriceRaw} onChange={this.handleNewCopierContractColorPrintPriceChange} />
                        </div>
                    }

                    <div className="form-group mt-3">
                        <label htmlFor="inputStartDate">Kezdő dátum</label>
                        <Datetime
                            closeOnClickOutside={true}
                            closeOnSelect={true}
                            dateFormat="YYYY.MM.DD"
                            timeFormat={false}
                            utc={true}
                            value={this.state.copierContractCreatePayload.startDate}
                            onChange={this.handleNewCopierContractStartDateChange} />
                    </div>

                    <div className="form-check mt-3">
                        <input type="checkbox" className="form-check-input" id="inputIndefiniteEndDate" checked={this.state.indefiniteEndDate} onChange={this.handleIndefiniteEndDateChange} />
                        <label className="form-check-label" htmlFor="inputIndefiniteEndDate">Határozatlan idejű szerződés</label>
                    </div>

                    {!this.state.indefiniteEndDate &&
                        <div className="form-group mt-3">
                            <label htmlFor="inputEndDate">Lejárat</label>
                            <Datetime
                                closeOnClickOutside={true}
                                closeOnSelect={true}
                                dateFormat="YYYY.MM.DD"
                                timeFormat={false}
                                utc={true}
                                value={this.state.copierContractCreatePayload.endDate || moment.utc(new Date()).startOf('day').toDate()}
                                onChange={this.handleNewCopierContractEndDateChange} />
                        </div>
                    }

                    {this.state.validationErrors.length > 0 &&
                        <Card className="mt-4 mb-2 p-2 bg-danger lansoft-danger text-white">
                            {this.state.validationErrors.map((error, index) => {
                                return (
                                    <div key={`technician-form-validation-error-${index}`} className="form-invalid">
                                        {error}
                                    </div>
                                );
                            })}
                        </Card>
                    }

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleSaveNewCopierContractClick} type="button" className="btn btn-primary lansoft-primary" disabled={!this.isValidCreate()}>
                            <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Mentés
                        </button>

                        <button className="btn btn-secondary" onClick={this.handleCancelCreateCopierContractClick}>
                            <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private getViewCopierContractModal = () => {
        return (
            <CopierContractDetailsModal
                open={!isNaN(this.state.viewCopierContractIndex)}
                contract={isNaN(this.state.viewCopierContractIndex) ? null : this.state.filteredCopierContracts[this.state.viewCopierContractIndex]}
                close={this.closeViewCopierContractModal}
            />
        );
    }

    private getEditCopierContractModal = () => {
        return (
            <Modal isOpen={!isNaN(this.state.editCopierContractIndex) && !this.props.loading && this.state.filteredCopierContracts.length > this.state.editCopierContractIndex} toggle={this.handleCancelEditCopierContractClick} className="modal-dialog-centered">
                <ModalHeader className="lansoft-success" toggle={this.closeEditCopierContractModal}>Másolástechnikai szerződés módosítása</ModalHeader>
                <ModalBody>
                    <div className="form-group">
                        <label htmlFor="inputEditCopierContractContractNumber">Szerződésszám</label>
                        <input type="text" className="form-control" id="inputEditCopierContractContractNumber" autoComplete="off" placeholder="Szerződésszám" value={this.state.copierContractUpdatePayload.contractNumber} onChange={this.handleEditCopierContractContractNumberChange} />
                    </div>

                    <div className="form-group mt-3 mb-1">
                        <label>Technikus</label>
                        <Dropdown isOpen={this.state.copierContractTechnicianDropdownOpen} toggle={this.toggleCopierContractTechnicianDropdown}>
                            <DropdownToggle className="w-100 lansoft-success" caret>
                                {this.state.copierContractUpdatePayload.technicianId === 0 ? "Válassz" : this.props.technicians.find(x => x.id === this.state.copierContractUpdatePayload.technicianId)!!.name}
                            </DropdownToggle>
                            <DropdownMenu className="w-100 scrollable-dropdown-menu">
                                {this.props.technicians.map((technician, index) =>
                                    <DropdownItem active={this.state.copierContractUpdatePayload.technicianId === technician.id} onClick={() => this.handleEditCopierContractTechnicianChange(technician.id)} key={`edit-copier-contract-technician-dropdown-item-${index}`}>{technician.name}</DropdownItem>
                                )}
                            </DropdownMenu>
                        </Dropdown>
                    </div>

                    <div className="form-group">
                        <label htmlFor="inputEditCopierContractMemo">Memo</label>
                        <input type="text" className="form-control" id="inputEditCopierContractMemo" autoComplete="off" placeholder="Memo" value={this.state.copierContractUpdatePayload.memo} onChange={this.handleEditCopierContractMemoChange} />
                    </div>

                    {this.state.validationErrors.length > 0 &&
                        <Card className="mt-4 mb-2 p-2 bg-danger lansoft-danger text-white">
                            {this.state.validationErrors.map((error, index) => {
                                return (
                                    <div key={`technician-form-validation-error-${index}`} className="form-invalid">
                                        {error}
                                    </div>
                                );
                            })}
                        </Card>
                    }

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleUpdateCopierContractClick} type="button" className="btn btn-primary lansoft-primary" disabled={!this.isValidEdit()}>
                            <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Mentés
                        </button>

                        <button className="btn btn-secondary" onClick={this.handleCancelEditCopierContractClick}>
                            <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private getCloseCopierContractModal = () => {
        return (
            <Modal isOpen={!isNaN(this.state.closeCopierContractIndex) && !this.props.loading} toggle={this.closeCloseCopierContractModal} className="modal-dialog-centered">
                <ModalHeader className="lansoft-success" toggle={this.closeCloseCopierContractModal}>Másolástechnikai szerződés lezárása</ModalHeader>
                <ModalBody>
                    <div>
                        <p>
                            Biztosan le szeretnéd zárni az alábbi másolástechnikai szerződést?
                        </p>
                        <p>{!isNaN(this.state.closeCopierContractIndex) && <strong>{this.state.filteredCopierContracts[this.state.closeCopierContractIndex].contractNumber}</strong>}</p>
                    </div>

                    <div className="form-group mt-3">
                        <label htmlFor="inputEndDate">Lejárat</label>
                        <Datetime
                            closeOnClickOutside={true}
                            closeOnSelect={true}
                            dateFormat="YYYY.MM.DD"
                            timeFormat={false}
                            utc={true}
                            value={this.state.copierContractClosePayload.endDate}
                            onChange={this.handleCopierContractClosePayloadEndDateChange} />
                    </div>

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleConfirmCloseContractClick} type="button" className="btn btn-danger lansoft-danger">
                            <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Lezárás
                        </button>

                        <button className="btn btn-secondary" onClick={this.handleCancelCloseCopierContractClick}>
                            <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private getDeleteCopierContractModal = () => {
        return (
            <Modal isOpen={!isNaN(this.state.deleteCopierContractIndex) && !this.props.loading} toggle={this.closeDeleteCopierContractModal} className="modal-dialog-centered">
                <ModalHeader className="lansoft-success" toggle={this.closeDeleteCopierContractModal}>Másolástechnikai szerződés törlése</ModalHeader>
                <ModalBody>
                    <div>
                        <p>
                            Biztosan törölni szeretnéd az alábbi másolástechnikai szerződést?
                        </p>
                        <p>{!isNaN(this.state.deleteCopierContractIndex) && <strong>{this.state.filteredCopierContracts[this.state.deleteCopierContractIndex].contractNumber}</strong>}</p>
                    </div>

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleConfirmDeleteCopierContractClick} type="button" className="btn btn-danger lansoft-danger">
                            <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Törlés
                        </button>

                        <button className="btn btn-secondary" onClick={this.handleCancelDeleteCopierContractClick}>
                            <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private getCreateItContractModal = () => {
        return (
            <Modal isOpen={!this.props.loading && this.state.createItContractModalOpen} toggle={this.handleCancelCreateItContractClick} className="modal-dialog-centered">
                <ModalHeader className="lansoft-success" toggle={this.handleCancelCreateItContractClick}>Informatikai szerződés létrehozása</ModalHeader>
                <ModalBody>
                    <div className="form-group">
                        <label htmlFor="inputNewItContractContractNumber">Szerződésszám</label>
                        <input type="text" className="form-control" id="inputNewItContractContractNumber" autoComplete="off" placeholder="Szerződésszám" value={this.state.itContractCreatePayload.contractNumber} onChange={this.handleNewItContractContractNumberChange} />
                    </div>

                    <div className="form-group mt-3 mb-1">
                        <label>Ügyfél</label>
                        <Dropdown isOpen={this.state.itContractClientDropdownOpen} toggle={this.toggleItContractClientDropdown}>
                            <DropdownToggle className="w-100 lansoft-success" caret>
                                {this.state.itContractCreatePayload.clientId === 0 ? "Válassz" : this.props.clients.find(x => x.id === this.state.itContractCreatePayload.clientId)!!.name}
                            </DropdownToggle>
                            <DropdownMenu className="w-100 scrollable-dropdown-menu">
                                {this.props.clients.map((client, index) =>
                                    <DropdownItem active={this.state.itContractCreatePayload.clientId === client.id} onClick={() => this.handleNewItContractClientChange(client.id)} key={`new-it-contract-client-dropdown-item-${index}`}>{client.name}</DropdownItem>
                                )}
                            </DropdownMenu>
                        </Dropdown>
                    </div>

                    <div className="form-group mt-3 mb-1">
                        <label>Technikus</label>
                        <Dropdown isOpen={this.state.itContractTechnicianDropdownOpen} toggle={this.toggleItContractTechnicianDropdown}>
                            <DropdownToggle className="w-100 lansoft-success" caret>
                                {this.state.itContractCreatePayload.technicianId === 0 ? "Válassz" : this.props.technicians.find(x => x.id === this.state.itContractCreatePayload.technicianId)!!.name}
                            </DropdownToggle>
                            <DropdownMenu className="w-100 scrollable-dropdown-menu">
                                {this.props.technicians.map((technician, index) =>
                                    <DropdownItem active={this.state.itContractCreatePayload.technicianId === technician.id} onClick={() => this.handleNewItContractTechnicianChange(technician.id)} key={`new-it-contract-technician-dropdown-item-${index}`}>{technician.name}</DropdownItem>
                                )}
                            </DropdownMenu>
                        </Dropdown>
                    </div>

                    <div className="form-group">
                        <label htmlFor="inputNewItContractMemo">Memo</label>
                        <input type="text" className="form-control" id="inputNewItContractMemo" autoComplete="off" placeholder="Memo" value={this.state.itContractCreatePayload.memo} onChange={this.handleNewItContractMemoChange} />
                    </div>

                    <div className="form-group mt-3">
                        <label>Típus</label>
                        <Dropdown isOpen={this.state.itContractContractTypeDropdownOpen} toggle={this.toggleItContractContractTypeDropdown}>
                            <DropdownToggle className="w-100 lansoft-success" caret>
                                {this.state.itContractCreatePayload.contractType === ItContractType.None ? "Válassz" : `${getFriendlyNameFromItContractType(this.state.itContractCreatePayload.contractType)}`}
                            </DropdownToggle>
                            <DropdownMenu className="w-100">
                                {getEnumValues(ItContractType).filter(x => x !== ItContractType.None).map((contractType, index) =>
                                    <DropdownItem active={this.state.itContractCreatePayload.contractType === contractType} onClick={() => this.handleNewItContractTypeChange(contractType)} key={`new-it-contract-type-dropdown-item-${index}`}>{getFriendlyNameFromItContractType(contractType)}</DropdownItem>
                                )}
                            </DropdownMenu>
                        </Dropdown>
                    </div>

                    <div className="form-check mt-3">
                        <input type="checkbox" className="form-check-input" id="inputItContractMaintenanceType" checked={this.state.itContractMaintenanceIntervalType === ItContractMaintenanceIntervalType.Months} onChange={this.toggleItContractMaintenanceIntervalType} />
                        <label className="form-check-label" htmlFor="inputItContractMaintenanceType">Havi megjelenés heti helyett</label>
                    </div>

                    {this.state.itContractMaintenanceIntervalType === ItContractMaintenanceIntervalType.Months &&
                        <div className="form-group mt-3">
                            <label>Megjelenés gyakorisága</label>
                            <Dropdown isOpen={this.state.itContractMaintenanceIntervalMonthsDropdownOpen} toggle={this.toggleItContractMaintenanceIntervalMonthsDropdown}>
                                <DropdownToggle className="w-100 lansoft-success lansoft-success" caret>
                                    {this.state.itContractCreatePayload.maintenanceIntervalMonths == null || this.state.itContractCreatePayload.maintenanceIntervalMonths === 0 ? "Válassz" : `${this.state.itContractCreatePayload.maintenanceIntervalMonths} hónap (G/${this.state.itContractCreatePayload.maintenanceIntervalMonths})`}
                                </DropdownToggle>
                                <DropdownMenu className="w-100 scrollable-dropdown-menu">
                                    {Array.from({ length: 2 }, (_, i) => i + 1).map((months, index) =>
                                        <DropdownItem active={this.state.itContractCreatePayload.maintenanceIntervalMonths === months} onClick={() => this.handleNewItContractMaintenanceIntervalMonthsChange(months)} key={`new-it-contract-maintenance-interval-months-dropdown-item-${index}`}>{`${months} hónap (G/${months})`}</DropdownItem>
                                    )}
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    }

                    {this.state.itContractMaintenanceIntervalType === ItContractMaintenanceIntervalType.Weeks &&
                        <div className="form-group mt-3">
                            <label>Megjelenés gyakorisága</label>
                            <Dropdown isOpen={this.state.itContractMaintenanceIntervalWeeksDropdownOpen} toggle={this.toggleItContractMaintenanceIntervalWeeksDropdown}>
                                <DropdownToggle className="w-100 lansoft-success lansoft-success" caret>
                                    {this.state.itContractCreatePayload.maintenanceIntervalWeeks == null || this.state.itContractCreatePayload.maintenanceIntervalWeeks === 0 ? "Válassz" : `${this.state.itContractCreatePayload.maintenanceIntervalWeeks} hét (W/${this.state.itContractCreatePayload.maintenanceIntervalWeeks})`}
                                </DropdownToggle>
                                <DropdownMenu className="w-100 scrollable-dropdown-menu">
                                    {Array.from({ length: 5 }, (_, i) => i + 1).map((weeks, index) =>
                                        <DropdownItem active={this.state.itContractCreatePayload.maintenanceIntervalWeeks === weeks} onClick={() => this.handleNewItContractMaintenanceIntervalWeeksChange(weeks)} key={`new-it-contract-maintenance-interval-weeks-dropdown-item-${index}`}>{`${weeks} hét (W/${weeks})`}</DropdownItem>
                                    )}
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    }

                    <div className="form-group mt-3">
                        <label htmlFor="inputNewItContractPcCount">PC darabszám</label>
                        <input type="text" className="form-control" id="inputNewItContractPcCount" autoComplete="off" placeholder="PC darabszám" value={getNumberInputValue(this.state.itContractCreatePayload.pcCount)} onChange={this.handleNewItContractPcCountChange} />
                    </div>

                    <div className="form-group mt-3">
                        <label htmlFor="inputNewItContractNotebookCount">Notebook darabszám</label>
                        <input type="text" className="form-control" id="inputNewItContractNotebookCount" autoComplete="off" placeholder="Notebook darabszám" value={getNumberInputValue(this.state.itContractCreatePayload.notebookCount)} onChange={this.handleNewItContractNotebookCountChange} />
                    </div>

                    <div className="form-group mt-3">
                        <label htmlFor="inputNewItContractNetworkingDeviceCount">Hálózati eszköz darabszám</label>
                        <input type="text" className="form-control" id="inputNewItContractNetworkingDeviceCount" autoComplete="off" placeholder="Hálózati eszköz darabszám" value={getNumberInputValue(this.state.itContractCreatePayload.networkingDeviceCount)} onChange={this.handleNewItContractNetworkingDeviceCountChange} />
                    </div>

                    <div className="form-group mt-3">
                        <label htmlFor="inputNewItContractServerCount">Szerver darabszám</label>
                        <input type="text" className="form-control" id="inputNewItContractServerCount" autoComplete="off" placeholder="Szerver darabszám" value={getNumberInputValue(this.state.itContractCreatePayload.serverCount)} onChange={this.handleNewItContractServerCountChange} />
                    </div>

                    <div className="form-group mt-3">
                        <label htmlFor="inputNewItContractNasCount">NAS darabszám</label>
                        <input type="text" className="form-control" id="inputNewItContractNasCount" autoComplete="off" placeholder="NAS darabszám" value={getNumberInputValue(this.state.itContractCreatePayload.nasCount)} onChange={this.handleNewItContractNasCountChange} />
                    </div>

                    <div className="form-group mt-3">
                        <label htmlFor="inputNewItContractMiscellaneousCount">Egyéb eszköz darabszám</label>
                        <input type="text" className="form-control" id="inputNewItContractMiscellaneousCount" autoComplete="off" placeholder="Egyéb eszköz darabszám" value={getNumberInputValue(this.state.itContractCreatePayload.miscellaneousCount)} onChange={this.handleNewItContractMiscellaneousCountChange} />
                    </div>

                    <div className="form-group mt-3">
                        <label htmlFor="inputStartDate">Kezdő dátum</label>
                        <Datetime
                            closeOnClickOutside={true}
                            closeOnSelect={true}
                            dateFormat="YYYY.MM.DD"
                            timeFormat={false}
                            utc={true}
                            value={this.state.itContractCreatePayload.startDate}
                            onChange={this.handleNewItContractStartDateChange} />
                    </div>

                    <div className="form-check mt-3">
                        <input type="checkbox" className="form-check-input" id="inputIndefiniteEndDateIt" checked={this.state.indefiniteEndDateIt} onChange={this.handleIndefiniteEndDateItChange} />
                        <label className="form-check-label" htmlFor="inputIndefiniteEndDateIt">Határozatlan idejű szerződés</label>
                    </div>

                    {!this.state.indefiniteEndDateIt &&
                        <div className="form-group mt-3">
                            <label htmlFor="inputEndDate">Lejárat</label>
                            <Datetime
                                closeOnClickOutside={true}
                                closeOnSelect={true}
                                dateFormat="YYYY.MM.DD"
                                timeFormat={false}
                                utc={true}
                                value={this.state.itContractCreatePayload.endDate || moment.utc(new Date()).startOf('day').toDate()}
                                onChange={this.handleNewItContractEndDateChange} />
                        </div>
                    }

                    {this.state.validationErrorsIt.length > 0 &&
                        <Card className="mt-4 mb-2 p-2 bg-danger lansoft-danger text-white">
                            {this.state.validationErrorsIt.map((error, index) => {
                                return (
                                    <div key={`technician-form-validation-error-${index}`} className="form-invalid">
                                        {error}
                                    </div>
                                );
                            })}
                        </Card>
                    }

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleSaveNewItContractClick} type="button" className="btn btn-primary lansoft-primary" disabled={!this.isValidCreateIt()}>
                            <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Mentés
                        </button>

                        <button className="btn btn-secondary" onClick={this.handleCancelCreateItContractClick}>
                            <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private getViewItContractModal = () => {
        return (
            <ItContractDetailsModal
                open={!isNaN(this.state.viewItContractIndex)}
                contract={isNaN(this.state.viewItContractIndex) ? null : this.state.filteredItContracts[this.state.viewItContractIndex]}
                close={this.closeViewItContractModal}
            />
        );
    }

    private getEditItContractModal = () => {
        return (
            <Modal isOpen={!isNaN(this.state.editItContractIndex) && !this.props.loading && this.state.filteredItContracts.length > this.state.editItContractIndex} toggle={this.handleCancelEditItContractClick} className="modal-dialog-centered">
                <ModalHeader className="lansoft-success" toggle={this.closeEditItContractModal}>Informatikai szerződés módosítása</ModalHeader>
                <ModalBody>
                    <div className="form-group">
                        <label htmlFor="inputEditItContractContractNumber">Szerződésszám</label>
                        <input type="text" className="form-control" id="inputEditItContractContractNumber" autoComplete="off" placeholder="Szerződésszám" value={this.state.itContractUpdatePayload.contractNumber} onChange={this.handleEditItContractContractNumberChange} />
                    </div>

                    <div className="form-group mt-3 mb-1">
                        <label>Technikus</label>
                        <Dropdown isOpen={this.state.itContractTechnicianDropdownOpen} toggle={this.toggleItContractTechnicianDropdown}>
                            <DropdownToggle className="w-100 lansoft-success" caret>
                                {this.state.itContractUpdatePayload.technicianId === 0 ? "Válassz" : this.props.technicians.find(x => x.id === this.state.itContractUpdatePayload.technicianId)!!.name}
                            </DropdownToggle>
                            <DropdownMenu className="w-100 scrollable-dropdown-menu">
                                {this.props.technicians.map((technician, index) =>
                                    <DropdownItem active={this.state.itContractUpdatePayload.technicianId === technician.id} onClick={() => this.handleEditItContractTechnicianChange(technician.id)} key={`edit-it-contract-technician-dropdown-item-${index}`}>{technician.name}</DropdownItem>
                                )}
                            </DropdownMenu>
                        </Dropdown>
                    </div>

                    <div className="form-group">
                        <label htmlFor="inputEditItContractMemo">Memo</label>
                        <input type="text" className="form-control" id="inputEditItContractMemo" autoComplete="off" placeholder="Memo" value={this.state.itContractUpdatePayload.memo} onChange={this.handleEditItContractMemoChange} />
                    </div>

                    <div className="form-group mt-3">
                        <label htmlFor="inputEditItContractPcCount">PC darabszám</label>
                        <input type="text" className="form-control" id="inputEditItContractPcCount" autoComplete="off" placeholder="PC darabszám" value={getNumberInputValue(this.state.itContractUpdatePayload.pcCount)} onChange={this.handleEditItContractPcCountChange} />
                    </div>

                    <div className="form-group mt-3">
                        <label htmlFor="inputEditItContractNotebookCount">Notebook darabszám</label>
                        <input type="text" className="form-control" id="inputEditItContractNotebookCount" autoComplete="off" placeholder="Notebook darabszám" value={getNumberInputValue(this.state.itContractUpdatePayload.notebookCount)} onChange={this.handleEditItContractNotebookCountChange} />
                    </div>

                    <div className="form-group mt-3">
                        <label htmlFor="inputEditItContractNetworkingDeviceCount">Hálózati eszköz darabszám</label>
                        <input type="text" className="form-control" id="inputEditItContractNetworkingDeviceCount" autoComplete="off" placeholder="Hálózati eszköz darabszám" value={getNumberInputValue(this.state.itContractUpdatePayload.networkingDeviceCount)} onChange={this.handleEditItContractNetworkingDeviceCountChange} />
                    </div>

                    <div className="form-group mt-3">
                        <label htmlFor="inputEditItContractServerCount">Szerver darabszám</label>
                        <input type="text" className="form-control" id="inputEditItContractServerCount" autoComplete="off" placeholder="Szerver darabszám" value={getNumberInputValue(this.state.itContractUpdatePayload.serverCount)} onChange={this.handleEditItContractServerCountChange} />
                    </div>

                    <div className="form-group mt-3">
                        <label htmlFor="inputEditItContractNasCount">NAS darabszám</label>
                        <input type="text" className="form-control" id="inputEditItContractNasCount" autoComplete="off" placeholder="NAS darabszám" value={getNumberInputValue(this.state.itContractUpdatePayload.nasCount)} onChange={this.handleEditItContractNasCountChange} />
                    </div>

                    <div className="form-group mt-3">
                        <label htmlFor="inputEditItContractMiscellaneousCount">Egyéb eszköz darabszám</label>
                        <input type="text" className="form-control" id="inputEditItContractMiscellaneousCount" autoComplete="off" placeholder="Egyéb eszköz darabszám" value={getNumberInputValue(this.state.itContractUpdatePayload.miscellaneousCount)} onChange={this.handleEditItContractMiscellaneousCountChange} />
                    </div>

                    {this.state.validationErrorsIt.length > 0 &&
                        <Card className="mt-4 mb-2 p-2 bg-danger lansoft-danger text-white">
                            {this.state.validationErrorsIt.map((error, index) => {
                                return (
                                    <div key={`technician-form-validation-error-${index}`} className="form-invalid">
                                        {error}
                                    </div>
                                );
                            })}
                        </Card>
                    }

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleUpdateItContractClick} type="button" className="btn btn-primary lansoft-primary" disabled={!this.isValidEditIt()}>
                            <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Mentés
                        </button>

                        <button className="btn btn-secondary" onClick={this.handleCancelEditItContractClick}>
                            <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private getCloseItContractModal = () => {
        return (
            <Modal isOpen={!isNaN(this.state.closeItContractIndex) && !this.props.loading} toggle={this.closeCloseItContractModal} className="modal-dialog-centered">
                <ModalHeader className="lansoft-success" toggle={this.closeCloseItContractModal}>Informatikai szerződés lezárása</ModalHeader>
                <ModalBody>
                    <div>
                        <p>
                            Biztosan le szeretnéd zárni az alábbi informatikai szerződést?
                        </p>
                        <p>{!isNaN(this.state.closeItContractIndex) && <strong>{this.state.filteredItContracts[this.state.closeItContractIndex].contractNumber}</strong>}</p>
                    </div>

                    <div className="form-group mt-3">
                        <label htmlFor="inputEndDate">Lejárat</label>
                        <Datetime
                            closeOnClickOutside={true}
                            closeOnSelect={true}
                            dateFormat="YYYY.MM.DD"
                            timeFormat={false}
                            utc={true}
                            value={this.state.itContractClosePayload.endDate}
                            onChange={this.handleItContractClosePayloadEndDateChange} />
                    </div>

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleConfirmCloseItContractClick} type="button" className="btn btn-danger lansoft-danger">
                            <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Lezárás
                        </button>

                        <button className="btn btn-secondary" onClick={this.handleCancelCloseItContractClick}>
                            <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private getDeleteItContractModal = () => {
        return (
            <Modal isOpen={!isNaN(this.state.deleteItContractIndex) && !this.props.loading} toggle={this.closeDeleteItContractModal} className="modal-dialog-centered">
                <ModalHeader className="lansoft-success" toggle={this.closeDeleteItContractModal}>Informatikai szerződés törlése</ModalHeader>
                <ModalBody>
                    <div>
                        <p>
                            Biztosan törölni szeretnéd az alábbi informatikai szerződést?
                        </p>
                        <p>{!isNaN(this.state.deleteItContractIndex) && <strong>{this.state.filteredItContracts[this.state.deleteItContractIndex].contractNumber}</strong>}</p>
                    </div>

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleConfirmDeleteItContractClick} type="button" className="btn btn-danger lansoft-danger">
                            <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Törlés
                        </button>

                        <button className="btn btn-secondary" onClick={this.handleCancelDeleteItContractClick}>
                            <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private handleSearchChange = (e: any) => {
        const searchString = e.target.value;
        this.setState({
            search: searchString,
            filteredCopierContracts: this.filterCopierContracts(this.props.copierContracts, searchString),
            filteredItContracts: this.filterItContracts(this.props.itContracts, searchString)
        });
    }

    private filterCopierContracts = (contracts: CopierContract[], searchString: string): CopierContract[] => {
        return contracts
            .filter(x => {
                return (
                    x.client.name.toLowerCase().indexOf(searchString.toLowerCase()) !== -1 ||
                    x.contractNumber.toLowerCase().indexOf(searchString.toLowerCase()) !== -1 ||
                    x.device.serialNumber.toLowerCase().indexOf(searchString.toLowerCase()) !== -1 ||
                    x.device.model.name.toLowerCase().indexOf(searchString.toLowerCase()) !== -1 ||
                    (x.memo != null && x.memo.toLowerCase().indexOf(searchString.toLowerCase()) !== -1)
                );
            });
    }

    private filterItContracts = (contracts: ItContract[], searchString: string): ItContract[] => {
        return contracts
            .filter(x => {
                return (
                    x.client.name.toLowerCase().indexOf(searchString.toLowerCase()) !== -1 ||
                    x.contractNumber.toLowerCase().indexOf(searchString.toLowerCase()) !== -1 ||
                    (x.memo != null && x.memo.toLowerCase().indexOf(searchString.toLowerCase()) !== -1)
                );
            });
    }

    private closeViewCopierContractModal = () => {
        this.setState({
            viewCopierContractIndex: NaN
        });
    }

    private closeViewItContractModal = () => {
        this.setState({
            viewItContractIndex: NaN
        });
    }

    private toggleItContractMaintenanceIntervalType = () => {
        this.setState({
            itContractMaintenanceIntervalType:
                this.state.itContractMaintenanceIntervalType === ItContractMaintenanceIntervalType.Months
                    ? ItContractMaintenanceIntervalType.Weeks
                    : ItContractMaintenanceIntervalType.Months,
            itContractCreatePayload: {
                ...this.state.itContractCreatePayload,
                maintenanceIntervalWeeks: null,
                maintenanceIntervalMonths: null
            }
        });
    }

    private handleNewCopierContractClientChange = (id: number) => {
        this.setState({
            copierContractCreatePayload: {
                ...this.state.copierContractCreatePayload,
                clientId: id
            }
        }, () => { this.validateCreate(true); });
    }

    private handleNewCopierContractTechnicianChange = (id: number) => {
        this.setState({
            copierContractCreatePayload: {
                ...this.state.copierContractCreatePayload,
                technicianId: id
            }
        }, () => { this.validateCreate(true); });
    }

    private handleEditCopierContractTechnicianChange = (id: number) => {
        this.setState({
            copierContractUpdatePayload: {
                ...this.state.copierContractUpdatePayload,
                technicianId: id
            }
        }, () => { this.validateEdit(true); });
    }

    private handleNewItContractClientChange = (id: number) => {
        this.setState({
            itContractCreatePayload: {
                ...this.state.itContractCreatePayload,
                clientId: id
            }
        }, () => { this.validateCreateIt(true); });
    }

    private handleNewItContractTechnicianChange = (id: number) => {
        this.setState({
            itContractCreatePayload: {
                ...this.state.itContractCreatePayload,
                technicianId: id
            }
        }, () => { this.validateCreateIt(true); });
    }

    private handleEditItContractTechnicianChange = (id: number) => {
        this.setState({
            itContractUpdatePayload: {
                ...this.state.itContractUpdatePayload,
                technicianId: id
            }
        }, () => { this.validateEditIt(true); });
    }

    private handleNewCopierContractDeviceChange = (id: number) => {
        this.setState({
            copierContractCreatePayload: {
                ...this.state.copierContractCreatePayload,
                deviceId: id
            }
        }, () => { this.validateCreate(true); });
    }

    private handleNewCopierContractTypeChange = (contractType: CopierContractType) => {
        this.setState({
            copierContractCreatePayload: {
                ...this.state.copierContractCreatePayload,
                contractType: contractType
            }
        }, () => { this.validateCreate(true); });
    }

    private handleNewItContractTypeChange = (contractType: ItContractType) => {
        this.setState({
            itContractCreatePayload: {
                ...this.state.itContractCreatePayload,
                contractType: contractType
            }
        }, () => { this.validateCreateIt(true); });
    }

    private handleNewCopierContractBlackAndWhiteCounterChange = (e: any) => {
        if (!isNaN(e.target.value)) {
            this.setState({
                copierContractCreatePayload: {
                    ...this.state.copierContractCreatePayload,
                    blackAndWhiteCounter: parseInt(e.target.value.replace(/\D/, ""))
                }
            }, () => { this.validateCreate(true); });
        }
    }

    private handleNewCopierContractColorCounterChange = (e: any) => {
        if (!isNaN(e.target.value)) {
            this.setState({
                copierContractCreatePayload: {
                    ...this.state.copierContractCreatePayload,
                    colorCounter: parseInt(e.target.value.replace(/\D/, ""))
                }
            }, () => { this.validateCreate(true); });
        }
    }

    private handleNewCopierContractMaintenanceIntervalMonthsChange = (months: number) => {
        this.setState({
            copierContractCreatePayload: {
                ...this.state.copierContractCreatePayload,
                maintenanceIntervalMonths: months
            }
        }, () => { this.validateCreate(true); });
    }

    private handleNewItContractMaintenanceIntervalMonthsChange = (months: number) => {
        this.setState({
            itContractCreatePayload: {
                ...this.state.itContractCreatePayload,
                maintenanceIntervalMonths: months
            }
        }, () => { this.validateCreateIt(true); });
    }

    private handleNewItContractMaintenanceIntervalWeeksChange = (weeks: number) => {
        this.setState({
            itContractCreatePayload: {
                ...this.state.itContractCreatePayload,
                maintenanceIntervalWeeks: weeks
            }
        }, () => { this.validateCreateIt(true); });
    }

    private handleNewItContractPcCountChange = (e: any) => {
        if (!isNaN(e.target.value)) {
            this.setState({
                itContractCreatePayload: {
                    ...this.state.itContractCreatePayload,
                    pcCount: parseInt(e.target.value.replace(/\D/, ""))
                }
            }, () => { this.validateCreateIt(true); });
        }
    }

    private handleNewItContractNotebookCountChange = (e: any) => {
        if (!isNaN(e.target.value)) {
            this.setState({
                itContractCreatePayload: {
                    ...this.state.itContractCreatePayload,
                    notebookCount: parseInt(e.target.value.replace(/\D/, ""))
                }
            }, () => { this.validateCreateIt(true); });
        }
    }

    private handleNewItContractNetworkingDeviceCountChange = (e: any) => {
        if (!isNaN(e.target.value)) {
            this.setState({
                itContractCreatePayload: {
                    ...this.state.itContractCreatePayload,
                    networkingDeviceCount: parseInt(e.target.value.replace(/\D/, ""))
                }
            }, () => { this.validateCreateIt(true); });
        }
    }

    private handleNewItContractServerCountChange = (e: any) => {
        if (!isNaN(e.target.value)) {
            this.setState({
                itContractCreatePayload: {
                    ...this.state.itContractCreatePayload,
                    serverCount: parseInt(e.target.value.replace(/\D/, ""))
                }
            }, () => { this.validateCreateIt(true); });
        }
    }

    private handleNewItContractNasCountChange = (e: any) => {
        if (!isNaN(e.target.value)) {
            this.setState({
                itContractCreatePayload: {
                    ...this.state.itContractCreatePayload,
                    nasCount: parseInt(e.target.value.replace(/\D/, ""))
                }
            }, () => { this.validateCreateIt(true); });
        }
    }

    private handleNewItContractMiscellaneousCountChange = (e: any) => {
        if (!isNaN(e.target.value)) {
            this.setState({
                itContractCreatePayload: {
                    ...this.state.itContractCreatePayload,
                    miscellaneousCount: parseInt(e.target.value.replace(/\D/, ""))
                }
            }, () => { this.validateCreateIt(true); });
        }
    }

    private handleEditItContractPcCountChange = (e: any) => {
        if (!isNaN(e.target.value)) {
            this.setState({
                itContractUpdatePayload: {
                    ...this.state.itContractUpdatePayload,
                    pcCount: parseInt(e.target.value.replace(/\D/, ""))
                }
            }, () => { this.validateEditIt(true); });
        }
    }

    private handleEditItContractNotebookCountChange = (e: any) => {
        if (!isNaN(e.target.value)) {
            this.setState({
                itContractUpdatePayload: {
                    ...this.state.itContractUpdatePayload,
                    notebookCount: parseInt(e.target.value.replace(/\D/, ""))
                }
            }, () => { this.validateEditIt(true); });
        }
    }

    private handleEditItContractNetworkingDeviceCountChange = (e: any) => {
        if (!isNaN(e.target.value)) {
            this.setState({
                itContractUpdatePayload: {
                    ...this.state.itContractUpdatePayload,
                    networkingDeviceCount: parseInt(e.target.value.replace(/\D/, ""))
                }
            }, () => { this.validateEditIt(true); });
        }
    }

    private handleEditItContractServerCountChange = (e: any) => {
        if (!isNaN(e.target.value)) {
            this.setState({
                itContractUpdatePayload: {
                    ...this.state.itContractUpdatePayload,
                    serverCount: parseInt(e.target.value.replace(/\D/, ""))
                }
            }, () => { this.validateEditIt(true); });
        }
    }

    private handleEditItContractNasCountChange = (e: any) => {
        if (!isNaN(e.target.value)) {
            this.setState({
                itContractUpdatePayload: {
                    ...this.state.itContractUpdatePayload,
                    nasCount: parseInt(e.target.value.replace(/\D/, ""))
                }
            }, () => { this.validateEditIt(true); });
        }
    }

    private handleEditItContractMiscellaneousCountChange = (e: any) => {
        if (!isNaN(e.target.value)) {
            this.setState({
                itContractUpdatePayload: {
                    ...this.state.itContractUpdatePayload,
                    miscellaneousCount: parseInt(e.target.value.replace(/\D/, ""))
                }
            }, () => { this.validateEditIt(true); });
        }
    }

    private handleNewCopierContractMonthlyLeasePriceChange = (e: any) => {
        if (!isNaN(e.target.value)) {
            this.setState({
                copierContractCreatePayload: {
                    ...this.state.copierContractCreatePayload,
                    monthlyLeasePrice: parseInt(e.target.value.replace(/\D/, ""))
                }
            }, () => { this.validateCreate(true); });
        }
    }

    private handleNewCopierContractIncludedBlackAndWhitePrintsChange = (e: any) => {
        if (!isNaN(e.target.value)) {
            this.setState({
                copierContractCreatePayload: {
                    ...this.state.copierContractCreatePayload,
                    includedBlackAndWhitePrints: parseInt(e.target.value.replace(/\D/, ""))
                }
            }, () => { this.validateCreate(true); });
        }
    }

    private handleNewCopierContractBlackAndWhitePrintPriceChange = (e: any) => {
        const raw = e.target.value;
        if (!isNaN(e.target.value)) {
            this.setState({
                copierContractCreatePayload: {
                    ...this.state.copierContractCreatePayload,
                    blackAndWhitePrintPrice: parseFloat(e.target.value)
                },
                blackAndWhitePrintPriceRaw: raw
            }, () => { this.validateCreate(true); });
        }
    }

    private handleNewCopierContractColorPrintPriceChange = (e: any) => {
        const raw = e.target.value;
        if (!isNaN(e.target.value)) {
            this.setState({
                copierContractCreatePayload: {
                    ...this.state.copierContractCreatePayload,
                    colorPrintPrice: parseFloat(e.target.value)
                },
                colorPrintPriceRaw: raw
            }, () => { this.validateCreate(true); });
        }
    }

    private handleNewCopierContractStartDateChange = (date: any) => {
        this.setState({
            copierContractCreatePayload: {
                ...this.state.copierContractCreatePayload,
                startDate: moment.utc(date).startOf('day').toDate()
            }
        }, () => { this.validateCreate(true); });
    }

    private handleNewCopierContractEndDateChange = (date: any) => {
        this.setState({
            copierContractCreatePayload: {
                ...this.state.copierContractCreatePayload,
                endDate: moment.utc(date).startOf('day').toDate()
            }
        }, () => { this.validateCreate(true); });
    }

    private handleCopierContractClosePayloadEndDateChange = (date: any) => {
        this.setState({
            copierContractClosePayload: {
                ...this.state.copierContractClosePayload,
                endDate: moment.utc(date).startOf('day').toDate()
            }
        });
    }

    private handleNewItContractStartDateChange = (date: any) => {
        this.setState({
            itContractCreatePayload: {
                ...this.state.itContractCreatePayload,
                startDate: moment.utc(date).startOf('day').toDate()
            }
        }, () => { this.validateCreateIt(true); });
    }

    private handleNewItContractEndDateChange = (date: any) => {
        this.setState({
            itContractCreatePayload: {
                ...this.state.itContractCreatePayload,
                endDate: moment.utc(date).startOf('day').toDate()
            }
        }, () => { this.validateCreateIt(true); });
    }

    private handleItContractClosePayloadEndDateChange = (date: any) => {
        this.setState({
            itContractClosePayload: {
                ...this.state.itContractClosePayload,
                endDate: moment.utc(date).startOf('day').toDate()
            }
        });
    }

    private handleIndefiniteEndDateChange = (e: any) => {
        this.setState({
            indefiniteEndDate: e.target.checked
        }, () => { this.validateCreate(true); });
    }

    private handleIndefiniteEndDateItChange = (e: any) => {
        this.setState({
            indefiniteEndDateIt: e.target.checked
        }, () => { this.validateCreateIt(true); });
    }

    private toggleCopierContractClientDropdown = () => {
        this.setState({
            copierContractClientDropdownOpen: !this.state.copierContractClientDropdownOpen
        });
    }

    private toggleCopierContractTechnicianDropdown = () => {
        this.setState({
            copierContractTechnicianDropdownOpen: !this.state.copierContractTechnicianDropdownOpen
        });
    }

    private toggleItContractClientDropdown = () => {
        this.setState({
            itContractClientDropdownOpen: !this.state.itContractClientDropdownOpen
        });
    }

    private toggleItContractTechnicianDropdown = () => {
        this.setState({
            itContractTechnicianDropdownOpen: !this.state.itContractTechnicianDropdownOpen
        });
    }

    private toggleCopierContractDeviceDropdown = () => {
        this.setState({
            copierContractDeviceDropdownOpen: !this.state.copierContractDeviceDropdownOpen
        });
    }

    private toggleCopierContractContractTypeDropdown = () => {
        this.setState({
            copierContractContractTypeDropdownOpen: !this.state.copierContractContractTypeDropdownOpen
        });
    }

    private toggleCopierContractMaintenanceIntervalMonthsDropdown = () => {
        this.setState({
            copierContractMaintenanceIntervalMonthsDropdownOpen: !this.state.copierContractMaintenanceIntervalMonthsDropdownOpen
        });
    }

    private toggleItContractContractTypeDropdown = () => {
        this.setState({
            itContractContractTypeDropdownOpen: !this.state.itContractContractTypeDropdownOpen
        });
    }

    private toggleItContractMaintenanceIntervalMonthsDropdown = () => {
        this.setState({
            itContractMaintenanceIntervalMonthsDropdownOpen: !this.state.itContractMaintenanceIntervalMonthsDropdownOpen
        });
    }

    private toggleItContractMaintenanceIntervalWeeksDropdown = () => {
        this.setState({
            itContractMaintenanceIntervalWeeksDropdownOpen: !this.state.itContractMaintenanceIntervalWeeksDropdownOpen
        });
    }

    private resetCreateCopierContractForm = () => {
        this.setState({
            createCopierContractModalOpen: false,
            copierContractCreatePayload: {
                contractNumber: "",
                clientId: 0,
                technicianId: 0,
                deviceId: 0,
                memo: "",
                contractType: CopierContractType.None,
                blackAndWhiteCounter: 0,
                colorCounter: null,
                maintenanceIntervalMonths: null,
                monthlyLeasePrice: 0,
                includedBlackAndWhitePrints: 0,
                blackAndWhitePrintPrice: 0,
                colorPrintPrice: 0,
                startDate: moment.utc(new Date()).startOf('day').toDate(),
                endDate: null
            },
            indefiniteEndDate: false,
            validationErrors: []
        });
    }

    private resetEditCopierContractForm = () => {
        this.setState({
            copierContractUpdatePayload: {
                contractNumber: "",
                technicianId: 0,
                memo: "",
            },
            editCopierContractIndex: NaN,
            validationErrors: []
        }, () => { this.validateEdit(true); });
    }

    private resetCreateItContractForm = () => {
        this.setState({
            createItContractModalOpen: false,
            itContractCreatePayload: {
                contractNumber: "",
                clientId: 0,
                technicianId: 0,
                memo: "",
                contractType: ItContractType.None,
                maintenanceIntervalWeeks: null,
                maintenanceIntervalMonths: null,
                pcCount: 0,
                notebookCount: 0,
                networkingDeviceCount: 0,
                serverCount: 0,
                nasCount: 0,
                miscellaneousCount: 0,
                startDate: moment.utc(new Date()).startOf('day').toDate(),
                endDate: null
            },
            indefiniteEndDateIt: false,
            validationErrorsIt: []
        });
    }

    private resetEditItContractForm = () => {
        this.setState({
            itContractUpdatePayload: {
                contractNumber: "",
                technicianId: 0,
                pcCount: 0,
                notebookCount: 0,
                networkingDeviceCount: 0,
                serverCount: 0,
                nasCount: 0,
                miscellaneousCount: 0,
                memo: ""
            },
            editItContractIndex: NaN,
            validationErrorsIt: []
        }, () => { this.validateEditIt(true); });
    }

    private handleCancelCreateCopierContractClick = () => {
        this.resetCreateCopierContractForm();
        this.setState({
            createCopierContractModalOpen: false
        });
    }

    private handleCancelEditCopierContractClick = () => {
        this.resetEditCopierContractForm();
        this.setState({
            editCopierContractIndex: NaN
        });
    }

    private handleCancelCloseCopierContractClick = () => {
        this.setState({
            closeCopierContractIndex: NaN,
            copierContractClosePayload: {
                endDate: moment.utc(new Date()).startOf('day').toDate()
            }
        });
    }

    private handleCancelDeleteCopierContractClick = () => {
        this.setState({
            deleteCopierContractIndex: NaN
        });
    }

    private handleSaveNewCopierContractClick = () => {
        this.props.createCopierContract(this.state.copierContractCreatePayload);
        this.resetCreateCopierContractForm();
    }

    private handleUpdateCopierContractClick = () => {
        const copierContract = this.state.filteredCopierContracts[this.state.editCopierContractIndex];
        this.props.updateCopierContract(copierContract.id, this.state.copierContractUpdatePayload);
        this.resetEditCopierContractForm();
    }

    private handleConfirmCloseContractClick = () => {
        const copierContract = this.state.filteredCopierContracts[this.state.closeCopierContractIndex];
        this.props.closeCopierContract(copierContract.id, this.state.copierContractClosePayload);
        this.setState({
            closeCopierContractIndex: NaN,
            copierContractClosePayload: {
                endDate: moment.utc(new Date()).startOf('day').toDate()
            }
        });
    }

    private handleConfirmDeleteCopierContractClick = () => {
        const copierContract = this.state.filteredCopierContracts[this.state.deleteCopierContractIndex];
        this.props.deleteCopierContract(copierContract.id);
        this.setState({
            deleteCopierContractIndex: NaN
        });
    }

    private handleCancelCreateItContractClick = () => {
        this.resetCreateItContractForm();
        this.setState({
            createItContractModalOpen: false
        });
    }

    private handleCancelEditItContractClick = () => {
        this.resetEditItContractForm();
        this.setState({
            editItContractIndex: NaN
        });
    }

    private handleCancelCloseItContractClick = () => {
        this.setState({
            closeItContractIndex: NaN,
            itContractClosePayload: {
                endDate: moment.utc(new Date()).startOf('day').toDate()
            }
        });
    }

    private handleCancelDeleteItContractClick = () => {
        this.setState({
            deleteItContractIndex: NaN
        });
    }

    private handleSaveNewItContractClick = () => {
        this.props.createItContract(this.state.itContractCreatePayload);
        this.resetCreateItContractForm();
    }

    private handleUpdateItContractClick = () => {
        const itContract = this.state.filteredItContracts[this.state.editItContractIndex];
        this.props.updateItContract(itContract.id, this.state.itContractUpdatePayload);
        this.resetEditItContractForm();
    }

    private handleConfirmCloseItContractClick = () => {
        const itContract = this.state.filteredItContracts[this.state.closeItContractIndex];
        this.props.closeItContract(itContract.id, this.state.itContractClosePayload);
        this.setState({
            closeItContractIndex: NaN,
            itContractClosePayload: {
                endDate: moment.utc(new Date()).startOf('day').toDate()
            }
        });
    }

    private handleConfirmDeleteItContractClick = () => {
        const itContract = this.state.filteredItContracts[this.state.deleteItContractIndex];
        this.props.deleteItContract(itContract.id);
        this.setState({
            deleteItContractIndex: NaN
        });
    }

    private handleNewCopierContractContractNumberChange = (e: any) => {
        this.setState({
            copierContractCreatePayload: {
                ...this.state.copierContractCreatePayload,
                contractNumber: e.target.value
            }
        }, () => { this.validateCreate(true); });
    }

    private handleNewCopierContractMemoChange = (e: any) => {
        this.setState({
            copierContractCreatePayload: {
                ...this.state.copierContractCreatePayload,
                memo: e.target.value
            }
        }, () => { this.validateCreate(true); });
    }

    private handleEditCopierContractContractNumberChange = (e: any) => {
        this.setState({
            copierContractUpdatePayload: {
                ...this.state.copierContractUpdatePayload,
                contractNumber: e.target.value
            }
        }, () => { this.validateEdit(true); });
    }

    private handleEditCopierContractMemoChange = (e: any) => {
        this.setState({
            copierContractUpdatePayload: {
                ...this.state.copierContractUpdatePayload,
                memo: e.target.value
            }
        }, () => { this.validateEdit(true); });
    }

    private handleNewItContractContractNumberChange = (e: any) => {
        this.setState({
            itContractCreatePayload: {
                ...this.state.itContractCreatePayload,
                contractNumber: e.target.value
            }
        }, () => { this.validateCreateIt(true); });
    }

    private handleNewItContractMemoChange = (e: any) => {
        this.setState({
            itContractCreatePayload: {
                ...this.state.itContractCreatePayload,
                memo: e.target.value
            }
        }, () => { this.validateCreateIt(true); });
    }

    private handleEditItContractContractNumberChange = (e: any) => {
        this.setState({
            itContractUpdatePayload: {
                ...this.state.itContractUpdatePayload,
                contractNumber: e.target.value
            }
        }, () => { this.validateEditIt(true); });
    }

    private handleEditItContractMemoChange = (e: any) => {
        this.setState({
            itContractUpdatePayload: {
                ...this.state.itContractUpdatePayload,
                memo: e.target.value
            }
        }, () => { this.validateEditIt(true); });
    }

    private closeEditCopierContractModal = () => {
        this.setState({
            editCopierContractIndex: NaN
        });
    }

    private closeCloseCopierContractModal = () => {
        this.setState({
            closeCopierContractIndex: NaN,
            copierContractClosePayload: {
                endDate: moment.utc(new Date()).startOf('day').toDate()
            }
        });
    }

    private closeDeleteCopierContractModal = () => {
        this.setState({
            deleteCopierContractIndex: NaN
        });
    }

    private handleCreateCopierContractClick = () => {
        this.resetCreateCopierContractForm();

        this.setState({
            createCopierContractModalOpen: true
        });
    }

    private closeEditItContractModal = () => {
        this.setState({
            editItContractIndex: NaN
        });
    }

    private closeCloseItContractModal = () => {
        this.setState({
            closeItContractIndex: NaN,
            itContractClosePayload: {
                endDate: moment.utc(new Date()).startOf('day').toDate()
            }
        });
    }

    private closeDeleteItContractModal = () => {
        this.setState({
            deleteItContractIndex: NaN
        });
    }

    private handleCreateItContractClick = () => {
        this.resetCreateItContractForm();

        this.setState({
            createItContractModalOpen: true
        });
    }

    private handleEditCopierContractClick = (index: number) => {
        this.resetEditCopierContractForm();

        const contract = this.state.filteredCopierContracts[index];

        this.setState({
            editCopierContractIndex: index,
            copierContractUpdatePayload: {
                contractNumber: contract.contractNumber,
                technicianId: contract.technician.id,
                memo: contract.memo
            }
        });
    }

    private handleCloseCopierContractClick = (index: number) => {
        this.setState({
            closeCopierContractIndex: index
        });
    }

    private handleDeleteCopierContractClick = (index: number) => {
        this.setState({
            deleteCopierContractIndex: index
        });
    }

    private handleViewCopierContractClick = (index: number) => {
        this.setState({
            viewCopierContractIndex: index
        });
    }

    private handleEditItContractClick = (index: number) => {
        this.resetEditItContractForm();

        const contract = this.state.filteredItContracts[index];

        this.setState({
            editItContractIndex: index,
            itContractUpdatePayload: {
                contractNumber: contract.contractNumber,
                technicianId: contract.technician.id,
                memo: contract.memo,
                pcCount: contract.pcCount,
                serverCount: contract.serverCount,
                notebookCount: contract.notebookCount,
                networkingDeviceCount: contract.networkingDeviceCount,
                nasCount: contract.nasCount,
                miscellaneousCount: contract.miscellaneousCount
            }
        });
    }

    private handleCloseItContractClick = (index: number) => {
        this.setState({
            closeItContractIndex: index
        });
    }

    private handleDeleteItContractClick = (index: number) => {
        this.setState({
            deleteItContractIndex: index
        });
    }

    private handleViewItContractClick = (index: number) => {
        this.setState({
            viewItContractIndex: index
        });
    }

    private isValidCreate = () => {
        return this.validateCreate();
    }

    private validateCreate = (shouldSetState: boolean = false) => {
        const validationErrors: string[] = [];

        if (this.state.copierContractCreatePayload.contractNumber.length === 0) {
            validationErrors.push("A szerződésszám megadása kötelező.");
        }

        if (this.state.copierContractCreatePayload.clientId === 0) {
            validationErrors.push("Az ügyfél kiválasztása kötelező.");
        }

        if (this.state.copierContractCreatePayload.technicianId === 0) {
            validationErrors.push("A technikus kiválasztása kötelező.");
        }

        if (this.state.copierContractCreatePayload.deviceId === 0) {
            validationErrors.push("A gép kiválasztása kötelező.");
        }

        if (this.state.copierContractCreatePayload.contractType === CopierContractType.None) {
            validationErrors.push("A típus kiválasztása kötelező.");
        }

        if (this.state.copierContractCreatePayload.blackAndWhiteCounter === 0) {
            validationErrors.push("A fekete-fehér számláló kitöltése kötelező.");
        }

        if (this.state.copierContractCreatePayload.deviceId !== 0 && this.props.copierDevices.find(x => x.id === this.state.copierContractCreatePayload.deviceId)!!.model.color && this.state.copierContractCreatePayload.colorCounter == null) {
            validationErrors.push("A színes számláló kitöltése kötelező.");
        }

        if (this.state.copierContractCreatePayload.contractType === CopierContractType.Monthly && this.state.copierContractCreatePayload.maintenanceIntervalMonths == null) {
            validationErrors.push("A megjelenés gyakoriságának kiválasztása kötelező.");
        }

        if (this.state.copierContractCreatePayload.contractType === CopierContractType.Monthly && this.state.copierContractCreatePayload.maintenanceIntervalMonths == null) {
            validationErrors.push("A megjelenés gyakoriságának kiválasztása kötelező.");
        }

        if ((this.state.copierContractCreatePayload.contractType === CopierContractType.Lease || this.state.copierContractCreatePayload.contractType === CopierContractType.Monthly) && this.state.copierContractCreatePayload.monthlyLeasePrice === 0) {
            validationErrors.push("A bérleti díj kitöltése kötelező.");
        }

        if (this.state.copierContractCreatePayload.contractType === CopierContractType.Lease && isNaN(this.state.copierContractCreatePayload.includedBlackAndWhitePrints)) {
            validationErrors.push("A benne foglalt fekete nyomat kitöltése kötelező.");
        }

        if ((this.state.copierContractCreatePayload.contractType === CopierContractType.Lease || this.state.copierContractCreatePayload.contractType === CopierContractType.AllRoundOperation) && isNaN(this.state.copierContractCreatePayload.blackAndWhitePrintPrice)) {
            validationErrors.push("A fekete-fehér nyomatdíj kitöltése kötelező.");
        }

        if (this.state.copierContractCreatePayload.deviceId !== 0 && this.props.copierDevices.find(x => x.id === this.state.copierContractCreatePayload.deviceId)!!.model.color && (this.state.copierContractCreatePayload.contractType === CopierContractType.Lease || this.state.copierContractCreatePayload.contractType === CopierContractType.AllRoundOperation) && isNaN(this.state.copierContractCreatePayload.colorPrintPrice)) {
            validationErrors.push("A színes nyomatdíj kitöltése kötelező.");
        }

        if (shouldSetState) {
            this.setState({
                validationErrors: validationErrors
            });
        }

        return validationErrors.length === 0;
    }

    private isValidCreateIt = () => {
        return this.validateCreateIt();
    }

    private validateCreateIt = (shouldSetState: boolean = false) => {
        const validationErrors: string[] = [];

        if (this.state.itContractCreatePayload.contractNumber.length === 0) {
            validationErrors.push("A szerződésszám megadása kötelező.");
        }

        if (this.state.itContractCreatePayload.clientId === 0) {
            validationErrors.push("Az ügyfél kiválasztása kötelező.");
        }

        if (this.state.itContractCreatePayload.technicianId === 0) {
            validationErrors.push("A technikus kiválasztása kötelező.");
        }

        if (this.state.itContractCreatePayload.contractType === ItContractType.None) {
            validationErrors.push("A típus kiválasztása kötelező.");
        }

        if ((this.state.itContractMaintenanceIntervalType === ItContractMaintenanceIntervalType.Months && this.state.itContractCreatePayload.maintenanceIntervalMonths == null)
            || (this.state.itContractMaintenanceIntervalType === ItContractMaintenanceIntervalType.Weeks && this.state.itContractCreatePayload.maintenanceIntervalWeeks == null)) {
            validationErrors.push("A megjelenés gyakoriságának kiválasztása kötelező.");
        }

        if (shouldSetState) {
            this.setState({
                validationErrorsIt: validationErrors
            });
        }

        return validationErrors.length === 0;
    }

    private isValidEdit = () => {
        return this.validateEdit();
    }

    private validateEdit = (shouldSetState: boolean = false) => {
        const validationErrors: string[] = [];

        if (this.state.copierContractUpdatePayload.contractNumber.length === 0) {
            validationErrors.push("A szerződésszám megadása kötelező.");
        }

        if (this.state.copierContractUpdatePayload.technicianId === 0) {
            validationErrors.push("A technikus kiválasztása kötelező.");
        }

        if (shouldSetState) {
            this.setState({
                validationErrors: validationErrors
            });
        }

        return validationErrors.length === 0;
    }

    private isValidEditIt = () => {
        return this.validateEditIt();
    }

    private validateEditIt = (shouldSetState: boolean = false) => {
        const validationErrors: string[] = [];

        if (this.state.itContractUpdatePayload.contractNumber.length === 0) {
            validationErrors.push("A szerződésszám megadása kötelező.");
        }

        if (this.state.itContractUpdatePayload.technicianId === 0) {
            validationErrors.push("A technikus kiválasztása kötelező.");
        }

        if (shouldSetState) {
            this.setState({
                validationErrorsIt: validationErrors
            });
        }

        return validationErrors.length === 0;
    }
}

export default connect(
    (state: ApplicationState) => state.lanSoft,
    LanSoftStore.actionCreators
)(ContractsAdmin as any);

import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, CardBody, CardTitle, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Tooltip } from 'reactstrap';
import { ItContract, ItSupportTicket, ItTicketSolutionType, ItTicketState, HistoryItemType } from "../models";
import { getFriendlyNameFromItContractType, getIndicatorClassFromHistoryItemType, getTooltipTextFromHistoryItemType, getIndicatorClassFromItTicket, getTooltipTextFromItTicket } from "../utils/EnumUtils";
import { getItContractDeviceCount, MAX_IT_CONTRACT_TYPE_LENGTH_DISPLAY } from "../utils";
import moment from 'moment';
import ItSupportTicketModal from "./ItSupportTicketModal";
import { ItSupportTicketSolvePayload } from '../payloads';
import { isMobileOnly } from "react-device-detect";
import Datetime from 'react-datetime';

interface ItSupportTicketCardPropsType {
    isExpanded: boolean;
    ticket: ItSupportTicket;
    contract: ItContract;
    expandCollapse: () => void;
    ticketSolutionTypes: ItTicketSolutionType[];
    handleSolveClick: (payload: ItSupportTicketSolvePayload) => void;
}

interface ItSupportTicketCardState {
    ticketSolutionTypeDropdownOpen: boolean;
    ticketSolutionTypeId: number;
    messageToClient: string;
    proposedState: ItTicketState | null;
    solvedAt: Date;
    validationErrors: string[];
    ticketIndicatorTooltipOpen: boolean;
    contractNumberTooltipOpen: boolean;
    contractTypeTooltipOpen: boolean;
    deviceCountTooltipOpen: boolean;
    maintenanceIntervalTooltipOpen: boolean;
    memoTooltipOpen: boolean;
    historyItemIndicatorTooltipOpen: number;
    ticketHistoryItem: ItSupportTicket | null;
    ticketHistoryItemModalOpen: boolean;
    itDeviceModelExpanded: boolean;
    contractTypeExpanded: boolean;
}

export class ItSupportTicketCard extends React.PureComponent<ItSupportTicketCardPropsType, ItSupportTicketCardState> {
    constructor(props: ItSupportTicketCardPropsType) {
        super(props);

        this.state = {
            ticketSolutionTypeDropdownOpen: false,
            ticketSolutionTypeId: props.ticket.solutionType != null ? props.ticket.solutionType.id : 0,
            messageToClient: props.ticket.messageToClient || "",
            proposedState: props.ticket.state === ItTicketState.Open ? ItTicketState.Solved : props.ticket.state,
            solvedAt: props.ticket.solvedAt != null ? new Date(props.ticket.solvedAt) : new Date(),
            validationErrors: [],
            ticketIndicatorTooltipOpen: false,
            contractNumberTooltipOpen: false,
            contractTypeTooltipOpen: false,
            deviceCountTooltipOpen: false,
            maintenanceIntervalTooltipOpen: false,
            memoTooltipOpen: false,
            historyItemIndicatorTooltipOpen: NaN,
            ticketHistoryItem: null,
            ticketHistoryItemModalOpen: false,
            itDeviceModelExpanded: false,
            contractTypeExpanded: false
        }
    }

    public render() {
        const contractTypeLong = getFriendlyNameFromItContractType(this.props.contract.contractType).length > MAX_IT_CONTRACT_TYPE_LENGTH_DISPLAY;

        const addressSecondSpaceIndex = this.props.contract.client.address.indexOf(" ", this.props.contract.client.address.indexOf(" ") + 1);
        const firstAddressChunk = addressSecondSpaceIndex >= 0 ? this.props.contract.client.address.substr(0, addressSecondSpaceIndex).trim() : this.props.contract.client.address.substr(addressSecondSpaceIndex + 1).trim();
        let secondAddressChunk = "";
        if (addressSecondSpaceIndex >= 0) {
            secondAddressChunk = this.props.contract.client.address.substr(addressSecondSpaceIndex + 1).trim();
        }

        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <CardTitle className="mb-0">
                            {isMobileOnly &&
                                <div className="h4 d-flex justify-content-between pb-1 mobile row border-bottom lansoft-border-success lansoft-border-full">
                                    <div />
                                    <div className="d-flex align-items-center mb-3">
                                        <div id={`it-support-ticket-ticket-indicator-${this.props.ticket.id}-${this.props.contract.id}`} className={`mr-1 ticket-indicator bg-${getIndicatorClassFromItTicket(this.props.ticket)}`}></div>
                                        <Tooltip placement="top" isOpen={this.state.ticketIndicatorTooltipOpen} target={`it-support-ticket-ticket-indicator-${this.props.ticket.id}-${this.props.contract.id}`} toggle={this.toggleTicketIndicatorTooltip}>
                                            {getTooltipTextFromItTicket(this.props.ticket)}
                                        </Tooltip>
                                        <span id={`it-contract-it-contract-number-${this.props.contract.id}`}>{this.props.contract.contractNumber}</span>
                                        <Tooltip placement="top" isOpen={this.state.contractNumberTooltipOpen} target={`it-contract-it-contract-number-${this.props.contract.id}`} toggle={this.toggleContractNumberTooltip}>
                                            Szerződésszám
                                        </Tooltip>
                                    </div>
                                    <div className="cursor-pointer mr-3" onClick={() => this.handleExpandClick()}><FontAwesomeIcon icon={["fas", this.props.isExpanded ? "chevron-up" : "chevron-down"]} className="mr-1" /></div>
                                </div>
                            }
                            <div className={`h4 d-flex justify-content-between flex-same-width-container ${isMobileOnly ? "mobile row pt-3" : "mt-4 mb-4 pb-2"}`}>
                                {!isMobileOnly && <div className={isMobileOnly ? "col-6" : ""}>
                                    <div className={`d-flex align-items-center ${isMobileOnly ? "mb-3" : ""}`}>
                                        <div id={`it-support-ticket-ticket-indicator-${this.props.ticket.id}-${this.props.contract.id}`} className={`mr-1 ticket-indicator bg-${getIndicatorClassFromItTicket(this.props.ticket)}`}></div>
                                        <Tooltip placement="top" isOpen={this.state.ticketIndicatorTooltipOpen} target={`it-support-ticket-ticket-indicator-${this.props.ticket.id}-${this.props.contract.id}`} toggle={this.toggleTicketIndicatorTooltip}>
                                            {getTooltipTextFromItTicket(this.props.ticket)}
                                        </Tooltip>
                                        <span id={`it-contract-contract-number-${this.props.contract.id}`}>{this.props.contract.contractNumber}</span>
                                        <Tooltip placement="top" isOpen={this.state.contractNumberTooltipOpen} target={`it-contract-contract-number-${this.props.contract.id}`} toggle={this.toggleContractNumberTooltip}>
                                            Szerződésszám
                                        </Tooltip>
                                    </div>
                                </div>}
                                <div className={isMobileOnly ? "col-6" : ""}>
                                    <div className={`d-flex ${isMobileOnly ? "mb-3" : ""}`}>
                                        <span id={`it-contract-it-maintenance-interval-${this.props.contract.id}`}>{this.props.contract.maintenanceIntervalMonths == null ? `W/${this.props.contract.maintenanceIntervalWeeks}` : `G/${this.props.contract.maintenanceIntervalMonths}`}</span>
                                        <Tooltip placement="top" isOpen={this.state.maintenanceIntervalTooltipOpen} target={`it-contract-it-maintenance-interval-${this.props.contract.id}`} toggle={this.toggleMaintenanceIntervalTooltip}>
                                            Megjelenés gyakorisága
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className={isMobileOnly ? "col-6 mb-3 d-flex" : ""}>
                                    <div onClick={this.handleContractTypeExpandClick} className={isMobileOnly ? "d-flex justify-content-end" : "cursor-pointer"}>
                                        <div id={`it-contract-it-contract-type-${this.props.contract.id}`} className="d-inline-block">
                                            {!contractTypeLong && <React.Fragment>{getFriendlyNameFromItContractType(this.props.contract.contractType)}</React.Fragment>}
                                            {contractTypeLong && <React.Fragment>
                                                {!this.state.contractTypeExpanded && <React.Fragment>
                                                    {getFriendlyNameFromItContractType(this.props.contract.contractType).substring(0, MAX_IT_CONTRACT_TYPE_LENGTH_DISPLAY - 2) + "..."}
                                                </React.Fragment>}
                                                {this.state.contractTypeExpanded && <React.Fragment>
                                                    {getFriendlyNameFromItContractType(this.props.contract.contractType).split(" ").map((fragment, index) =>
                                                        <span className="nowrap d-block" key={`contract-type-fragment-${index}`}>{fragment}</span>)
                                                    }
                                                </React.Fragment>}
                                            </React.Fragment>}
                                        </div>
                                        <Tooltip placement="top" isOpen={this.state.contractTypeTooltipOpen} target={`it-contract-it-contract-type-${this.props.contract.id}`} toggle={this.toggleContractTypeTooltip}>
                                            Szerződés típusa
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className={isMobileOnly ? "col-6 mb-3 d-flex" : ""}>
                                    <div className={isMobileOnly ? "text-right" : ""}>
                                        <div id={`it-contract-device-count-${this.props.contract.id}`} className="d-inline-block">
                                            {getItContractDeviceCount(this.props.contract)} eszköz
                                        </div>
                                        <Tooltip placement="top" isOpen={this.state.deviceCountTooltipOpen} target={`it-contract-device-count-${this.props.contract.id}`} toggle={this.toggleDeviceCountTooltip}>
                                            Eszközök száma
                                    </Tooltip>
                                    </div>
                                </div>
                                <div className={`flex-half-width-column ${isMobileOnly ? "mobile col" : ""}`}>
                                    <div className={`d-flex ${!isMobileOnly ? "justify-content-end" : ""}`}>
                                        <div id={`it-contract-memo-${this.props.contract.id}`}>
                                            {this.props.contract.memo}
                                        </div>
                                        <Tooltip placement="top" isOpen={this.state.memoTooltipOpen} target={`it-contract-memo-${this.props.contract.id}`} toggle={this.toggleMemoTooltip}>
                                            Memo
                                        </Tooltip>
                                        {!isMobileOnly && <div className="cursor-pointer ml-3" onClick={() => this.handleExpandClick()}><FontAwesomeIcon icon={["fas", this.props.isExpanded ? "chevron-up" : "chevron-down"]} className="mr-1" /></div>}
                                    </div>
                                </div>
                            </div>

                            <div className="mt-1 pt-1 border-top lansoft-border-success"></div>

                            {isMobileOnly && <div className="col-12 mb-2 mt-2 text-center">
                                <div className="h5 no-bottom-margin">
                                    Technikus: {this.props.contract.technician.name}
                                </div>
                            </div>}

                            <div className={isMobileOnly ? "pt-2" : "pt-4 pb-2"}>
                                <div className={`d-flex justify-content-between flex-same-width-container ${isMobileOnly ? "mobile row" : ""}`}>
                                    <div className={isMobileOnly ? "col-12 mb-4" : ""}>
                                        <div>
                                            <div className="h5 mb-3"><strong>{this.props.contract.client.name}</strong></div>
                                            <div className="h6 mb-3">
                                                {addressSecondSpaceIndex >= 0 ?
                                                    <div>
                                                        <div>{firstAddressChunk}</div>
                                                        <div>{secondAddressChunk}</div>
                                                    </div>
                                                    :
                                                    <div>
                                                        {this.props.contract.client.address}
                                                    </div>
                                                }
                                            </div>
                                            <div className="h6">{this.props.contract.client.contactPhoneNumber}</div>
                                        </div>
                                    </div>
                                    <div className={isMobileOnly ? "col-6" : ""}>
                                        <div><label className="label-90 mr-2">PC:</label><strong>{this.props.contract.pcCount}</strong></div>
                                        <div><label className="label-90 mr-2">Notebook:</label><strong>{this.props.contract.notebookCount}</strong></div>
                                        <div><label className="label-90 mr-2">Hálózati eszköz:</label><strong>{this.props.contract.networkingDeviceCount}</strong></div>
                                    </div>
                                    <div className={isMobileOnly ? "col-6" : ""}>
                                        <div><label className="label-90 mr-2">Szerver:</label><strong>{this.props.contract.serverCount}</strong></div>
                                        <div><label className="label-90 mr-2">NAS:</label><strong>{this.props.contract.nasCount}</strong></div>
                                        <div><label className="label-90 mr-2">Egyéb eszköz:</label><strong>{this.props.contract.miscellaneousCount}</strong></div>
                                    </div>
                                    {!isMobileOnly &&
                                        <div>
                                            <div className="h5">
                                                <div className="d-flex">Technikus: {this.props.contract.technician.name}</div>
                                            </div>
                                        </div>
                                    }
                                    {!isMobileOnly && <div className="flex-half-width-column"></div>}
                                </div>
                            </div>
                        </CardTitle>

                        {this.props.isExpanded && <React.Fragment>
                            <div className={`border-top lansoft-border-success mt-2 ${isMobileOnly ? "pt-2" : "pt-4"}`}>
                                <div className={`d-flex justify-content-between mt-3 flex-same-width-container border-bottom lansoft-border-success ${isMobileOnly ? "mobile row w-100 align-center-margin" : "pb-5"}`}>
                                    <div className={`form-group mr-3 ${isMobileOnly ? "col-12 no-left-padding" : ""}`}>
                                        <label>Megoldás</label>
                                        <Dropdown isOpen={this.state.ticketSolutionTypeDropdownOpen} toggle={this.toggleticketSolutionTypeDropdown}>
                                            <DropdownToggle className="lansoft-success" caret={!this.props.ticket.isSolved} disabled={this.props.ticket.isSolved}>
                                                {this.state.ticketSolutionTypeId === 0 ? "Válassz" : this.props.ticketSolutionTypes.find(x => x.id === this.state.ticketSolutionTypeId)!!.name}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {this.props.ticketSolutionTypes.map((solutionType, index) =>
                                                    <DropdownItem active={this.state.ticketSolutionTypeId === solutionType.id} onClick={() => this.handleTicketSolutionTypeChange(solutionType.id)} key={`it-ticket-solution-type-dropdown-item-${index}`}>{solutionType.name}</DropdownItem>
                                                )}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                    <div className={`form-group flex-double-width-column ${isMobileOnly ? "col-12 no-left-padding" : ""}`}>
                                        <label>Ügyfél kérése</label>
                                        <div>
                                            <textarea rows={4} className="form-control message-textarea" value={this.props.ticket.messageToTechnician} disabled />
                                        </div>
                                    </div>
                                    <div className={`h5 ${isMobileOnly ? "col-12 no-left-padding" : ""}`}>
                                        <div className="mb-3">Készült: {moment(this.props.ticket.createdAt).format("YYYY.MM.DD HH:mm")}</div>
                                        <div className="form-group">
                                            <label htmlFor={`inputSolvedAtIt-${this.props.ticket.id}`}>Megoldva</label>
                                            <Datetime
                                                closeOnClickOutside={true}
                                                closeOnSelect={true}
                                                dateFormat="YYYY.MM.DD"
                                                timeFormat={false}
                                                utc={true}
                                                value={this.state.solvedAt}
                                                inputProps={{ disabled: this.props.ticket.solvedAt != null }}
                                                onChange={this.handleSolvedAtChange} />
                                        </div>
                                    </div>
                                    <div className="flex-half-width-column"></div>
                                </div>
                                <div className={`d-flex justify-content-between mt-5 flex-same-width-container border-bottom lansoft-border-success ${isMobileOnly ? "mobile row w-100 align-center-margin pt-3 pb-3" : ""}`}>
                                    <div className={`flex-double-width-column ${isMobileOnly ? "col-12 mobile mb-2 no-left-padding" : ""}`}>
                                        <div className="d-flex justify-content-start">
                                            <div className="d-flex flex-column justify-content-center">
                                                <label className="mr-2 mb-0">Célállapot</label>
                                            </div>
                                            <Button color="success" className={`lansoft-success ticket-proposed-state ${this.state.proposedState === ItTicketState.Solved ? "ticket-proposed-state-selected" : ""}`} outline={true} onClick={this.handleSolvedClick} disabled={this.props.ticket.state !== ItTicketState.Open}>KÉSZ</Button>
                                            <Button color="success" className={`lansoft-success ticket-proposed-state ${this.state.proposedState === ItTicketState.Suspended ? "ticket-proposed-state-selected" : ""}`} outline={true} onClick={this.handleSuspendedClick} disabled={this.props.ticket.state !== ItTicketState.Open}>FELFÜGGESZTVE</Button>
                                        </div>
                                    </div>
                                    {!isMobileOnly && <div></div>}
                                    <div className={isMobileOnly ? "col-12 no-left-padding" : ""}>
                                        <div className="form-group">
                                            <label className="mr-2" htmlFor="inputNewTicketMessageToClient">Üzenet az ügyfélnek</label>
                                            <textarea maxLength={250} rows={4} className="form-control message-textarea" id="inputNewTicketMessageToClient" autoComplete="off" placeholder={this.props.ticket.state === ItTicketState.Open ? "Üzenet" : undefined} value={this.state.messageToClient} onChange={this.handleMessageToClientChange} disabled={this.props.ticket.state !== ItTicketState.Open} />
                                            {this.props.ticket.state === ItTicketState.Open &&
                                                <div className="mt-4 d-flex justify-content-end">
                                                    <Button color="primary" className="lansoft-primary" onClick={this.handleSolveClick} disabled={!this.isValid()}>Elküld</Button>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="flex-half-width-column"></div>
                                </div>
                            </div>
                        </React.Fragment>}

                        {this.props.isExpanded && <div className={isMobileOnly ? "mt-2" : "mt-5"}>
                            <div className="h5">Előzmények</div>
                            {this.props.contract.tickets.length === 0 && <div>Nem található elem.</div>}
                            {this.props.contract.tickets.length > 0 &&
                                <React.Fragment>
                                    <table className="table history-table">
                                        <thead>
                                            <tr>
                                                <th scope="col"></th>
                                                <th scope="col">Dátum</th>
                                                {!isMobileOnly && <th scope="col">Ügyfél kérése</th>}
                                                {!isMobileOnly && <th scope="col">Megoldás</th>}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.contract.tickets.map((ticket, index) => {
                                                const messageToTechnician = ticket.messageToTechnician;
                                                const messageToClient = ticket.messageToClient;

                                                return (
                                                    <tr key={`contract-history-row-${index}`} className="cursor-pointer" onClick={() => this.onTicketHistoryItemClick(ticket)}>
                                                        <td>
                                                            <div id={`contract-history-item-indicator-${index}`} className={`contract-history-item-indicator bg-${getIndicatorClassFromHistoryItemType(ticket.isSolved ? HistoryItemType.SolvedTicket : HistoryItemType.OpenTicket)}`}></div>
                                                            <Tooltip placement="top" isOpen={this.state.historyItemIndicatorTooltipOpen === index} target={`contract-history-item-indicator-${index}`} toggle={() => this.toggleHistoryItemIndicatorTooltipOpen(index)}>
                                                                {getTooltipTextFromHistoryItemType(ticket.isSolved ? (ticket.state === ItTicketState.Solved ? HistoryItemType.SolvedTicket : HistoryItemType.SuspendedTicket) : HistoryItemType.OpenTicket)}
                                                            </Tooltip>
                                                        </td>
                                                        <td>{moment(ticket.createdAt).format(isMobileOnly ? "MM.DD" : "YYYY.MM.DD HH:mm")}</td>
                                                        {!isMobileOnly && <td>{messageToTechnician}</td>}
                                                        {!isMobileOnly && <td>{messageToClient}</td>}
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    <div>
                                        <Button color="primary" className="lansoft-primary" onClick={() => this.handleDownloadHistoryExportClick(this.props.contract.id)}>Mentés Excelbe</Button>
                                    </div>
                                </React.Fragment>
                            }
                        </div>}
                    </CardBody>
                </Card>

                <ItSupportTicketModal
                    ticket={this.state.ticketHistoryItem}
                    open={this.state.ticketHistoryItemModalOpen}
                    close={this.closeTicketHistoryItemModal}
                />
            </React.Fragment >

        );
    }

    private handleContractTypeExpandClick = () => {
        this.setState({
            contractTypeExpanded: !this.state.contractTypeExpanded
        });
    }

    private handleSolvedAtChange = (date: any) => {
        this.setState({
            solvedAt: moment.utc(date).startOf('day').toDate()
        });
    }

    private handleDownloadHistoryExportClick = (contractId: number) => {
        window.location.href = `/api/excel/it-contract/${contractId}/history`;
    }

    private toggleMaintenanceIntervalTooltip = () => {
        this.setState({
            maintenanceIntervalTooltipOpen: !this.state.maintenanceIntervalTooltipOpen
        });
    }

    private onTicketHistoryItemClick = (ticket: ItSupportTicket) => {
        this.setState({
            ticketHistoryItem: ticket,
            ticketHistoryItemModalOpen: true
        });
    }

    private closeTicketHistoryItemModal = () => {
        this.setState({
            ticketHistoryItemModalOpen: false
        });
    }

    private toggleHistoryItemIndicatorTooltipOpen = (index: number) => {
        this.setState({
            historyItemIndicatorTooltipOpen: this.state.historyItemIndicatorTooltipOpen === index ? NaN : index
        });
    }

    private toggleMemoTooltip = () => {
        this.setState({
            memoTooltipOpen: !this.state.memoTooltipOpen
        });
    }

    private toggleDeviceCountTooltip = () => {
        this.setState({
            deviceCountTooltipOpen: !this.state.deviceCountTooltipOpen
        });
    }

    private toggleContractTypeTooltip = () => {
        this.setState({
            contractTypeTooltipOpen: !this.state.contractTypeTooltipOpen
        });
    }

    private toggleContractNumberTooltip = () => {
        this.setState({
            contractNumberTooltipOpen: !this.state.contractNumberTooltipOpen
        });
    }

    private toggleTicketIndicatorTooltip = () => {
        this.setState({
            ticketIndicatorTooltipOpen: !this.state.ticketIndicatorTooltipOpen
        });
    }

    private handleSolvedClick = () => {
        this.setState({
            proposedState: ItTicketState.Solved
        });
    }

    private handleSuspendedClick = () => {
        this.setState({
            proposedState: ItTicketState.Suspended
        });
    }

    private handleSolveClick = () => {
        const payload = {
            ticketSolutionTypeId: this.state.ticketSolutionTypeId,
            proposedState: this.state.proposedState!!,
            messageToClient: this.state.messageToClient,
            solvedAt: this.state.solvedAt
        };

        this.props.handleSolveClick(payload);
    }

    private handleMessageToClientChange = (e: any) => {
        this.setState({
            messageToClient: e.target.value
        });
    }

    private handleTicketSolutionTypeChange = (id: number) => {
        this.setState({
            ticketSolutionTypeId: id
        });
    }

    private toggleticketSolutionTypeDropdown = () => {
        this.setState({
            ticketSolutionTypeDropdownOpen: !this.state.ticketSolutionTypeDropdownOpen
        });
    }

    private handleExpandClick = () => {
        this.props.expandCollapse();
    }

    private isValid = () => {
        return this.validate();
    }

    private validate = (shouldSetState: boolean = false) => {
        const validationErrors: string[] = [];

        if (this.state.ticketSolutionTypeId === 0) {
            validationErrors.push("A megoldás kiválasztása kötelező.");
        }

        if (this.state.proposedState == null) {
            validationErrors.push("A célállapot kiválasztása kötelező.");
        }

        if (shouldSetState) {
            this.setState({
                validationErrors: validationErrors
            });
        }

        return validationErrors.length === 0;
    }
}

export default ItSupportTicketCard;

import * as React from 'react';
import { Technician } from "../models";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

interface TechnicianDetailsModalProps {
    open: boolean;
    technician: Technician | null;
    close: () => void;
}

export const TechnicianDetailsModal = (props: TechnicianDetailsModalProps) => (
    <Modal isOpen={props.open} toggle={props.close} className="modal-dialog-centered">
        <ModalHeader className="lansoft-success" toggle={props.close}>Technikus részletei</ModalHeader>
        <ModalBody>
            {props.technician != null && <React.Fragment>
                <div>
                    <label className="h6">Név</label>
                    <div>{props.technician.name}</div>
                </div>
                <div className="mt-3">
                    <label className="h6">E-mail cím</label>
                    <div>{props.technician.email}</div>
                </div>
                <div className="mt-3">
                    <label className="h6">Az összes technikushoz tartozó elem listázásának alapértelmezett értéke</label>
                    <div>{props.technician.displayItemsForAllTechnicians ? "Bekapcsolva" : "Kikapcsolva"}</div>
                </div>
            </React.Fragment>}
        </ModalBody>
    </Modal>
);

export default TechnicianDetailsModal;

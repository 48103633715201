import * as React from 'react';
import { Dropdown, DropdownProps } from 'reactstrap';
import { isMobileOnly } from "react-device-detect";

interface HoverDropdownState {
    isOpen: boolean;
}

class HoverDropdown extends React.PureComponent<DropdownProps, HoverDropdownState> {
    constructor(props: DropdownProps) {
        super(props);

        this.state = {
            isOpen: false
        }
    }

    public render() {
        return (
            <Dropdown
                {...this.props}
                onClick={() => this.updateIsOpen(!this.state.isOpen)}
                onMouseEnter={() => !isMobileOnly && this.updateIsOpen(true)}
                onFocus={() => !isMobileOnly && this.updateIsOpen(true)}
                onMouseLeave={() => !isMobileOnly && this.updateIsOpen(false)}
                onBlur={() => !isMobileOnly && this.updateIsOpen(false)}
                toggle={() => !isMobileOnly && this.updateIsOpen(!this.state.isOpen)}
                isOpen={this.state.isOpen}
            />
        );
    }

    private updateIsOpen = (isOpen: boolean) => {
        this.setState({
            isOpen: isOpen
        });
    }
}

export default HoverDropdown;

import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as LanSoftStore from '../store/LanSoftStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingIndicator, ToastContainer } from "./common";
import { CopierCounterStatementCreatePayload } from "../payloads";
import { getActiveCopierTicketCount, getActiveItTicketCount } from "../utils";
import CopierContractCard from "./CopierContractCard";
import ItContractCard from "./ItContractCard";
import { isMobileOnly } from 'react-device-detect';
import { CopierContract, ItContract } from '../models';
import moment from 'moment';

type ClientDashboardPropsType = LanSoftStore.LanSoftState
    & typeof LanSoftStore.actionCreators
    & RouteComponentProps<{}>;

interface ClientDashboardState {
    result: boolean | null;
    toastOpen: boolean;
    validationErrors: string[];
    copierContractExpandedIndex: number;
    itContractExpandedIndex: number;
    search: string;
    filteredCopierContracts: CopierContract[];
    filteredItContracts: ItContract[];
    showClosed: boolean;
}

class ClientDashboard extends React.PureComponent<ClientDashboardPropsType, ClientDashboardState> {
    constructor(props: ClientDashboardPropsType) {
        super(props);

        this.state = {
            result: null,
            toastOpen: false,
            validationErrors: [],
            copierContractExpandedIndex: NaN,
            itContractExpandedIndex: NaN,
            search: "",
            filteredCopierContracts: [],
            filteredItContracts: [],
            showClosed: false
        }
    }

    componentDidMount() {
        this.props.getMyCopierContracts();
        this.props.getMyItContracts();
        this.props.getAllClients();
        this.props.getAllCopierTicketTypes();
        this.props.getAllItTicketTypes();
    }

    UNSAFE_componentWillReceiveProps(nextProps: ClientDashboardPropsType) {
        if (nextProps.result !== this.props.result && nextProps.result != null) {
            this.setState({
                result: nextProps.result,
                toastOpen: true
            });

            window.setTimeout(() => {
                this.setState({
                    toastOpen: false
                }, () => {
                    this.props.clearResult();
                });
            }, 3000);
        }

        if (nextProps.copierContracts.length !== this.props.copierContracts.length) {
            this.setState({
                filteredCopierContracts: this.filterCopierContracts(nextProps.copierContracts, this.state.search, this.state.showClosed)
            });
        }

        if (nextProps.itContracts.length !== this.props.itContracts.length) {
            this.setState({
                filteredItContracts: this.filterItContracts(nextProps.itContracts, this.state.search, this.state.showClosed)
            });
        }
    }

    public render() {
        const client = this.props.loggedInUser == null ? null : this.props.clients.find(x => x.users.filter(y => y.id === this.props.loggedInUser!!.id).length > 0);

        return (
            <React.Fragment>
                <LoadingIndicator show={this.props.loading > 0} />
                <div className="d-flex justify-content-between my-4">
                    <div>
                        {client != null && <div>
                            <div className="h2">{client.name}</div>
                            <div className="h3">{client.address}</div>
                            <div className="h3">{client.contactPhoneNumber}</div>
                        </div>}</div>
                    <div className="p-4 border lansoft-border-success d-flex align-items-center">
                        <div>Aktív hibajegyek: {getActiveCopierTicketCount(this.props.copierContracts) + getActiveItTicketCount(this.props.itContracts)}</div>
                    </div>
                </div>

                <h4 className="mb-4">
                    <div className="d-flex align-items-center">
                        <FontAwesomeIcon icon={["fas", "file-contract"]} className="mr-2" />
                        <div className="h5 lansoft-underline-success pb-1 mb-0 width-fit-content">
                            Szerződéseim ({this.props.copierContracts.length + this.props.itContracts.length})
                        </div>
                    </div>
                </h4>

                <div className={`d-flex justify-content-between ${isMobileOnly ? "row" : ""}`}>
                    <div className={`form-group form-inline ${isMobileOnly ? "col-12" : ""}`}>
                        <input type="text" className="form-control" id="inputSearch" autoComplete="off" placeholder="Keresés" value={this.state.search} onChange={this.handleSearchChange} />
                    </div>
                    <div className="form-check mt-3 mb-3 checkbox-emulate-container">
                        <input type="checkbox" className="form-check-input" id="inputShowClosed" checked={this.state.showClosed} onChange={this.handleShowClosedChange} />
                        <label className="form-check-label" htmlFor="inputShowClosed">Lezárt szerződések mutatása</label>
                    </div>
                </div>

                <div className="tabbable full-width-tabs my-contracts-tabs">
                    <ul className="nav nav-tabs mb-3 d-flex justify-content-start align-items-center" id="contracts-tab-list" role="tablist">
                        <li className="d-flex">
                            <a className="nav-link active" id="copier-contracts-tab" data-toggle="pill" href="#copier-contracts" role="tab" aria-controls="copier" aria-selected="true">Másolástechnikai ({this.state.filteredCopierContracts.length})</a>
                        </li>
                        <li className="d-flex">
                            <a className="nav-link" id="it-contracts-tab" data-toggle="pill" href="#it-contracts" role="tab" aria-controls="it" aria-selected="false">Informatikai ({this.state.filteredItContracts.length})</a>
                        </li>
                    </ul>
                </div>

                <div className="tab-content" id="my-contracts-tab-content">
                    <div className="tab-pane fade show active" id="copier-contracts" role="tabpanel" aria-labelledby="copier-contracts-tab">
                        {this.state.filteredCopierContracts.length === 0 &&
                            <div className="d-flex justify-content-center text-white font-italic mt-4">
                                <span>Nem található szerződés.</span>
                            </div>
                        }

                        <ul className="list-group mt-3">
                            {this.state.filteredCopierContracts.map((contract, index) =>
                                <li className="list-group-item-container" key={`copier-contract-list-item-${index}`}>
                                    <div className="list-group-item list-margin diagonal-background diagonal-background-lansoft-primary">
                                        <CopierContractCard
                                            contract={contract}
                                            createStatement={payload => this.createStatement(contract.id, payload)}
                                            createSupportTicket={payload => this.props.createCopierSupportTicket(contract.id, payload)}
                                            expandCollapse={() => this.handleCopierCardExpandCollapseClick(index)}
                                            isExpanded={this.state.copierContractExpandedIndex === index}
                                            ticketTypes={this.props.copierTicketTypes}
                                            editEnabled={contract.endDate == null || moment(contract.endDate).isSameOrAfter(moment())}
                                        />
                                    </div>
                                </li>
                            )}
                        </ul>
                    </div>
                    <div className="tab-pane fade" id="it-contracts" role="tabpanel" aria-labelledby="it-contracts-tab">
                        {this.state.filteredItContracts.length === 0 &&
                            <div className="d-flex justify-content-center text-white font-italic mt-4">
                                <span>Nem található szerződés.</span>
                            </div>
                        }

                        <ul className="list-group mt-3">
                            {this.state.filteredItContracts.map((contract, index) =>
                                <li className="list-group-item-container" key={`it-contract-list-item-${index}`}>
                                    <div className="list-group-item list-margin diagonal-background diagonal-background-lansoft-primary">
                                        <ItContractCard
                                            contract={contract}
                                            createSupportTicket={payload => this.props.createItSupportTicket(contract.id, payload)}
                                            expandCollapse={() => this.handleItCardExpandCollapseClick(index)}
                                            isExpanded={this.state.itContractExpandedIndex === index}
                                            ticketTypes={this.props.itTicketTypes}
                                            editEnabled={contract.endDate == null || moment(contract.endDate).isSameOrAfter(moment())}
                                        />
                                    </div>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>

                <ToastContainer open={this.state.toastOpen} color={this.state.result ? "success" : "danger"} success={this.state.result === true} />
            </React.Fragment>

        );
    }

    private handleShowClosedChange = (e: any) => {
        this.setState({
            showClosed: e.target.checked,
            filteredCopierContracts: this.filterCopierContracts(this.props.copierContracts, this.state.search, e.target.checked),
            filteredItContracts: this.filterItContracts(this.props.itContracts, this.state.search, e.target.checked)
        });
    }

    private handleCopierCardExpandCollapseClick = (index: number) => {
        this.setState({
            copierContractExpandedIndex: this.state.copierContractExpandedIndex === index ? NaN : index
        });
    }

    private handleItCardExpandCollapseClick = (index: number) => {
        this.setState({
            itContractExpandedIndex: this.state.itContractExpandedIndex === index ? NaN : index
        });
    }

    private createStatement = (contractId: number, payload: CopierCounterStatementCreatePayload) => {
        this.props.createCopierCounterStatement(contractId, payload);
    }

    private handleSearchChange = (e: any) => {
        const searchString = e.target.value;
        this.setState({
            search: searchString,
            filteredCopierContracts: this.filterCopierContracts(this.props.copierContracts, searchString, this.state.showClosed),
            filteredItContracts: this.filterItContracts(this.props.itContracts, searchString, this.state.showClosed)
        });
    }

    private filterCopierContracts = (contracts: CopierContract[], searchString: string, showClosed: boolean): CopierContract[] => {
        return contracts
            .filter(x => {
                return (
                    ((x.client.name.toLowerCase().indexOf(searchString.toLowerCase()) !== -1 ||
                        x.contractNumber.toLowerCase().indexOf(searchString.toLowerCase()) !== -1 ||
                        x.device.serialNumber.toLowerCase().indexOf(searchString.toLowerCase()) !== -1 ||
                        x.device.model.name.toLowerCase().indexOf(searchString.toLowerCase()) !== -1 ||
                        (x.memo != null && x.memo.toLowerCase().indexOf(searchString.toLowerCase()) !== -1)))
                    && (x.endDate == null || moment(x.endDate).isSameOrAfter(moment()) || showClosed)
                );
            });
    }

    private filterItContracts = (contracts: ItContract[], searchString: string, showClosed: boolean): ItContract[] => {
        return contracts
            .filter(x => {
                return (
                    ((x.client.name.toLowerCase().indexOf(searchString.toLowerCase()) !== -1 ||
                        x.contractNumber.toLowerCase().indexOf(searchString.toLowerCase()) !== -1 ||
                        (x.memo != null && x.memo.toLowerCase().indexOf(searchString.toLowerCase()) !== -1)))
                    && (x.endDate == null || moment(x.endDate).isSameOrAfter(moment()) || showClosed)
                );
            });
    }
}

export default connect(
    (state: ApplicationState) => state.lanSoft,
    LanSoftStore.actionCreators
)(ClientDashboard as any);

import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, CardBody, CardTitle, Dropdown, DropdownToggle, Tooltip } from 'reactstrap';
import { CopierScheduledTicketSolvePayload } from "../payloads";
import { CopierContract, CopierContractType, CopierCounterStatement, CopierSupportTicket, CopierTicketKind, CopierTicketState, HistoryItemType } from "../models";
import { getFriendlyNameFromCopierContractType, getIndicatorClassFromHistoryItemType, getIndicatorClassFromTicket, getTooltipTextFromHistoryItemType } from "../utils/EnumUtils";
import moment from 'moment';
import { getNumberInputValue, MAX_DEVICE_NAME_LENGTH_DISPLAY } from '../utils';
import { isMobileOnly } from "react-device-detect";
import Datetime from 'react-datetime';
import CopierSupportTicketModal from './CopierSupportTicketModal';

interface ScheduledCopierTicketCardPropsType {
    isExpanded: boolean;
    ticket: CopierSupportTicket;
    contract: CopierContract;
    expandCollapse: () => void;
    handleSolveClick: (payload: CopierScheduledTicketSolvePayload) => void;
    tickets: CopierSupportTicket[];
    statements: CopierCounterStatement[];
    contracts: CopierContract[];
}

interface ScheduledCopierTicketCardState {
    blackAndWhiteCounter: number;
    colorCounter: number | null;
    signedByClient: boolean;
    solvedAt: Date;
    note: string;
    validationErrors: string[];
    ticketIndicatorTooltipOpen: boolean;
    contractNumberTooltipOpen: boolean;
    contractTypeTooltipOpen: boolean;
    deviceModelTooltipOpen: boolean;
    deviceSerialNumberTooltipOpen: boolean;
    memoTooltipOpen: boolean;
    historyItemIndicatorTooltipOpen: number;
    ticketHistoryItem: CopierSupportTicket | null;
    ticketHistoryItemContractNumber: string;
    ticketHistoryItemModalOpen: boolean;
    copierDeviceModelExpanded: boolean;
    historyItemContractNumberTooltipOpenIndex: number;
}

export class ScheduledCopierTicketCard extends React.PureComponent<ScheduledCopierTicketCardPropsType, ScheduledCopierTicketCardState> {
    constructor(props: ScheduledCopierTicketCardPropsType) {
        super(props);

        this.state = {
            blackAndWhiteCounter: 0,
            colorCounter: null,
            signedByClient: false,
            solvedAt: props.ticket.solvedAt != null ? new Date(props.ticket.solvedAt) : new Date(),
            note: "",
            validationErrors: [],
            ticketIndicatorTooltipOpen: false,
            contractNumberTooltipOpen: false,
            contractTypeTooltipOpen: false,
            deviceModelTooltipOpen: false,
            deviceSerialNumberTooltipOpen: false,
            memoTooltipOpen: false,
            historyItemIndicatorTooltipOpen: NaN,
            ticketHistoryItem: null,
            ticketHistoryItemContractNumber: "",
            ticketHistoryItemModalOpen: false,
            copierDeviceModelExpanded: false,
            historyItemContractNumberTooltipOpenIndex: NaN
        }
    }

    public render() {
        const tickets = this.props.contract.tickets.filter(x => x.kind !== CopierTicketKind.Scheduled || x.isSolved).map(ticket => {
            return {
                date: new Date(ticket.createdAt),
                ticket: ticket,
                statement: null
            };
        });

        const statements = this.props.contract.statements.map(statement => {
            return {
                date: new Date(statement.createdAt),
                ticket: null,
                statement: statement
            }
        });

        let history: { date: Date, ticket: CopierSupportTicket | null, statement: CopierCounterStatement | null }[] = [];
        history = history.concat(tickets).concat(statements);
        history.sort((a, b) => b.date.valueOf() - a.date.valueOf());

        const isSolved = this.props.ticket.isSolved;

        const deviceNameLong = this.props.contract.device.model.name.length > MAX_DEVICE_NAME_LENGTH_DISPLAY;

        const addressSecondSpaceIndex = this.props.contract.client.address.indexOf(" ", this.props.contract.client.address.indexOf(" ") + 1);
        const firstAddressChunk = addressSecondSpaceIndex >= 0 ? this.props.contract.client.address.substr(0, addressSecondSpaceIndex).trim() : this.props.contract.client.address.substr(addressSecondSpaceIndex + 1).trim();
        let secondAddressChunk = "";
        if (addressSecondSpaceIndex >= 0) {
            secondAddressChunk = this.props.contract.client.address.substr(addressSecondSpaceIndex + 1).trim();
        }

        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <CardTitle className="mb-0">
                            {isMobileOnly &&
                                <div className="h4 d-flex justify-content-between pb-1 mobile row border-bottom lansoft-border-success lansoft-border-full">
                                    <div />
                                    <div className="d-flex align-items-center mb-3">
                                        <div id={`scheduled-scheduled-ticket-indicator-${this.props.ticket.id}`} className={`mr-1 ticket-indicator bg-${getIndicatorClassFromTicket(this.props.ticket)}`}></div>
                                        <Tooltip placement="top" isOpen={this.state.ticketIndicatorTooltipOpen} target={`scheduled-scheduled-ticket-indicator-${this.props.ticket.id}`} toggle={this.toggleTicketIndicatorTooltip}>
                                            {getTooltipTextFromHistoryItemType(this.props.ticket.isSolved ? (this.props.ticket.state === CopierTicketState.Solved ? HistoryItemType.SolvedTicket : HistoryItemType.SuspendedTicket) : HistoryItemType.OpenTicket)}
                                        </Tooltip>
                                        <span id={`scheduled-contract-number-${this.props.ticket.id}`}>{this.props.contract.contractNumber}</span>
                                        <Tooltip placement="top" isOpen={this.state.contractNumberTooltipOpen} target={`scheduled-contract-number-${this.props.ticket.id}`} toggle={this.toggleContractNumberTooltip}>
                                            Szerződésszám
                                        </Tooltip>
                                    </div>
                                    <div className="cursor-pointer mr-3" onClick={() => this.handleExpandClick()}><FontAwesomeIcon icon={["fas", this.props.isExpanded ? "chevron-up" : "chevron-down"]} className="mr-1" /></div>
                                </div>
                            }
                            <div className={`h4 d-flex justify-content-between flex-same-width-container ${isMobileOnly ? "mobile row pt-3" : "mt-4 pb-5"}`}>
                                {!isMobileOnly && <div className={isMobileOnly ? "col-6" : ""}>
                                    <div className={`d-flex align-items-center ${isMobileOnly ? "mb-3" : ""}`}>
                                        <div id={`scheduled-scheduled-ticket-indicator-${this.props.ticket.id}`} className={`mr-1 ticket-indicator bg-${getIndicatorClassFromTicket(this.props.ticket)}`}></div>
                                        <Tooltip placement="top" isOpen={this.state.ticketIndicatorTooltipOpen} target={`scheduled-scheduled-ticket-indicator-${this.props.ticket.id}`} toggle={this.toggleTicketIndicatorTooltip}>
                                            {getTooltipTextFromHistoryItemType(this.props.ticket.isSolved ? (this.props.ticket.state === CopierTicketState.Solved ? HistoryItemType.SolvedTicket : HistoryItemType.SuspendedTicket) : HistoryItemType.OpenTicket)}
                                        </Tooltip>
                                        <span id={`scheduled-contract-number-${this.props.ticket.id}`}>{this.props.contract.contractNumber}</span>
                                        <Tooltip placement="top" isOpen={this.state.contractNumberTooltipOpen} target={`scheduled-contract-number-${this.props.ticket.id}`} toggle={this.toggleContractNumberTooltip}>
                                            Szerződésszám
                                        </Tooltip>
                                    </div>
                                </div>}
                                <div className={isMobileOnly ? "col-6 mb-3 d-flex" : ""}>
                                    <div className={isMobileOnly ? "d-flex justify-content-end" : ""}>
                                        <div id={`scheduled-contract-type-${this.props.ticket.id}`} className="d-inline-block">
                                            {getFriendlyNameFromCopierContractType(this.props.contract.contractType)}{this.props.contract.contractType === CopierContractType.Monthly && `/${this.props.contract.maintenanceIntervalMonths}`}
                                        </div>
                                        <Tooltip placement="top" isOpen={this.state.contractTypeTooltipOpen} target={`scheduled-contract-type-${this.props.ticket.id}`} toggle={this.toggleContractTypeTooltip}>
                                            Szerződés típusa
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className={isMobileOnly ? "col-6 mb-3 d-flex" : ""}>
                                    <div onClick={this.handleCopierDeviceExpandClick} className="cursor-pointer">
                                        <div id={`scheduled-device-model-${this.props.ticket.id}`} className="d-inline-block">
                                            {!deviceNameLong && <React.Fragment>{this.props.contract.device.model.name}</React.Fragment>}
                                            {deviceNameLong && <React.Fragment>
                                                {!this.state.copierDeviceModelExpanded && <React.Fragment>
                                                    {this.props.contract.device.model.name.substring(0, MAX_DEVICE_NAME_LENGTH_DISPLAY - 2) + "..."}
                                                </React.Fragment>}
                                                {this.state.copierDeviceModelExpanded && <React.Fragment>
                                                    {this.props.contract.device.model.name.split(" ").map((fragment, index) =>
                                                        <span className="nowrap d-block" key={`device-model-name-fragment-${index}`}>{fragment}</span>)
                                                    }
                                                </React.Fragment>}
                                            </React.Fragment>}
                                        </div>
                                        <Tooltip placement="top" isOpen={this.state.deviceModelTooltipOpen} target={`scheduled-device-model-${this.props.ticket.id}`} toggle={this.toggleDeviceModelTooltip}>
                                            Gép típusa
                                    </Tooltip>
                                    </div>
                                </div>
                                <div className={isMobileOnly ? "col-6 mb-3 d-flex" : ""}>
                                    <div className={isMobileOnly ? "text-right" : ""}>
                                        <div id={`scheduled-device-serial-number-${this.props.ticket.id}`} className="d-inline-block">
                                            {this.props.contract.device.serialNumber}
                                        </div>
                                        <Tooltip placement="top" isOpen={this.state.deviceSerialNumberTooltipOpen} target={`scheduled-device-serial-number-${this.props.ticket.id}`} toggle={this.toggleDeviceSerialNumberTooltip}>
                                            Gép gyári száma
                                    </Tooltip>
                                    </div>
                                </div>
                                <div className={`flex-half-width-column ${isMobileOnly ? "mobile col" : ""}`}>
                                    <div className={`d-flex ${!isMobileOnly ? "justify-content-end" : ""}`}>
                                        <div id={`scheduled-memo-${this.props.ticket.id}`}>
                                            {this.props.contract.memo}
                                        </div>
                                        <Tooltip placement="top" isOpen={this.state.memoTooltipOpen} target={`scheduled-memo-${this.props.ticket.id}`} toggle={this.toggleMemoTooltip}>
                                            Memo
                                        </Tooltip>
                                        {!isMobileOnly && <div className="cursor-pointer ml-3" onClick={() => this.handleExpandClick()}><FontAwesomeIcon icon={["fas", this.props.isExpanded ? "chevron-up" : "chevron-down"]} className="mr-1" /></div>}
                                    </div>
                                </div>
                            </div>

                            <div className="mt-1 pt-1 border-top lansoft-border-success"></div>

                            {isMobileOnly && <div className="col-12 mt-2 text-center">
                                <div className="h5 no-bottom-margin">
                                    Technikus: {this.props.contract.technician.name}
                                </div>
                            </div>}

                            <div className={`d-flex justify-content-between flex-same-width-container ${!isMobileOnly ? "pt-5" : "mobile row w-100 align-center-margin"}`}>
                                {isMobileOnly
                                    ? <React.Fragment>
                                        {!isMobileOnly && <div className="col-6 h5 mb-5">Technikus:<br />{this.props.contract.technician.name}</div>}
                                        <div className={isMobileOnly ? "col-12 mb-4 no-left-padding" : "flex-half-width-column"}>
                                            <div>
                                                <div className="h5 mb-3"><strong>{this.props.contract.client.name}</strong></div>
                                                <div className="h6 mb-3">
                                                    {addressSecondSpaceIndex >= 0 ?
                                                        <div>
                                                            <div>{firstAddressChunk}</div>
                                                            <div>{secondAddressChunk}</div>
                                                        </div>
                                                        :
                                                        <div>
                                                            {this.props.contract.client.address}
                                                        </div>
                                                    }
                                                </div>
                                                <div className="h6">{this.props.contract.client.contactPhoneNumber}</div>
                                            </div>
                                        </div>
                                        <div className="col-6 mb-5 no-left-padding">
                                            <div>
                                                <Button className={isSolved ? "lansoft-success" : "lansoft-danger"} disabled={isSolved} onClick={() => this.handleExpandClick(true)}>
                                                    {isSolved ? moment(this.props.ticket.solvedAt).format("YYYY.MM.DD") : "Megoldás"}
                                                </Button>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="d-flex justify-content-start">
                                                <div>Bérleti díj:<br /> <strong>{this.props.contract.monthlyLeasePrice} Ft + ÁFA</strong></div>
                                            </div>
                                            <div>Fekete nyomatdíj:<br /> <strong>{this.props.contract.blackAndWhitePrintPrice} Ft + ÁFA</strong></div>
                                            {this.props.contract.device.model.color && <div>Színes nyomatdíj:<br /> <strong>{this.props.contract.colorPrintPrice} Ft + ÁFA</strong></div>}
                                            <div>Bérleti díjban foglalt nyomat:<br /> <strong>{this.props.contract.includedBlackAndWhitePrints}</strong></div>
                                        </div>
                                    </React.Fragment>
                                    : <React.Fragment>
                                        <div>
                                            <div className="h5 mb-3"><strong>{this.props.contract.client.name}</strong></div>
                                            <div className="h5 mb-3">
                                                {addressSecondSpaceIndex >= 0 ?
                                                    <div>
                                                        <div>{firstAddressChunk}</div>
                                                        <div>{secondAddressChunk}</div>
                                                    </div>
                                                    :
                                                    <div>
                                                        {this.props.contract.client.address}
                                                    </div>
                                                }
                                            </div>
                                            <div className="h5">{this.props.contract.client.contactPhoneNumber}</div>
                                        </div>
                                        <div>
                                            <div className="d-flex justify-content-start">
                                                <div>Bérleti díj: {this.props.contract.monthlyLeasePrice} Ft + ÁFA</div>
                                            </div>
                                            <div>Fekete nyomatdíj: {this.props.contract.blackAndWhitePrintPrice} Ft + ÁFA</div>
                                            {this.props.contract.device.model.color && <div>Színes nyomatdíj: {this.props.contract.colorPrintPrice} Ft + ÁFA</div>}
                                        </div>
                                        <div>
                                            <div>Bérleti díjban foglalt nyomat: {this.props.contract.includedBlackAndWhitePrints}</div>
                                        </div>
                                        <div className="h5">Technikus: {this.props.contract.technician.name}</div>
                                        <div className="flex-half-width-column align-items-end">
                                            <div>
                                                <Button className={isSolved ? "lansoft-success" : "lansoft-danger"} disabled={isSolved} onClick={() => this.handleExpandClick(true)}>
                                                    {isSolved ? moment(this.props.ticket.solvedAt).format("YYYY.MM.DD") : "Megoldás"}
                                                </Button>
                                            </div>
                                        </div>
                                    </React.Fragment>}
                            </div>
                        </CardTitle>

                        {this.props.isExpanded && <div className={`border-top lansoft-border-success ${isMobileOnly ? "mt-2 pt-2 pb-3" : "mt-3 pt-2 pb-2"}`}>
                            <div className={`d-flex justify-content-between mt-3 ${isMobileOnly ? "row" : "flex-same-width-container"}`}>
                                <div className={`form-group ${isMobileOnly ? "col-6" : ""}`}>
                                    <label>Megoldás</label>
                                    <Dropdown disabled>
                                        <DropdownToggle disabled>
                                            Szerződés szerinti karbantartás
                                        </DropdownToggle>
                                    </Dropdown>
                                    <div className={`form-group mt-2 ${this.props.ticket.solvedAt != null ? "invisible" : ""}`}>
                                        <label htmlFor={`inputSolvedAt-${this.props.ticket.id}`}>Megoldva</label>
                                        <Datetime
                                            closeOnClickOutside={true}
                                            closeOnSelect={true}
                                            dateFormat="YYYY.MM.DD"
                                            timeFormat={false}
                                            utc={true}
                                            value={this.state.solvedAt}
                                            inputProps={{ disabled: this.props.ticket.solvedAt != null }}
                                            onChange={this.handleSolvedAtChange} />
                                    </div>
                                </div>
                                <div className={`flex-double-width-column ${isMobileOnly ? "col-6" : ""}`}>
                                    <div className={!isMobileOnly ? "d-flex justify-content-center align-items-center" : ""}>
                                        <div className="form-group">
                                            <label htmlFor="inputNewStatementBlackAndWhiteCounter">Fekete számláló</label>
                                            <input type="text" className="form-control numeric-input" id="inputNewStatementBlackAndWhiteCounter" autoComplete="off" placeholder="Fekete számláló" value={getNumberInputValue(this.state.blackAndWhiteCounter)} onChange={this.handleBlackAndWhiteCounterChange} />
                                        </div>
                                        <div className={`form-group ${this.props.contract.device.model.color ? "" : "invisible"} ${isMobileOnly ? "" : "ml-5 pr-5"}`}>
                                            <label htmlFor="inputNewStatementColorCounter">Színes számláló</label>
                                            <input type="text" className="form-control numeric-input" id="inputNewStatementColorCounter" autoComplete="off" placeholder="Színes számláló" value={getNumberInputValue(this.state.colorCounter || 0)} onChange={this.handleColorCounterChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className={isMobileOnly ? "col-6" : ""}>
                                    <div className="form-group">
                                        <label htmlFor="inputNote">Munkalapon megjegyzés</label>
                                        <textarea maxLength={250} rows={4} className="form-control message-textarea border" id="inputNote" autoComplete="off" placeholder="Megjegyzés" value={this.state.note} onChange={this.handleNoteChange} />
                                    </div>
                                    <div className="form-check mt-3">
                                        <input type="checkbox" className="form-check-input" id="inputSignedByClient" checked={this.state.signedByClient} onChange={this.handleSignedByClientChange} />
                                        <label className="form-check-label" htmlFor="inputSignedByClient">Ügyfél által aláírva</label>
                                    </div>
                                    <div className={`mt-4 ${isMobileOnly ? "" : "d-flex justify-content-end"}`}>
                                        <Button color="primary" className="lansoft-primary" onClick={this.handleSolveClick} disabled={!this.isValid()}>Elküld</Button>
                                    </div>
                                </div>
                                <div className="flex-half-width-column"></div>
                            </div>
                        </div>}

                        {this.props.isExpanded && <div className={`border-top lansoft-border-success ${isMobileOnly ? "pt-2" : "pt-3"}`}>
                            <div className="h5">Előzmények</div>
                            {history.length === 0 && <div>Nem található elem.</div>}
                            {history.length > 0 &&
                                <React.Fragment>
                                    <table className="table history-table">
                                        <thead>
                                            <tr>
                                                <th scope="col"></th>
                                                <th scope="col">Dátum</th>
                                                <th scope="col">Fekete sz.</th>
                                                {this.props.contract.device.model.color && <th scope="col">Színes sz.</th>}
                                                {!isMobileOnly && <th scope="col">Ügyfél kérése</th>}
                                                {!isMobileOnly && <th scope="col">Megoldás</th>}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {history.map((historyItem, index) => {
                                                const blackCounter = historyItem.statement == null ? historyItem.ticket!!.blackAndWhiteCounterValue : historyItem.statement.blackAndWhiteCounterValue;
                                                const colorCounter = historyItem.statement == null ? historyItem.ticket!!.colorCounterValue : historyItem.statement.colorCounterValue;
                                                const messageToTechnician = historyItem.ticket != null ? historyItem.ticket!!.messageToTechnician : "Számláló";
                                                const messageToClient = historyItem.ticket != null ? historyItem.ticket!!.messageToClient : "";

                                                const historyItemContract = this.props.contracts.find(x => x.id === (historyItem.statement == null ? historyItem.ticket!!.contractId : historyItem.statement.contractId));
                                                if (historyItemContract == null) {
                                                    return null;
                                                }

                                                const contractNumberTooltipTargetId = `copier-scheduled-ticket-card-contract-number-icon-${index}`;

                                                return (
                                                    <tr key={`contract-history-row-${index}`} className={historyItem.ticket == null ? "" : "cursor-pointer"} onClick={() => { if (historyItem.ticket != null) this.onTicketHistoryItemClick(historyItem.ticket); }}>
                                                        <td>
                                                            <div id={`scheduled-contract-history-item-indicator-${index}`} className={`contract-history-item-indicator bg-${getIndicatorClassFromHistoryItemType(historyItem.statement != null ? HistoryItemType.CounterStatement : (historyItem.ticket!!.isSolved ? (historyItem.ticket!!.state === CopierTicketState.Solved ? HistoryItemType.SolvedTicket : HistoryItemType.SuspendedTicket) : HistoryItemType.OpenTicket))}`}></div>
                                                            <Tooltip placement="top" isOpen={this.state.historyItemIndicatorTooltipOpen === index} target={`scheduled-contract-history-item-indicator-${index}`} toggle={() => this.toggleHistoryItemIndicatorTooltipOpen(index)}>
                                                                {getTooltipTextFromHistoryItemType(historyItem.ticket == null ? HistoryItemType.CounterStatement : (historyItem.ticket.isSolved ? (historyItem.ticket.state === CopierTicketState.Solved ? HistoryItemType.SolvedTicket : HistoryItemType.SuspendedTicket) : HistoryItemType.OpenTicket))}
                                                            </Tooltip>
                                                        </td>
                                                        <td>{moment(historyItem.date).format(isMobileOnly ? "MM.DD" : "YYYY.MM.DD HH:mm")}</td>
                                                        <td>{blackCounter}</td>
                                                        {this.props.contract.device.model.color && <td>{colorCounter}</td>}
                                                        {!isMobileOnly && <td>{messageToTechnician}</td>}
                                                        {!isMobileOnly && <td>{messageToClient}</td>}
                                                        {!isMobileOnly && <td className="history-table-contract-icon-column">
                                                            <div className="cursor-pointer d-flex align-items-center align-content-center justify-content-center" id={contractNumberTooltipTargetId}>
                                                                <FontAwesomeIcon icon={["fas", "file-contract"]} />
                                                            </div>
                                                            <Tooltip placement="left" isOpen={this.state.historyItemContractNumberTooltipOpenIndex === index} target={contractNumberTooltipTargetId} toggle={() => this.toggleStatementContractNumberTooltipIndex(index)}>
                                                                Szerződésszám: {historyItemContract.contractNumber}
                                                            </Tooltip>
                                                        </td>}
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    <div>
                                        <Button color="primary" className="lansoft-primary" onClick={() => this.handleDownloadHistoryExportClick(this.props.contract.id)}>Mentés Excelbe</Button>
                                    </div>
                                </React.Fragment>
                            }
                        </div>}
                    </CardBody>
                </Card>

                <CopierSupportTicketModal
                    ticket={this.state.ticketHistoryItem}
                    contractNumber={this.state.ticketHistoryItemContractNumber}
                    open={this.state.ticketHistoryItemModalOpen}
                    close={this.closeTicketHistoryItemModal}
                    color={this.props.contract.device.model.color}
                />
            </React.Fragment>

        );
    }

    private toggleStatementContractNumberTooltipIndex = (index: number) => {
        this.setState({
            historyItemContractNumberTooltipOpenIndex: index === this.state.historyItemContractNumberTooltipOpenIndex ? NaN : index
        });
    }

    private onTicketHistoryItemClick = (ticket: CopierSupportTicket) => {
        const contract = this.props.contracts.find(x => x.id === ticket.contractId);
        this.setState({
            ticketHistoryItem: ticket,
            ticketHistoryItemContractNumber: contract == null ? "" : contract.contractNumber,
            ticketHistoryItemModalOpen: true
        });
    }

    private closeTicketHistoryItemModal = () => {
        this.setState({
            ticketHistoryItemModalOpen: false
        });
    }

    private handleSolvedAtChange = (date: any) => {
        this.setState({
            solvedAt: moment.utc(date).startOf('day').toDate()
        });
    }

    private handleDownloadHistoryExportClick = (contractId: number) => {
        window.location.href = `/api/excel/copier-contract/${contractId}/history`;
    }

    private handleCopierDeviceExpandClick = () => {
        this.setState({
            copierDeviceModelExpanded: !this.state.copierDeviceModelExpanded
        });
    }

    private handleSignedByClientChange = (e: any) => {
        this.setState({
            signedByClient: e.target.checked
        }, () => this.validate(true));
    }

    private handleBlackAndWhiteCounterChange = (e: any) => {
        this.setState({
            blackAndWhiteCounter: parseInt(e.target.value.replace(/\D/, ""))
        });
    }

    private handleColorCounterChange = (e: any) => {
        this.setState({
            colorCounter: parseInt(e.target.value.replace(/\D/, ""))
        });
    }

    private handleNoteChange = (e: any) => {
        this.setState({
            note: e.target.value
        });
    }

    private handleExpandClick = (value?: boolean) => {
        if (value == null || value !== this.props.isExpanded) {
            this.props.expandCollapse();
        }
    }

    private handleSolveClick = () => {
        const payload = {
            blackAndWhiteCounter: this.state.blackAndWhiteCounter,
            colorCounter: this.state.colorCounter,
            messageToClient: this.state.note,
            solvedAt: this.state.solvedAt
        };

        this.props.handleSolveClick(payload);
    }

    private isValid = () => {
        return this.validate();
    }

    private validate = (shouldSetState: boolean = false) => {
        const validationErrors: string[] = [];

        if (!this.state.signedByClient) {
            validationErrors.push("Csak ügyfél által aláírt karbantartás küldhető be.");
        }

        if (shouldSetState) {
            this.setState({
                validationErrors: validationErrors
            });
        }

        return validationErrors.length === 0;
    }

    private toggleHistoryItemIndicatorTooltipOpen = (index: number) => {
        this.setState({
            historyItemIndicatorTooltipOpen: this.state.historyItemIndicatorTooltipOpen === index ? NaN : index
        });
    }

    private toggleMemoTooltip = () => {
        this.setState({
            memoTooltipOpen: !this.state.memoTooltipOpen
        });
    }

    private toggleDeviceSerialNumberTooltip = () => {
        this.setState({
            deviceSerialNumberTooltipOpen: !this.state.deviceSerialNumberTooltipOpen
        });
    }

    private toggleDeviceModelTooltip = () => {
        this.setState({
            deviceModelTooltipOpen: !this.state.deviceModelTooltipOpen
        });
    }

    private toggleContractTypeTooltip = () => {
        this.setState({
            contractTypeTooltipOpen: !this.state.contractTypeTooltipOpen
        });
    }

    private toggleContractNumberTooltip = () => {
        this.setState({
            contractNumberTooltipOpen: !this.state.contractNumberTooltipOpen
        });
    }

    private toggleTicketIndicatorTooltip = () => {
        this.setState({
            ticketIndicatorTooltipOpen: !this.state.ticketIndicatorTooltipOpen
        });
    }
}

export default ScheduledCopierTicketCard;

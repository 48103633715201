import * as React from 'react';
import { CopierContract, CopierContractType } from "../models";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import moment from 'moment';
import { getFriendlyNameFromCopierContractType } from '../utils';

interface CopierContractDetailsModalProps {
    open: boolean;
    contract: CopierContract | null;
    close: () => void;
}

export const CopierContractDetailsModal = (props: CopierContractDetailsModalProps) => (
    <Modal isOpen={props.open} toggle={props.close} className="modal-dialog-centered">
        <ModalHeader className="lansoft-success" toggle={props.close}>Másolástechnikai szerződés részletei</ModalHeader>
        <ModalBody>
            {props.contract != null && <React.Fragment>
                <div>
                    <label className="h6">Szerződésszám</label>
                    <div>{props.contract.contractNumber}</div>
                </div>
                <div className="mt-3">
                    <label className="h6">Ügyfél neve</label>
                    <div>{props.contract.client.name}</div>
                </div>
                <div className="mt-3">
                    <label className="h6">Ügyfél címe</label>
                    <div>{props.contract.client.address}</div>
                </div>
                {props.contract.client.contactPhoneNumber != null && props.contract.client.contactPhoneNumber.length > 0 && <div className="mt-3">
                    <label className="h6">Ügyfél kapcsolati telefonszáma</label>
                    <div>{props.contract.client.contactPhoneNumber}</div>
                </div>}
                <div className="mt-3">
                    <label className="h6">Technikus neve</label>
                    <div>{props.contract.technician.name}</div>
                </div>
                <div className="mt-3">
                    <label className="h6">Eszköz sorozatszáma</label>
                    <div>{props.contract.device.serialNumber}</div>
                </div>
                <div className="mt-3">
                    <label className="h6">Eszköz típusa</label>
                    <div>{props.contract.device.model.name}</div>
                </div>
                <div className="mt-3">
                    <label className="h6">Eszköz színes</label>
                    <div>{props.contract.device.model.color ? "Igen" : "Nem"}</div>
                </div>
                <div className="mt-3">
                    <label className="h6">Kezdeti fekete-fehér számláló</label>
                    <div>{props.contract.blackAndWhiteCounter}</div>
                </div>
                {props.contract.device.model.color && <div className="mt-3">
                    <label className="h6">Kezdeti színes számláló</label>
                    <div>{props.contract.colorCounter}</div>
                </div>}
                <div className="mt-3">
                    <label className="h6">Szerződés típusa</label>
                    <div>{getFriendlyNameFromCopierContractType(props.contract.contractType)}{props.contract.contractType === CopierContractType.Monthly && `/${props.contract.maintenanceIntervalMonths}`}</div>
                </div>
                {(props.contract.contractType === CopierContractType.Lease || props.contract.contractType === CopierContractType.Monthly) &&
                    <div className="mt-3">
                        <label className="h6">Bérleti díj</label>
                        <div>{props.contract.monthlyLeasePrice} Ft + ÁFA</div>
                    </div>
                }
                {props.contract.contractType === CopierContractType.Lease &&
                    <div className="mt-3">
                        <label className="h6">Benne foglalt fekete-fehér nyomat</label>
                        <div>{props.contract.includedBlackAndWhitePrints}</div>
                    </div>
                }
                {(props.contract.contractType === CopierContractType.Lease || props.contract.contractType === CopierContractType.AllRoundOperation) &&
                    <div className="mt-3">
                        <label className="h6">Fekete-fehér nyomatdíj</label>
                        <div>{props.contract.blackAndWhitePrintPrice} Ft + ÁFA</div>
                    </div>
                }
                {props.contract.device.model.color && (props.contract.contractType === CopierContractType.Lease || props.contract.contractType === CopierContractType.AllRoundOperation) &&
                    <div className="mt-3">
                        <label className="h6">Színes nyomatdíj</label>
                        <div>{props.contract.colorPrintPrice} Ft + ÁFA</div>
                    </div>
                }
                <div className="mt-3">
                    <label className="h6">Memo</label>
                    <div>{props.contract.memo}</div>
                </div>
                <div className="mt-3">
                    <label className="h6">Kezdő dátum</label>
                    <div>{moment(props.contract.startDate).format("YYYY.MM.DD")}</div>
                </div>
                <div className="mt-3">
                    <label className="h6">Határozatlan idejű szerződés</label>
                    <div>{props.contract.endDate == null ? "Igen" : "Nem"}</div>
                </div>
                {props.contract.endDate != null && <div className="mt-3">
                    <label className="h6">Lejárat</label>
                    <div>{moment(props.contract.endDate).format("YYYY.MM.DD")}</div>
                </div>}
            </React.Fragment>}
        </ModalBody>
    </Modal>
);

export default CopierContractDetailsModal;

import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as LanSoftStore from '../store/LanSoftStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { LoadingIndicator, ToastContainer } from "./common";
import { CopierModelCreatePayload, CopierModelUpdatePayload } from "../payloads";
import CopierModelItem from "./CopierModelItem";

type CopierModelsPropsType = LanSoftStore.LanSoftState
    & typeof LanSoftStore.actionCreators
    & RouteComponentProps<{}>;

interface CopierModelsState {
    result: boolean | null;
    toastOpen: boolean;
    editIndex: number;
    deleteIndex: number;
    validationErrors: string[];
    copierModelId: number;
    copierModelName: string;
    copierModelColor: boolean;
}

class CopierModels extends React.PureComponent<CopierModelsPropsType, CopierModelsState> {
    constructor(props: CopierModelsPropsType) {
        super(props);

        this.state = {
            result: null,
            toastOpen: false,
            editIndex: NaN,
            deleteIndex: NaN,
            validationErrors: [],
            copierModelId: 0,
            copierModelName: "",
            copierModelColor: false,
        }
    }

    componentDidMount() {
        this.props.getAllCopierModels();
    }

    UNSAFE_componentWillReceiveProps(nextProps: CopierModelsPropsType) {
        if (nextProps.result !== this.props.result && nextProps.result != null) {
            this.setState({
                result: nextProps.result,
                toastOpen: true
            });

            window.setTimeout(() => {
                this.setState({
                    toastOpen: false
                }, () => {
                    this.props.clearResult();
                });
            }, 3000);
        }
    }

    public render() {
        return (
            <React.Fragment>
                <LoadingIndicator show={this.props.loading > 0} />
                <h4 className="mb-3"><FontAwesomeIcon icon={["fas", "hdd"]} className="lansoft-text-success" /> <span className="lansoft-underline-success">Másolástechnikai géptípusok {`(${this.props.copierModels.length})`}</span></h4>

                <div className="d-flex justify-content-end">
                    <Button onClick={this.handleCreateCopierModelClick} color="success" className="lansoft-success">
                        <FontAwesomeIcon icon={["fas", "plus"]} /> Új géptípus
                    </Button>
                </div>

                {this.props.copierModels.length === 0 &&
                    <div className="d-flex justify-content-center text-white font-italic">
                        <span>Nem található géptípus.</span>
                    </div>
                }

                <ul className="list-group mt-3 diagonal-background-flat diagonal-background-lansoft-primary position-relative no-left-border-radius">
                    {this.props.copierModels.map((copierModel, index) =>
                        <li className="list-group-item" key={`copier-model-list-item-${index}`}>
                            <CopierModelItem
                                model={copierModel}
                                editCopierModel={() => this.handleEditCopierModelClick(index)}
                                deleteCopierModel={() => this.handleDeleteCopierModelClick(index)}
                            />
                        </li>
                    )}
                </ul>

                {this.getEditModal()}
                {this.getDeleteModal()}
                <ToastContainer open={this.state.toastOpen} color={this.state.result ? "success" : "danger"} success={this.state.result === true} />
            </React.Fragment>

        );
    }

    private getEditModal = () => {
        return (
            <Modal isOpen={!isNaN(this.state.editIndex) && !this.props.loading && this.props.copierModels.length > this.state.editIndex} toggle={this.closeEditModal} className="modal-dialog-centered">
                <ModalHeader className="lansoft-success" toggle={this.closeEditModal}>Géptípus {this.state.editIndex === -1 ? "létrehozása" : "szerkesztése"}</ModalHeader>
                <ModalBody>
                    <div className="form-group">
                        <label htmlFor="inputCopierModelName">Név</label>
                        <input type="text" className="form-control" id="inputCopierModelName" autoComplete="off" placeholder="Név" value={this.state.copierModelName} onChange={this.handleCopierModelNameChange} />
                    </div>

                    <div className="form-check mt-2">
                        <input type="checkbox" className="form-check-input" id="inputCopierModelColor" checked={this.state.copierModelColor} onChange={this.handleCopierModelColorChange} />
                        <label className="form-check-label" htmlFor="inputCopierModelColor">Színes</label>
                    </div>

                    {this.state.validationErrors.length > 0 &&
                        <Card className="mt-2 mb-2 p-2 bg-danger lansoft-danger text-white">
                            {this.state.validationErrors.map((error, index) => {
                                return (
                                    <div key={`copierModel-form-validation-error-${index}`} className="form-invalid">
                                        {error}
                                    </div>
                                );
                            })}
                        </Card>
                    }

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleSaveCopierModelClick} type="button" className="btn btn-primary lansoft-primary" disabled={!this.isValid()}>
                            <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Mentés
                        </button>

                        <button className="btn btn-secondary" onClick={this.handleCancelEditClick}>
                            <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private getDeleteModal = () => {
        return (
            <Modal isOpen={!isNaN(this.state.deleteIndex) && !this.props.loading} toggle={this.closeDeleteModal} className="modal-dialog-centered">
                <ModalHeader className="lansoft-success" toggle={this.closeDeleteModal}>Géptípus törlése</ModalHeader>
                <ModalBody>
                    <div>
                        <p>
                            Biztosan törölni szeretnéd az alábbi géptípust?
                        </p>
                        <p>{!isNaN(this.state.deleteIndex) && <strong>{this.props.copierModels[this.state.deleteIndex].name}</strong>}</p>
                    </div>

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleConfirmDeleteCopierModelClick} type="button" className="btn btn-danger lansoft-danger">
                            <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Törlés
                        </button>

                        <button className="btn btn-secondary" onClick={this.handleCancelDeleteClick}>
                            <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private resetForm = () => {
        this.setState({
            copierModelId: 0,
            copierModelName: "",
            copierModelColor: false,
            editIndex: NaN,
            validationErrors: []
        });
    }

    private handleCancelEditClick = () => {
        this.setState({
            editIndex: NaN
        });
    }

    private handleCancelDeleteClick = () => {
        this.setState({
            deleteIndex: NaN
        });
    }

    private handleSaveCopierModelClick = () => {
        if (this.state.copierModelId === 0) {
            const payload: CopierModelCreatePayload = {
                name: this.state.copierModelName,
                color: this.state.copierModelColor
            };

            this.props.createCopierModel(payload);
        } else {
            const payload: CopierModelUpdatePayload = {
                name: this.state.copierModelName,
                color: this.state.copierModelColor
            };

            this.props.updateCopierModel(this.state.copierModelId, payload);
        }

        this.setState({
            editIndex: NaN
        });
    }

    private handleConfirmDeleteCopierModelClick = () => {
        const copierModel = this.props.copierModels[this.state.deleteIndex];
        this.props.deleteCopierModel(copierModel.id);
        this.setState({
            deleteIndex: NaN
        });
    }

    private handleCopierModelColorChange = (e: any) => {
        this.setState({
            copierModelColor: e.target.checked
        });
    }

    private handleCopierModelNameChange = (e: any) => {
        this.setState({
            copierModelName: e.target.value
        }, () => { this.validate(true); });
    }

    private closeEditModal = () => {
        this.setState({
            editIndex: NaN
        });
    }

    private closeDeleteModal = () => {
        this.setState({
            deleteIndex: NaN
        });
    }

    private handleCreateCopierModelClick = () => {
        this.resetForm();
        this.setState({
            editIndex: -1,
            copierModelId: 0,
            copierModelName: "",
            copierModelColor: false
        });
    }

    private handleEditCopierModelClick = (index: number) => {
        this.resetForm();
        const copierModel = this.props.copierModels[index];
        this.setState({
            editIndex: index,
            copierModelId: copierModel.id,
            copierModelName: copierModel.name,
            copierModelColor: copierModel.color
        }, () => this.validate(true));
    }

    private handleDeleteCopierModelClick = (index: number) => {
        this.setState({
            deleteIndex: index
        });
    }

    private isValid = () => {
        return this.validate();
    }

    private validate = (shouldSetState: boolean = false) => {
        const validationErrors: string[] = [];

        if (this.state.copierModelName.length === 0) {
            validationErrors.push("A név megadása kötelező.");
        }

        if (shouldSetState) {
            this.setState({
                validationErrors: validationErrors
            });
        }

        return validationErrors.length === 0;
    }
}

export default connect(
    (state: ApplicationState) => state.lanSoft,
    LanSoftStore.actionCreators
)(CopierModels as any);

import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CopierDevice } from "../models";
import { Button } from "reactstrap";

interface CopierDeviceItemItemProps {
    className?: string;
    device: CopierDevice;
    editCopierDevice?: () => void;
    deleteCopierDevice?: () => void;
    mobile?: boolean;
}

export const CopierDeviceItemItem = (props: CopierDeviceItemItemProps) => (
    <div className={`d-flex align-items-center justify-content-between flex-same-width-container ${(props.className != null ? props.className : "")}`}>
        <div>
            <div className="h5 mb-0">{props.device.serialNumber}</div>
        </div>
        <div>
            <div className="h5 mb-0 ml-4">{props.device.model.name}</div>
        </div>
        {!props.mobile && <div>
            <div className="h5 mb-0 d-flex justify-content-center">{props.device.model.color ? "Színes" : "Fekete"}</div>
        </div>}
        <div>
            <div className="d-flex justify-content-end">
                {props.editCopierDevice != null && <Button color="primary" className="lansoft-primary" onClick={props.editCopierDevice}><FontAwesomeIcon icon={["fas", "pencil-alt"]} /></Button>}
                {props.deleteCopierDevice != null && <Button color="danger" className="ml-3 lansoft-danger" onClick={props.deleteCopierDevice}><FontAwesomeIcon icon={["fas", "trash-alt"]} /></Button>}
            </div>
        </div>
    </div>
);

export default CopierDeviceItemItem;

import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as LanSoftStore from '../store/LanSoftStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import { LoadingIndicator, ToastContainer } from "./common";
import Datetime from 'react-datetime';
import moment from 'moment';
import { PrintVolumesExportPayload } from '../payloads';

type DataExportPropsType = LanSoftStore.LanSoftState
    & typeof LanSoftStore.actionCreators
    & RouteComponentProps<{}>;

interface DataExportState {
    from: Date;
    to: Date;
    result: boolean | null;
    toastOpen: boolean;
}

class DataExport extends React.PureComponent<DataExportPropsType, DataExportState> {
    constructor(props: DataExportPropsType) {
        super(props);

        this.state = {
            from: moment.utc(new Date()).startOf('day').add(-1, "months").toDate(),
            to: moment.utc(new Date()).startOf('day').toDate(),
            result: null,
            toastOpen: false
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: DataExportPropsType) {
        if (nextProps.result !== this.props.result && nextProps.result != null) {
            this.setState({
                result: nextProps.result,
                toastOpen: true
            });

            window.setTimeout(() => {
                this.setState({
                    toastOpen: false
                }, () => {
                    this.props.clearResult();
                });
            }, 3000);
        }
    }

    public render() {
        return (
            <React.Fragment>
                <LoadingIndicator show={this.props.loading > 0} />
                <h4 className="mb-3"><FontAwesomeIcon icon={["fas", "file-download"]} className="lansoft-text-success" /> <span className="lansoft-underline-success">Exportálás</span></h4>

                <div className="mt-4 pt-4 pb-5 border-bottom lansoft-border-success">
                    <div>
                        <Button color="primary" className="lansoft-primary" onClick={this.handleDownloadActiveCopierDevicesExportClick}>Aktív másolástechnikai gépek exportálása</Button>
                    </div>
                </div>

                <div className="mt-5">
                    <div className="d-flex">
                        <div className="form-group">
                            <label>Kezdő dátum</label>
                            <Datetime
                                className="text-black"
                                closeOnClickOutside={true}
                                closeOnSelect={true}
                                dateFormat="YYYY.MM.DD"
                                timeFormat={false}
                                utc={true}
                                value={this.state.from}
                                onChange={this.handleFromChange} />
                        </div>
                        <div className="form-group ml-3">
                            <label>Vége dátum</label>
                            <Datetime
                                className="text-black"
                                closeOnClickOutside={true}
                                closeOnSelect={true}
                                dateFormat="YYYY.MM.DD"
                                timeFormat={false}
                                utc={true}
                                value={this.state.to}
                                onChange={this.handleToChange} />
                        </div>
                    </div>
                    <div className="mt-2">
                        <Button color="primary" className="lansoft-primary" onClick={this.handleDownloadPrintVolumesExportClick}>Nyomtatott mennyiségek exportálása</Button>
                    </div>
                </div>

                <ToastContainer open={this.state.toastOpen} color={this.state.result ? "success" : "danger"} success={this.state.result === true} />
            </React.Fragment>

        );
    }

    private handleFromChange = (date: any) => {
        this.setState({
            from: moment.utc(date).startOf('day').toDate()
        });
    }

    private handleToChange = (date: any) => {
        this.setState({
            to: moment.utc(date).startOf('day').toDate()
        });
    }

    private handleDownloadActiveCopierDevicesExportClick = () => {
        window.location.href = `/api/excel/active-copier-devices`;
    }

    private handleDownloadPrintVolumesExportClick = () => {
        const payload: PrintVolumesExportPayload = {
            from: this.state.from,
            to: this.state.to
        };

        this.props.getPrintVolumesExport(payload);
    }
}

export default connect(
    (state: ApplicationState) => state.lanSoft,
    LanSoftStore.actionCreators
)(DataExport as any);

import * as React from 'react';
import { Route, Redirect, Switch } from 'react-router';
import Layout from './components/Layout';
import MainRedirect from './components/MainRedirect';
import Clients from './components/Clients';
import CopierDevices from "./components/CopierDevices";
import CopierModels from "./components/CopierModels";
import Technicians from './components/Technicians';
import ContractsAdmin from './components/ContractsAdmin';
import ClientDashboard from './components/ClientDashboard';
import Tickets from './components/Tickets';
import CopierCounterStatements from './components/CopierCounterStatements';
import CopierTicketTypes from './components/CopierTicketTypes';
import CopierTicketSolutionTypes from './components/CopierTicketSolutionTypes';
import ItTicketTypes from './components/ItTicketTypes';
import ItTicketSolutionTypes from './components/ItTicketSolutionTypes';
import DataImport from './components/DataImport';
import DataExport from './components/DataExport';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';

import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

import './custom.css';
import "react-datetime/css/react-datetime.css";

export default () => (
    <Layout>
        <Switch>
            <AuthorizeRoute exact path='/' component={MainRedirect} />
            <AuthorizeRoute exact path='/my' component={ClientDashboard} />
            <AuthorizeRoute exact path='/admin/technicians' component={Technicians} />
            <AuthorizeRoute exact path='/admin/clients' component={Clients} />
            <AuthorizeRoute exact path='/admin/copier-models' component={CopierModels} />
            <AuthorizeRoute exact path='/admin/copier-devices' component={CopierDevices} />
            <AuthorizeRoute exact path='/admin/contracts' component={ContractsAdmin} />
            <AuthorizeRoute exact path='/admin/tickets' component={Tickets} />
            <AuthorizeRoute exact path='/admin/copier-counter-statements' component={CopierCounterStatements} />
            <AuthorizeRoute exact path='/admin/copier-ticket-types' component={CopierTicketTypes} />
            <AuthorizeRoute exact path='/admin/copier-ticket-solution-types' component={CopierTicketSolutionTypes} />
            <AuthorizeRoute exact path='/admin/it-ticket-types' component={ItTicketTypes} />
            <AuthorizeRoute exact path='/admin/it-ticket-solution-types' component={ItTicketSolutionTypes} />
            <AuthorizeRoute exact path='/admin/data-import' component={DataImport} />
            <AuthorizeRoute exact path='/admin/data-export' component={DataExport} />
            <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
            <Redirect to='/' />
        </Switch>
    </Layout>
);

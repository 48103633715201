import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, CardBody, CardTitle, Modal, ModalHeader, ModalBody, Tooltip } from 'reactstrap';
import { CopierContract, CopierContractType, CopierCounterStatement, CopierCounterStatementTab } from "../models";
import { getFriendlyNameFromCopierContractType, getIndicatorClassFromStatement, getTooltipTextFromStatement } from "../utils/EnumUtils";
import moment from 'moment';
import { Bar } from 'react-chartjs-2';
import { CopierCounterStatementCreatePayload } from "../payloads";
import { copierContractPrintHistoryBarChartOptions, copierContractPrintHistoryBarChartOptionsForMobile, getCopierContractPrintHistoryBarChartData, getNumberInputValue, MAX_DEVICE_NAME_LENGTH_DISPLAY } from '../utils';
import { isMobileOnly } from "react-device-detect";

interface CopierCounterStatementCardPropsType {
    isExpanded: boolean;
    statement: CopierCounterStatement;
    contract: CopierContract;
    createInvoice: (file: File) => void;
    createStatement: (payload: CopierCounterStatementCreatePayload) => void;
    sendStatementNotification: () => void;
    expandCollapse: () => void;
    tab: CopierCounterStatementTab;
    statements: CopierCounterStatement[];
    contracts: CopierContract[];
}

interface CopierCounterStatementCardState {
    validationErrors: string[];
    validationErrorsCounterStatement: string[];
    ticketIndicatorTooltipOpen: boolean;
    contractNumberTooltipOpen: boolean;
    contractTypeTooltipOpen: boolean;
    deviceModelTooltipOpen: boolean;
    deviceSerialNumberTooltipOpen: boolean;
    memoTooltipOpen: boolean;
    sendEmailButtonTooltipOpen: boolean;
    sendStatementButtonTooltipOpen: boolean;
    invoiceModalStatementId: number;
    invoiceFile: File | null;
    blackAndWhiteCounter: number;
    colorCounter: number | null;
    copierDeviceModelExpanded: boolean;
    statementContractNumberTooltipOpenId: number;
}

export class CopierCounterStatementCard extends React.PureComponent<CopierCounterStatementCardPropsType, CopierCounterStatementCardState> {
    constructor(props: CopierCounterStatementCardPropsType) {
        super(props);

        this.state = {
            validationErrors: [],
            validationErrorsCounterStatement: [],
            ticketIndicatorTooltipOpen: false,
            contractNumberTooltipOpen: false,
            contractTypeTooltipOpen: false,
            deviceModelTooltipOpen: false,
            deviceSerialNumberTooltipOpen: false,
            memoTooltipOpen: false,
            sendEmailButtonTooltipOpen: false,
            sendStatementButtonTooltipOpen: false,
            invoiceModalStatementId: NaN,
            invoiceFile: null,
            blackAndWhiteCounter: props.contract.suggestedNextBlackAndWhiteCounter,
            colorCounter: props.contract.suggestedNextColorCounter,
            copierDeviceModelExpanded: false,
            statementContractNumberTooltipOpenId: NaN
        }
    }

    public render() {
        const deviceNameLong = this.props.contract.device.model.name.length > MAX_DEVICE_NAME_LENGTH_DISPLAY;
        const previousBlackAndWhiteCounterValue = this.props.contract.statements.length > 0 ? this.props.contract.statements[0].blackAndWhiteCounterValue : this.props.contract.blackAndWhiteCounter;
        const previousColorCounterValue = this.props.contract.statements.length > 0 ? this.props.contract.statements[0].colorCounterValue : this.props.contract.colorCounter;

        const addressSecondSpaceIndex = this.props.contract.client.address.indexOf(" ", this.props.contract.client.address.indexOf(" ") + 1);
        const firstAddressChunk = addressSecondSpaceIndex >= 0 ? this.props.contract.client.address.substr(0, addressSecondSpaceIndex).trim() : this.props.contract.client.address.substr(addressSecondSpaceIndex + 1).trim();
        let secondAddressChunk = "";
        if (addressSecondSpaceIndex >= 0) {
            secondAddressChunk = this.props.contract.client.address.substr(addressSecondSpaceIndex + 1).trim();
        }

        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <CardTitle className="no-bottom-margin">
                            {isMobileOnly &&
                                <div className="h4 d-flex justify-content-between pb-1 mobile row border-bottom lansoft-border-success lansoft-border-full">
                                    <div />
                                    <div className={`d-flex align-items-center ${isMobileOnly ? "mb-3" : ""}`}>
                                        <div id={`counter-statement-ticket-indicator-${this.props.statement.id}-${this.props.contract.id}-${this.props.tab}`} className={`mr-1 ticket-indicator bg-${getIndicatorClassFromStatement(this.props.statement)}`}></div>
                                        <Tooltip placement="top" isOpen={this.state.ticketIndicatorTooltipOpen} target={`counter-statement-ticket-indicator-${this.props.statement.id}-${this.props.contract.id}-${this.props.tab}`} toggle={this.toggleTicketIndicatorTooltip}>
                                            {getTooltipTextFromStatement(this.props.statement)}
                                        </Tooltip>
                                        <span id={`counter-statement-contract-number-${this.props.statement.id}-${this.props.contract.id}-${this.props.tab}`}>{this.props.contract.contractNumber}</span>
                                        <Tooltip placement="top" isOpen={this.state.contractNumberTooltipOpen} target={`counter-statement-contract-number-${this.props.statement.id}-${this.props.contract.id}-${this.props.tab}`} toggle={this.toggleContractNumberTooltip}>
                                            Szerződésszám
                                        </Tooltip>
                                    </div>
                                    <div className={`cursor-pointer mr-3 ${this.props.statement.id === 0 ? "invisible" : ""}`} onClick={() => this.handleExpandClick()}><FontAwesomeIcon icon={["fas", this.props.isExpanded ? "chevron-up" : "chevron-down"]} className="mr-1" /></div>
                                </div>
                            }
                            <div className={`h4 d-flex justify-content-between flex-same-width-container ${isMobileOnly ? "mobile row pt-3" : "mt-4 mb-4 pb-4"}`}>
                                {!isMobileOnly && <div className={isMobileOnly ? "col-6" : ""}>
                                    <div className={`d-flex align-items-center ${isMobileOnly ? "mb-3" : ""}`}>
                                        <div id={`counter-statement-ticket-indicator-${this.props.statement.id}-${this.props.contract.id}-${this.props.tab}`} className={`mr-1 ticket-indicator bg-${getIndicatorClassFromStatement(this.props.statement)}`}></div>
                                        <Tooltip placement="top" isOpen={this.state.ticketIndicatorTooltipOpen} target={`counter-statement-ticket-indicator-${this.props.statement.id}-${this.props.contract.id}-${this.props.tab}`} toggle={this.toggleTicketIndicatorTooltip}>
                                            {getTooltipTextFromStatement(this.props.statement)}
                                        </Tooltip>
                                        <span id={`counter-statement-contract-number-${this.props.statement.id}-${this.props.contract.id}-${this.props.tab}`}>{this.props.contract.contractNumber}</span>
                                        <Tooltip placement="top" isOpen={this.state.contractNumberTooltipOpen} target={`counter-statement-contract-number-${this.props.statement.id}-${this.props.contract.id}-${this.props.tab}`} toggle={this.toggleContractNumberTooltip}>
                                            Szerződésszám
                                        </Tooltip>
                                    </div>
                                </div>}
                                <div className={isMobileOnly ? "col-6 mb-3 d-flex" : ""}>
                                    <div className={isMobileOnly ? "d-flex justify-content-end" : ""}>
                                        <div id={`counter-statement-contract-type-${this.props.statement.id}-${this.props.contract.id}-${this.props.tab}`} className="d-inline-block">
                                            {getFriendlyNameFromCopierContractType(this.props.contract.contractType)}{this.props.contract.contractType === CopierContractType.Monthly && `/${this.props.contract.maintenanceIntervalMonths}`}
                                        </div>
                                        <Tooltip placement="top" isOpen={this.state.contractTypeTooltipOpen} target={`counter-statement-contract-type-${this.props.statement.id}-${this.props.contract.id}-${this.props.tab}`} toggle={this.toggleContractTypeTooltip}>
                                            Szerződés típusa
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className={isMobileOnly ? "col-6 mb-3 d-flex" : ""}>
                                    <div onClick={this.handleCopierDeviceExpandClick} className="cursor-pointer">
                                        <div id={`counter-statement-device-model-${this.props.statement.id}-${this.props.contract.id}-${this.props.tab}`} className="d-inline-block">
                                            {!deviceNameLong && <React.Fragment>{this.props.contract.device.model.name}</React.Fragment>}
                                            {deviceNameLong && <React.Fragment>
                                                {!this.state.copierDeviceModelExpanded && <React.Fragment>
                                                    {this.props.contract.device.model.name.substring(0, MAX_DEVICE_NAME_LENGTH_DISPLAY - 2) + "..."}
                                                </React.Fragment>}
                                                {this.state.copierDeviceModelExpanded && <React.Fragment>
                                                    {this.props.contract.device.model.name.split(" ").map((fragment, index) =>
                                                        <span className="nowrap d-block" key={`device-model-name-fragment-${index}`}>{fragment}</span>)
                                                    }
                                                </React.Fragment>}
                                            </React.Fragment>}
                                        </div>
                                        <Tooltip placement="top" isOpen={this.state.deviceModelTooltipOpen} target={`counter-statement-device-model-${this.props.statement.id}-${this.props.contract.id}-${this.props.tab}`} toggle={this.toggleDeviceModelTooltip}>
                                            Gép típusa
                                    </Tooltip>
                                    </div>
                                </div>
                                <div className={isMobileOnly ? "col-6 mb-3 d-flex" : ""}>
                                    <div className={isMobileOnly ? "text-right" : ""}>
                                        <div id={`counter-statement-device-serial-number-${this.props.statement.id}-${this.props.contract.id}-${this.props.tab}`} className="d-inline-block">
                                            {this.props.contract.device.serialNumber}
                                        </div>
                                        <Tooltip placement="top" isOpen={this.state.deviceSerialNumberTooltipOpen} target={`counter-statement-device-serial-number-${this.props.statement.id}-${this.props.contract.id}-${this.props.tab}`} toggle={this.toggleDeviceSerialNumberTooltip}>
                                            Gép gyári száma
                                    </Tooltip>
                                    </div>
                                </div>
                                <div className={`flex-half-width-column ${isMobileOnly ? "mobile col" : ""}`}>
                                    <div className={`d-flex ${isMobileOnly ? "" : (this.props.statement.id === 0 ? "" : "justify-content-end")}`}>
                                        <div id={`counter-statement-memo-${this.props.statement.id}-${this.props.contract.id}-${this.props.tab}`}>
                                            {this.props.contract.memo}
                                        </div>
                                        <Tooltip placement="top" isOpen={this.state.memoTooltipOpen} target={`counter-statement-memo-${this.props.statement.id}-${this.props.contract.id}-${this.props.tab}`} toggle={this.toggleMemoTooltip}>
                                            Memo
                                        </Tooltip>
                                        {!isMobileOnly && <div className={`cursor-pointer ml-3 ${this.props.statement.id === 0 ? "invisible" : ""}`} onClick={() => this.handleExpandClick()}><FontAwesomeIcon icon={["fas", this.props.isExpanded ? "chevron-up" : "chevron-down"]} className="mr-1" /></div>}
                                    </div>
                                </div>
                            </div>
                            {this.props.statement.id > 0 && <div className={`mt-1 pt-3 border-top ${isMobileOnly ? " lansoft-border-success" : "lansoft-border-success-wider"}`}></div>}
                            {this.props.statement.id === 0 && <div className={`mt-1 pt-3 border-top ${isMobileOnly ? " lansoft-border-success" : "lansoft-border-success-even-wider"}`}></div>}
                            {this.props.statement.id > 0 && <React.Fragment>
                                <div className={`${isMobileOnly ? "row" : "flex-same-width-container mb-3"}`}>
                                    {isMobileOnly && <div className="h5 col-12 d-flex justify-content-center">Technikus: {this.props.contract.technician.name}</div>}
                                    <div className={isMobileOnly ? "col-12 mt-2 mb-2" : ""}>
                                        <div className={isMobileOnly ? "" : "border-right lansoft-border-success mr-5"}>
                                            <div className="h5 mb-3"><strong>{this.props.contract.client.name}</strong></div>
                                            <div className="h6 mb-3">
                                                {addressSecondSpaceIndex >= 0 ?
                                                    <div>
                                                        <div>{firstAddressChunk}</div>
                                                        <div>{secondAddressChunk}</div>
                                                    </div>
                                                    :
                                                    <div>
                                                        {this.props.contract.client.address}
                                                    </div>
                                                }
                                            </div>
                                            <div className="h6">{this.props.contract.client.contactPhoneNumber}</div>
                                        </div>
                                        {isMobileOnly && <div className="mt-1 pt-1 border-top lansoft-border-success"></div>}
                                    </div>
                                    <div className={isMobileOnly ? "col-6" : ""}>
                                        <div className={`h-100 py-2 width-fit-content ${!isMobileOnly ? "border-right lansoft-border-success pr-5" : ""}`}>
                                            <div className="d-flex align-items-center"><img src="icons/fekete_1.svg" className="custom-icon" alt="splash-icon-black" /><div className={isMobileOnly ? "ml-3" : "ml-2"}>Fekete {isMobileOnly ? "sz." : "számláló"}:{isMobileOnly && <br />} <strong>{this.props.statement.blackAndWhiteCounterValue}</strong></div></div>
                                            {this.props.contract.device.model.color && <div className="d-flex align-items-center mt-3"><img src="icons/szines_1.svg" alt="splash-icon-color" className="custom-icon" /><div className={isMobileOnly ? "ml-3" : "ml-2"}>Színes {isMobileOnly ? "sz." : "számláló"}:{isMobileOnly && <br />} <strong>{this.props.statement.colorCounterValue}</strong></div></div>}
                                        </div>
                                    </div>
                                    <div className={isMobileOnly ? "col-6" : ""}>
                                        <div className={`h-100 py-2 width-fit-content ${!isMobileOnly ? "border-right lansoft-border-success pr-5" : ""}`}>
                                            <div className="d-flex align-items-center"><img src="icons/fekete_1.svg" className="custom-icon" alt="splash-icon-black" /><div className={isMobileOnly ? "ml-3" : "ml-2"}>Fekete {isMobileOnly ? "ny." : "nyomat"}:{isMobileOnly && <br />} <strong>{this.props.statement.blackAndWhitePrints}</strong></div></div>
                                            {this.props.contract.device.model.color && <div className="d-flex align-items-center mt-3"><img src="icons/szines_1.svg" alt="splash-icon-color" className="custom-icon" /><div className={isMobileOnly ? "ml-3" : "ml-2"}>Színes {isMobileOnly ? "ny." : "nyomat"}:{isMobileOnly && <br />} <strong>{this.props.statement.colorPrints}</strong></div></div>}
                                        </div>
                                    </div>
                                    {!isMobileOnly && <div className="h5">Technikus:<br />{this.props.contract.technician.name}</div>}
                                    {!isMobileOnly && <div className="flex-half-width-column"></div>}
                                </div>
                                <div className={`mt-2 pt-2 border-top ${isMobileOnly ? "lansoft-border-success" : "lansoft-border-success-offset"}`}>
                                </div>
                                <div className={`mt-2 ${isMobileOnly ? "row" : "flex-same-width-container pt-3"}`}>
                                    <div className={isMobileOnly ? "col-6" : ""}>
                                        <div className={isMobileOnly ? "h-100" : ""}>
                                            <Button color="primary" className={`lansoft-primary ${isMobileOnly ? "h-100" : ""}`} onClick={() => this.handleExpandClick()}>Részletek <FontAwesomeIcon icon={["fas", this.props.isExpanded ? "chevron-up" : "chevron-down"]} className="ml-1" /></Button>
                                        </div>
                                    </div>
                                    <div className={isMobileOnly ? "col-6" : "flex-double-width-column"}>
                                        <div className={isMobileOnly ? "h-100" : ""}>
                                            <Button color="primary" className={`lansoft-primary ${isMobileOnly ? " h-100" : ""}`} onClick={() => this.toggleInvoiceModal(this.props.statement.id)}>Számla {this.props.statement.invoice == null ? "feltöltés" : "csere"}</Button>
                                        </div>
                                    </div>
                                    <div className={`h5 ${isMobileOnly ? "col-12 d-flex justify-content-between mt-4" : ""}`}>
                                        <div className={`mb-2 ${isMobileOnly ? "col-6 no-left-padding" : ""}`}>Bejelentve:{isMobileOnly && <br />} {moment(this.props.statement.createdAt).format("YYYY.MM.DD HH:mm")}</div>
                                        <div className={isMobileOnly ? "col-6" : ""}>
                                            Fizetendő:{isMobileOnly && <br />} <strong>{this.props.statement.amountToBePaid} Ft + ÁFA</strong>
                                        </div>
                                    </div>
                                    {!isMobileOnly && <div className="flex-half-width-column"></div>}
                                </div>
                            </React.Fragment>
                            }
                            {this.props.statement.id === 0 &&
                                <React.Fragment>
                                    <div className={`${isMobileOnly ? "row" : "flex-same-width-container mb-3"}`}>
                                        {isMobileOnly && <div className="h5 col-12 mb-4 d-flex justify-content-center">Technikus: {this.props.contract.technician.name}</div>}
                                        <div className={isMobileOnly ? "col-12 mb-4" : "flex-wider-column"}>
                                            <div className={isMobileOnly ? "" : "h-100 border-right lansoft-border-success mr-5"}>
                                                <div className="h5 mb-3"><strong>{this.props.contract.client.name}</strong></div>
                                                <div className="h6 mb-3">
                                                    {addressSecondSpaceIndex >= 0 ?
                                                        <div>
                                                            <div>{firstAddressChunk}</div>
                                                            <div>{secondAddressChunk}</div>
                                                        </div>
                                                        :
                                                        <div>
                                                            {this.props.contract.client.address}
                                                        </div>
                                                    }
                                                </div>
                                                <div className="h6">{this.props.contract.client.contactPhoneNumber}</div>
                                            </div>
                                        </div>
                                        {!isMobileOnly && <React.Fragment>
                                            <div className="flex-wider-column">
                                                <div className="d-flex justify-content-start h-100">
                                                    <div className="d-inline-block pr-8 border-right lansoft-border-success width-fit-content h-100">
                                                        <div className="mb-4"><strong>Legutóbbi:</strong></div>
                                                        <div className="mb-4 form-group line-height-higher"><img src="icons/fekete_1.svg" alt="splash-icon-black" className="custom-icon mr-2" />Fekete számláló: <strong>{previousBlackAndWhiteCounterValue}</strong></div>
                                                        {this.props.contract.device.model.color && <div className="mb-4 mt-2 form-group line-height-higher"><img src="icons/szines_1.svg" alt="splash-icon-color" className="custom-icon mr-2" />Színes számláló: <strong>{previousColorCounterValue}</strong></div>}
                                                        <div className="mt-4-5">
                                                            <Button id={`send-email-button-${this.props.statement.id}-${this.props.contract.id}-${this.props.tab}`} color="primary" className="lansoft-primary" onClick={this.handleSendStatementNotificationButtonClick}>E-mail küldése</Button>
                                                            <Tooltip placement="top" isOpen={this.state.sendEmailButtonTooltipOpen} target={`send-email-button-${this.props.statement.id}-${this.props.contract.id}-${this.props.tab}`} toggle={this.toggleSendEmailButtonTooltip}>
                                                                Felszólító e-mail küldése az ügyfélnek az esedékes számláló bejelentésről
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-wider-column h-100">
                                                <div className="flex-wider-column border-right lansoft-border-success h-100">
                                                    <div className="mb-3"><strong>Jelenlegi - {moment(new Date()).format("YYYY.MM.DD")}:</strong></div>
                                                    <div className={`form-group form-inline ${!this.props.contract.device.model.color ? "mb-4" : ""}`}>
                                                        <label className="mr-2 label-125"><img src="icons/fekete_1.svg" alt="splash-icon-black" className="custom-icon mr-2" />Fekete számláló</label>
                                                        <input type="text" className="form-control numeric-input" autoComplete="off" placeholder="Fekete számláló" value={getNumberInputValue(this.state.blackAndWhiteCounter)} onChange={this.handleBlackAndWhiteCounterChange} />
                                                    </div>
                                                    {this.props.contract.device.model.color && <div className="form-group form-inline pb-2">
                                                        <label className="mr-2 label-125"><img src="icons/szines_1.svg" alt="splash-icon-color" className="custom-icon mr-2" />Színes számláló</label>
                                                        <input type="text" className="form-control numeric-input" autoComplete="off" placeholder="Színes számláló" value={getNumberInputValue(this.state.colorCounter || 0)} onChange={this.handleColorCounterChange} />
                                                    </div>}
                                                    <div>
                                                        <Button id={`send-statement-button-${this.props.statement.id}-${this.props.contract.id}-${this.props.tab}`} color="primary" className="lansoft-primary" onClick={this.handleCreateStatementClick} disabled={!this.isValidCounterStatement()}>Elküld</Button>
                                                        <Tooltip placement="top" isOpen={this.state.sendStatementButtonTooltipOpen} target={`send-statement-button-${this.props.statement.id}-${this.props.contract.id}-${this.props.tab}`} toggle={this.toggleSendStatementButtonTooltip}>
                                                            Az ügyfél jelenlegi mérőállásának rögzítése
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>}
                                        {isMobileOnly && <React.Fragment>
                                            <div className="col-6">
                                                <div className="d-flex justify-content-start">
                                                    <div className="d-inline-block pr-2 border-right lansoft-border-success width-fit-content h-100">
                                                        <div className="mb-3"><strong>Legutóbbi:<span className="invisible"><br /> - {moment(new Date()).format("YYYY.MM.DD")}</span></strong></div>
                                                        <div className={`form-group form-inline ${!this.props.contract.device.model.color ? "mb-4" : ""}`}>
                                                            <label className="mr-2 label-125"><img src="icons/fekete_1.svg" alt="splash-icon-black" className="custom-icon mr-2" />Fekete sz.</label>
                                                            <input type="text" disabled className="form-control numeric-input" autoComplete="off" placeholder="Fekete számláló" value={previousBlackAndWhiteCounterValue} />
                                                        </div>
                                                        {this.props.contract.device.model.color && <div className="form-group form-inline">
                                                            <label className="mr-2 label-125"><img src="icons/szines_1.svg" alt="splash-icon-color" className="custom-icon mr-2" />Színes sz.</label>
                                                            <input type="text" disabled className="form-control numeric-input" autoComplete="off" placeholder="Színes számláló" value={previousColorCounterValue || 0} />
                                                        </div>}
                                                        <div>
                                                            <Button id={`send-email-button-${this.props.statement.id}-${this.props.contract.id}-${this.props.tab}`} color="primary" className="lansoft-primary" onClick={this.handleSendStatementNotificationButtonClick}>E-mail küldése</Button>
                                                            <Tooltip placement="top" isOpen={this.state.sendEmailButtonTooltipOpen} target={`send-email-button-${this.props.statement.id}-${this.props.contract.id}-${this.props.tab}`} toggle={this.toggleSendEmailButtonTooltip}>
                                                                Felszólító e-mail küldése az ügyfélnek az esedékes számláló bejelentésről
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div>
                                                    <div className="mb-3"><strong>Jelenlegi<br />{moment(new Date()).format("YYYY.MM.DD")}:</strong></div>
                                                    <div className={`form-group form-inline ${!this.props.contract.device.model.color ? "mb-4" : ""}`}>
                                                        <label className="mr-2 label-125"><img src="icons/fekete_1.svg" alt="splash-icon-black" className="custom-icon mr-2" />Fekete sz.</label>
                                                        <input type="text" className="form-control numeric-input" autoComplete="off" placeholder="Fekete számláló" value={getNumberInputValue(this.state.blackAndWhiteCounter)} onChange={this.handleBlackAndWhiteCounterChange} />
                                                    </div>
                                                    {this.props.contract.device.model.color && <div className="form-group form-inline">
                                                        <label className="mr-2 label-125"><img src="icons/szines_1.svg" alt="splash-icon-color" className="custom-icon mr-2" />Színes sz.</label>
                                                        <input type="text" className="form-control numeric-input" autoComplete="off" placeholder="Színes számláló" value={getNumberInputValue(this.state.colorCounter || 0)} onChange={this.handleColorCounterChange} />
                                                    </div>}
                                                    <div>
                                                        <Button id={`send-statement-button-${this.props.statement.id}-${this.props.contract.id}-${this.props.tab}`} color="primary" className="lansoft-primary" onClick={this.handleCreateStatementClick} disabled={!this.isValidCounterStatement()}>Elküld</Button>
                                                        <Tooltip placement="top" isOpen={this.state.sendStatementButtonTooltipOpen} target={`send-statement-button-${this.props.statement.id}-${this.props.contract.id}-${this.props.tab}`} toggle={this.toggleSendStatementButtonTooltip}>
                                                            Az ügyfél jelenlegi mérőállásának rögzítése
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>}
                                        {!isMobileOnly && <div className="h5 col-12"><div className="left-margin-82">Technikus:<br />{this.props.contract.technician.name}</div></div>}
                                    </div>
                                    {!isMobileOnly && <div className="border-top lansoft-border-success-offset-2"></div>}
                                </React.Fragment>
                            }
                        </CardTitle>

                        {this.props.statement.id > 0 && this.props.isExpanded && <div className={`border-top lansoft-border-success ${isMobileOnly ? "" : "mt-4"}`}>
                            <div className={`${isMobileOnly ? "pt-3" : "mt-3 p-5"}`}>
                                <Bar data={getCopierContractPrintHistoryBarChartData(this.props.contract)} options={isMobileOnly ? copierContractPrintHistoryBarChartOptionsForMobile : copierContractPrintHistoryBarChartOptions} height={isMobileOnly ? 250 : undefined} />
                            </div>

                            <div className="mt-4 pt-3 border-top lansoft-border-success">
                            </div>

                            <div className={`mt-3 ${isMobileOnly ? "" : "pt-2"}`}>
                                {this.props.contract.statements.length === 0 && <div>Nem található számláló.</div>}
                                {this.props.contract.statements.length > 0 &&
                                    <React.Fragment>
                                        <table className="table statements-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Dátum</th>
                                                    {!isMobileOnly && <th scope="col">Fekete sz.</th>}
                                                    {!isMobileOnly && this.props.contract.device.model.color && <th scope="col">Színes sz.</th>}
                                                    <th scope="col">{isMobileOnly ? "Fekete ny." : "Fekete nyomat"}</th>
                                                    {this.props.contract.device.model.color && <th scope="col">{isMobileOnly ? "Színes ny." : "Színes nyomat"}</th>}
                                                    <th scope="col">{isMobileOnly ? "Fizet." : "Fizetendő"}</th>
                                                    <th scope="col">Számla</th>
                                                    {!isMobileOnly && <th />}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.props.statements.map((statement, index) => {
                                                    const statementContract = this.props.contracts.find(x => x.id === statement.contractId);
                                                    if (statementContract == null) {
                                                        return null;
                                                    }

                                                    return (
                                                        <tr key={`contract-statement-row-${index}`}>
                                                            <td>{moment(statement.createdAt).format(isMobileOnly ? "MM.DD" : "YYYY.MM.DD HH:mm")}</td>
                                                            {!isMobileOnly && <td>{statement.blackAndWhiteCounterValue}</td>}
                                                            {!isMobileOnly && this.props.contract.device.model.color && <td>{statement.colorCounterValue || 0}</td>}
                                                            <td>{statement.blackAndWhitePrints}</td>
                                                            {this.props.contract.device.model.color && <td>{statement.colorPrints}</td>}
                                                            <td>{statement.amountToBePaid}</td>
                                                            <td className={isMobileOnly ? "d-flex justify-content-around" : ""}>{statement.invoice != null &&
                                                                <React.Fragment>
                                                                    {isMobileOnly
                                                                        ? <FontAwesomeIcon icon={["fas", "eye"]} onClick={() => this.handleDownloadInvoiceClick(statement.id)} />
                                                                        : <Button color="primary" className="lansoft-primary" onClick={() => this.handleDownloadInvoiceClick(statement.id)}>Megnéz</Button>}
                                                                    {isMobileOnly
                                                                        ? <FontAwesomeIcon icon={["fas", "sync"]} onClick={() => this.toggleInvoiceModal(statement.id)} />
                                                                        : <Button color="warning" className="lansoft-warning ml-3" onClick={() => this.toggleInvoiceModal(statement.id)}>Cserél</Button>}
                                                                </React.Fragment>}
                                                            </td>
                                                            {!isMobileOnly && <td className="history-table-contract-icon-column">
                                                                <div className="cursor-pointer d-flex align-items-center align-content-center justify-content-center" id={`statement-contract-number-icon-${this.props.contract.id}-${statement.id}`}>
                                                                    <FontAwesomeIcon icon={["fas", "file-contract"]} />
                                                                </div>
                                                                <Tooltip placement="left" isOpen={this.state.statementContractNumberTooltipOpenId === statement.id} target={`statement-contract-number-icon-${this.props.contract.id}-${statement.id}`} toggle={() => this.toggleStatementContractNumberTooltipId(statement.id)}>
                                                                    Szerződésszám: {statementContract.contractNumber}
                                                                </Tooltip>
                                                            </td>}
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                        <div>
                                            <Button color="primary" className="lansoft-primary" onClick={() => this.handleDownloadStatementHistoryExportClick(this.props.contract.id)}> Mentés Excelbe</Button>
                                        </div>
                                    </React.Fragment>
                                }
                            </div>
                        </div>}
                    </CardBody>
                </Card>

                {this.getInvoiceModal()}
            </React.Fragment >
        );
    }

    private getInvoiceModal = () => {
        return (
            <Modal isOpen={!isNaN(this.state.invoiceModalStatementId)} toggle={() => this.toggleInvoiceModal()} className="modal-dialog-centered">
                <ModalHeader className="lansoft-success" toggle={() => this.toggleInvoiceModal()}>Számla feltöltése</ModalHeader>
                <ModalBody>
                    <div className="form-group">
                        <label htmlFor="inputInvoice">Számla</label>
                        <input type="file" className="form-control" id="inputInvoice" onChange={this.handleInvoiceFileChange} />
                    </div>

                    {this.state.validationErrors.length > 0 &&
                        <Card className="mt-2 mb-2 p-2 bg-danger lansoft-danger text-white">
                            {this.state.validationErrors.map((error, index) => {
                                return (
                                    <div key={`copierModel-form-validation-error-${index}`} className="form-invalid">
                                        {error}
                                    </div>
                                );
                            })}
                        </Card>
                    }

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleUploadInvoiceClick} type="button" className="btn btn-primary lansoft-primary" disabled={!this.isValid()}>
                            <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Feltöltés
                        </button>

                        <button className="btn btn-secondary" onClick={this.handleCancelInvoiceClick}>
                            <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private toggleStatementContractNumberTooltipId = (id: number) => {
        this.setState({
            statementContractNumberTooltipOpenId: id === this.state.statementContractNumberTooltipOpenId ? NaN : id
        });
    }

    private handleSendStatementNotificationButtonClick = () => {
        this.props.sendStatementNotification();
    }

    private handleCopierDeviceExpandClick = () => {
        this.setState({
            copierDeviceModelExpanded: !this.state.copierDeviceModelExpanded
        });
    }

    private handleCreateStatementClick = () => {
        const payload: CopierCounterStatementCreatePayload = {
            blackAndWhiteCounterValue: this.state.blackAndWhiteCounter,
            colorCounterValue: this.state.colorCounter
        };

        this.props.createStatement(payload);

        this.resetStatementForm();
    }

    private handleBlackAndWhiteCounterChange = (e: any) => {
        this.setState({
            blackAndWhiteCounter: parseInt(e.target.value.replace(/\D/, ""))
        }, () => this.validateCounterStatement(true));
    }

    private handleColorCounterChange = (e: any) => {
        this.setState({
            colorCounter: parseInt(e.target.value.replace(/\D/, ""))
        }, () => this.validateCounterStatement(true));
    }

    private handleDownloadInvoiceClick = (statementId: number) => {
        window.location.href = `/api/copier-contract/${this.props.contract.id}/statement/${statementId}/invoice`;
    }

    private handleDownloadStatementHistoryExportClick = (contractId: number) => {
        window.location.href = `/api/excel/copier-contract/${contractId}/statement-history`;
    }

    private resetInvoiceForm = () => {
        this.setState({
            invoiceFile: null
        });
    }

    private resetStatementForm = () => {
        this.setState({
            blackAndWhiteCounter: this.props.contract.suggestedNextBlackAndWhiteCounter,
            colorCounter: this.props.contract.suggestedNextColorCounter
        });
    }

    private handleCancelInvoiceClick = () => {
        this.resetInvoiceForm();
        this.setState({
            invoiceModalStatementId: NaN
        });
    }

    private handleUploadInvoiceClick = () => {
        this.props.createInvoice(this.state.invoiceFile!!);
        this.toggleInvoiceModal();
        this.resetInvoiceForm();
    }

    private handleInvoiceFileChange = (e: any) => {
        this.setState({
            invoiceFile: e.target.files[0]
        }, () => this.validate(true));
    }

    private toggleInvoiceModal = (statementId: number = NaN) => {
        this.setState({
            invoiceModalStatementId: statementId,
            validationErrors: []
        });
    }

    private toggleMemoTooltip = () => {
        this.setState({
            memoTooltipOpen: !this.state.memoTooltipOpen
        });
    }

    private toggleSendEmailButtonTooltip = () => {
        this.setState({
            sendEmailButtonTooltipOpen: !this.state.sendEmailButtonTooltipOpen
        });
    }

    private toggleSendStatementButtonTooltip = () => {
        this.setState({
            sendStatementButtonTooltipOpen: !this.state.sendStatementButtonTooltipOpen
        });
    }

    private toggleDeviceSerialNumberTooltip = () => {
        this.setState({
            deviceSerialNumberTooltipOpen: !this.state.deviceSerialNumberTooltipOpen
        });
    }

    private toggleDeviceModelTooltip = () => {
        this.setState({
            deviceModelTooltipOpen: !this.state.deviceModelTooltipOpen
        });
    }

    private toggleContractTypeTooltip = () => {
        this.setState({
            contractTypeTooltipOpen: !this.state.contractTypeTooltipOpen
        });
    }

    private toggleContractNumberTooltip = () => {
        this.setState({
            contractNumberTooltipOpen: !this.state.contractNumberTooltipOpen
        });
    }

    private toggleTicketIndicatorTooltip = () => {
        this.setState({
            ticketIndicatorTooltipOpen: !this.state.ticketIndicatorTooltipOpen
        });
    }

    private handleExpandClick = (value?: boolean) => {
        if (this.props.isExpanded !== value) {
            this.props.expandCollapse();
        }
    }

    private isValid = () => {
        return this.validate();
    }

    private validate = (shouldSetState: boolean = false) => {
        const validationErrors: string[] = [];

        if (this.state.invoiceFile == null) {
            validationErrors.push("A fájl megadása kötelező.");
        }

        if (shouldSetState) {
            this.setState({
                validationErrors: validationErrors
            });
        }

        return validationErrors.length === 0;
    }

    private isValidCounterStatement = () => {
        return this.validateCounterStatement();
    }

    private validateCounterStatement = (shouldSetState: boolean = false) => {
        const validationErrors: string[] = [];

        const previousBlackAndWhiteCounter = this.props.contract.statements.length === 0 ? this.props.contract.blackAndWhiteCounter : this.props.contract.statements[0].blackAndWhiteCounterValue;
        const previousColorCounter = this.props.contract.statements.length === 0 ? this.props.contract.colorCounter : this.props.contract.statements[0].colorCounterValue;

        if (isNaN(this.state.blackAndWhiteCounter) || this.state.blackAndWhiteCounter < previousBlackAndWhiteCounter) {
            validationErrors.push("A fekete számláló értéke nem lehet kisebb a legutóbbinál.");
        }

        if (this.props.contract.device.model.color && (this.state.colorCounter == null || this.state.colorCounter < previousColorCounter!! || isNaN(this.state.colorCounter))) {
            validationErrors.push("A színes számláló értéke nem lehet kisebb a legutóbbinál.");
        }

        if (shouldSetState) {
            this.setState({
                validationErrorsCounterStatement: validationErrors
            });
        }

        return validationErrors.length === 0;
    }
}

export default CopierCounterStatementCard;

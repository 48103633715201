import { CopierContractType, ItContractType, CopierCounterStatement, CopierSupportTicket, HistoryItemType, CopierTicketState, ItSupportTicket, ItTicketState } from "../models";

export function getFriendlyNameFromCopierContractType(contractType: CopierContractType): string {
    switch (contractType) {
        case CopierContractType.AllRoundOperation:
            return "TÜSZ";
        case CopierContractType.Lease:
            return "Bérlet";
        case CopierContractType.Monthly:
            return "G";
        default:
            return "";
    }
}
export function getFriendlyNameFromItContractType(contractType: ItContractType): string {
    switch (contractType) {
        case ItContractType.Administrator:
            return "Rendszergazdai";
        case ItContractType.Networking:
            return "Hálózati";
        case ItContractType.OnlyHardware:
            return "Csak hardveres";
        default:
            return "";
    }
}

export function getIndicatorClassFromHistoryItemType(type: HistoryItemType) {
    switch (type) {
        case HistoryItemType.CounterStatement:
            return "lansoft-primary";
        case HistoryItemType.OpenTicket:
            return "lansoft-danger";
        case HistoryItemType.SolvedTicket:
            return "lansoft-success";
        case HistoryItemType.SuspendedTicket:
            return "secondary";
        default:
            return "";
    }
}

export function getIndicatorClassFromStatement(counterStatement: CopierCounterStatement) {
    if (counterStatement.id === 0) {
        return "lansoft-danger";
    }

    if (counterStatement.invoice == null) {
        return "lansoft-primary";
    }

    return "lansoft-success";
}

export function getTooltipTextFromStatement(counterStatement: CopierCounterStatement) {
    if (counterStatement.id === 0) {
        return "Nem bejelentett";
    }

    if (counterStatement.invoice == null) {
        return "Bejelentett";
    }

    return "Kiszámlázott";
}

export function getIndicatorClassFromTicket(ticket: CopierSupportTicket) {
    switch (ticket.state) {
        case CopierTicketState.Open:
            return "lansoft-danger";
        case CopierTicketState.Solved:
            return "lansoft-success";
        case CopierTicketState.Suspended:
            return "secondary";
        default:
            return "";
    }
}

export function getIndicatorClassFromItTicket(ticket: ItSupportTicket) {
    switch (ticket.state) {
        case ItTicketState.Open:
            return "lansoft-danger";
        case ItTicketState.Solved:
            return "lansoft-success";
        case ItTicketState.Suspended:
            return "secondary";
        default:
            return "";
    }
}

export function getTooltipTextFromTicket(ticket: CopierSupportTicket) {
    switch (ticket.state) {
        case CopierTicketState.Open:
            return "Aktív";
        case CopierTicketState.Solved:
            return "Megoldott";
        case CopierTicketState.Suspended:
            return "Felfüggesztett";
        default:
            return "";
    }
}

export function getTooltipTextFromItTicket(ticket: ItSupportTicket) {
    switch (ticket.state) {
        case ItTicketState.Open:
            return "Aktív";
        case ItTicketState.Solved:
            return "Megoldott";
        case ItTicketState.Suspended:
            return "Felfüggesztett";
        default:
            return "";
    }
}

export function getTooltipTextFromHistoryItemType(type: HistoryItemType) {
    switch (type) {
        case HistoryItemType.CounterStatement:
            return "Számláló bejelentés";
        case HistoryItemType.OpenTicket:
            return "Aktív hiba";
        case HistoryItemType.SolvedTicket:
            return "Megoldott hiba";
        case HistoryItemType.SuspendedTicket:
            return "Felfüggesztett hiba";
        default:
            return "";
    }
}

export function getMonthNameFromMonthNumber(month: number) {
    switch (month) {
        case 1:
            return "január";
        case 2:
            return "február";
        case 3:
            return "március";
        case 4:
            return "április";
        case 5:
            return "május";
        case 6:
            return "június";
        case 7:
            return "július";
        case 8:
            return "augusztus";
        case 9:
            return "szeptember";
        case 10:
            return "október";
        case 11:
            return "november";
        case 12:
            return "december";
        default:
            return "";
    }
}

export function getEnumValues<TEnum, TKeys extends string>(e: { [key in TKeys]: TEnum }): TEnum[] {
    let keys = Object.keys(e) as Array<TKeys>;
    keys = keys.filter(key => isNaN(+key));
    return keys.map(key => e[key]);
}

import * as React from 'react';
import { ItContract } from "../models";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { getFriendlyNameFromItContractType, getItContractDeviceCount } from '../utils';
import moment from 'moment';

interface ItContractDetailsModalProps {
    open: boolean;
    contract: ItContract | null;
    close: () => void;
}

export const ItContractDetailsModal = (props: ItContractDetailsModalProps) => (
    <Modal isOpen={props.open} toggle={props.close} className="modal-dialog-centered">
        <ModalHeader className="lansoft-success" toggle={props.close}>Informatikai szerződés részletei</ModalHeader>
        <ModalBody>
            {props.contract != null && <React.Fragment>
                <div>
                    <label className="h6">Szerződésszám</label>
                    <div>{props.contract.contractNumber}</div>
                </div>
                <div className="mt-3">
                    <label className="h6">Ügyfél neve</label>
                    <div>{props.contract.client.name}</div>
                </div>
                <div className="mt-3">
                    <label className="h6">Ügyfél címe</label>
                    <div>{props.contract.client.address}</div>
                </div>
                {props.contract.client.contactPhoneNumber != null && props.contract.client.contactPhoneNumber.length > 0 && <div className="mt-3">
                    <label className="h6">Ügyfél kapcsolati telefonszáma</label>
                    <div>{props.contract.client.contactPhoneNumber}</div>
                </div>}
                <div className="mt-3">
                    <label className="h6">Technikus neve</label>
                    <div>{props.contract.technician.name}</div>
                </div>
                <div className="mt-3">
                    <label className="h6">Szerződés típusa</label>
                    <div>{getFriendlyNameFromItContractType(props.contract.contractType)}</div>
                </div>
                <div className="mt-3">
                    <label className="h6">Megjelenés gyakorisága</label>
                    <div>{props.contract.maintenanceIntervalMonths == null ? `W/${props.contract.maintenanceIntervalWeeks}` : `G/${props.contract.maintenanceIntervalMonths}`}</div>
                </div>
                <div className="mt-3">
                    <label className="h6">Eszközök száma</label>
                    <div>{getItContractDeviceCount(props.contract)} eszköz</div>
                </div>
                <div className="mt-3">
                    <label className="h6">Memo</label>
                    <div>{props.contract.memo}</div>
                </div>
                <div className="mt-3">
                    <label className="h6">PC-k száma</label>
                    <div>{props.contract.pcCount}</div>
                </div>
                <div className="mt-3">
                    <label className="h6">Notebookok száma</label>
                    <div>{props.contract.notebookCount}</div>
                </div>
                <div className="mt-3">
                    <label className="h6">Hálózati eszközök száma</label>
                    <div>{props.contract.networkingDeviceCount}</div>
                </div>
                <div className="mt-3">
                    <label className="h6">Szerverek száma</label>
                    <div>{props.contract.serverCount}</div>
                </div>
                <div className="mt-3">
                    <label className="h6">NAS-ok száma</label>
                    <div>{props.contract.nasCount}</div>
                </div>
                <div className="mt-3">
                    <label className="h6">Egyéb eszközök száma</label>
                    <div>{props.contract.miscellaneousCount}</div>
                </div>
                <div className="mt-3">
                    <label className="h6">Kezdő dátum</label>
                    <div>{moment(props.contract.startDate).format("YYYY.MM.DD")}</div>
                </div>
                <div className="mt-3">
                    <label className="h6">Határozatlan idejű szerződés</label>
                    <div>{props.contract.endDate == null ? "Igen" : "Nem"}</div>
                </div>
                {props.contract.endDate != null && <div className="mt-3">
                    <label className="h6">Lejárat</label>
                    <div>{moment(props.contract.endDate).format("YYYY.MM.DD")}</div>
                </div>}
            </React.Fragment>}
        </ModalBody>
    </Modal>
);

export default ItContractDetailsModal;

import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as LanSoftStore from '../store/LanSoftStore';
import { LoadingIndicator } from "./common";

type MainRedirectPropsType = LanSoftStore.LanSoftState
    & typeof LanSoftStore.actionCreators
    & RouteComponentProps<{}>;

class MainRedirect extends React.PureComponent<MainRedirectPropsType, {}> {
    public render() {
        const user = this.props.loggedInUser;

        if (user != null) {
            if (user.isAdmin) {
                return <Redirect to="/admin/technicians" />;
            } else if (user.isTechnician) {
                return <Redirect to="/admin/tickets" />;
            } else {
                return <Redirect to="/my" />;
            }
        } else {
            return (
                <React.Fragment>
                    <LoadingIndicator show={true} />
                </React.Fragment>
            );
        }
    }
}

export default connect(
    (state: ApplicationState) => state.lanSoft,
    LanSoftStore.actionCreators
)(MainRedirect as any);

import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CopierTicketType } from "../models";
import { Button } from "reactstrap";

interface CopierTicketTypeItemProps {
    className?: string;
    ticketType: CopierTicketType;
    editCopierTicketType?: () => void;
    deleteCopierTicketType?: () => void;
}

export const CopierTicketTypeItem = (props: CopierTicketTypeItemProps) => (
    <div className={`d-flex align-items-center justify-content-between flex-same-width-container ${(props.className != null ? props.className : "")}`}>
        <div>
            <div className="h5 mb-0">{props.ticketType.name}</div>
        </div>
        <div>
            <div className="d-flex justify-content-end">
                {props.editCopierTicketType != null && <Button color="primary" className="lansoft-primary" onClick={props.editCopierTicketType}><FontAwesomeIcon icon={["fas", "pencil-alt"]} /></Button>}
                {props.deleteCopierTicketType != null && <Button color="danger" className="ml-3 lansoft-danger" onClick={props.deleteCopierTicketType}><FontAwesomeIcon icon={["fas", "trash-alt"]} /></Button>}
            </div>
        </div>
    </div>
);

export default CopierTicketTypeItem;

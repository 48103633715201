import * as React from 'react';
import { MenuItem } from "../models";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "react-router-dom";

interface NavMenuItemProps {
    menuItem: MenuItem;
    isLast?: boolean;
    className?: string;
    backgroundColor?: string;
    mobile?: boolean;
    onClick?: () => void;
}

export const NavMenuItem = (props: NavMenuItemProps) => (
    <React.Fragment>
        <div onClick={() => { if (props.onClick != null) props.onClick(); }} className={props.className == null ? "" : props.className} style={{ backgroundColor: props.backgroundColor == null ? "transparent" : props.backgroundColor }} data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown">
            <Link className={`nav-link nav-menu-item-link d-flex ${props.mobile ? "w-100" : "flex-column"} align-items-center text-white`} to={props.menuItem.target || ""}>
                {props.menuItem.customIcon == null && <div className={`nav-menu-item-icon-container ${props.mobile ? " ml-1 mobile" : ""}`}><FontAwesomeIcon icon={["fas", props.menuItem.icon]} className={`${props.mobile && "ml-2"} fa-fw nav-menu-item-icon ${props.mobile ? " mobile" : ""}`} /></div>}
                {props.menuItem.customIcon != null && <div className={`nav-menu-item-icon-container ${props.mobile ? " ml-1 mobile" : ""}`}><img src={props.menuItem.customIcon} className={`${props.mobile && "ml-2"} nav-menu-custom-icon`} alt="nav-menu-custom-icon" /></div>}
                <span className={props.mobile ? "ml-3" : ""}>{props.menuItem.text}</span>
            </Link>
        </div>
        {!props.isLast && <span className={`navbar-vertical-divider ${props.mobile ? "mobile" : ""}`} />}
    </React.Fragment>
);

export default NavMenuItem;

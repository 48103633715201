import { CopierContract, CopierCounterStatement, CopierSupportTicket, CopierTicketKind, CopierTicketState } from "../models";

export function getCopierContractHistory(contract: CopierContract, allTickets: CopierSupportTicket[], allStatements: CopierCounterStatement[], allContracts: CopierContract[]) {
    const contractIds = allContracts.filter(x => x.device.id === contract.device.id).map(x => x.id);
    const tickets = allTickets.filter(x => contractIds.indexOf(x.contractId) > -1 && (x.kind !== CopierTicketKind.Scheduled || x.isSolved)).map(ticket => {
        return {
            date: new Date(ticket.createdAt),
            ticket: ticket,
            statement: null
        };
    });

    const statements = allStatements.filter(x => contractIds.indexOf(x.contractId) > -1).map(statement => {
        return {
            date: new Date(statement.createdAt),
            ticket: null,
            statement: statement
        }
    });

    let history: { date: Date, ticket: CopierSupportTicket | null, statement: CopierCounterStatement | null }[] = [];
    history = history.concat(tickets).concat(statements);
    history.sort((a, b) => b.date.valueOf() - a.date.valueOf());

    return history;
}

export function getActiveCopierTicketCount(copierContracts: CopierContract[]) {
    return copierContracts.reduce((a, b) => (a + b.tickets.filter(x => x.kind === CopierTicketKind.Submitted && x.state === CopierTicketState.Open).length), 0);
}

import { ProtectedRestApi } from "./";
import { DataImportResult, ServiceResult } from "../models";
import { PrintVolumesExportPayload } from "../payloads";

export class ExcelApi extends ProtectedRestApi {
    private static baseUrl = "/api/excel";

    public importData(file: File): Promise<ServiceResult<DataImportResult>> {
        const formData = new FormData();
        formData.append('file', file);

        return this.httpPutForm(`${ExcelApi.baseUrl}/import`, formData) as Promise<ServiceResult<DataImportResult>>;
    }

    public getPrintVolumesExport(payload: PrintVolumesExportPayload): Promise<Blob> {
        return this.httpPostWithBlobResponse(`${ExcelApi.baseUrl}/print-volumes`, payload) as Promise<Blob>;
    }
}

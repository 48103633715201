import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Technician } from "../models";
import { Button } from "reactstrap";

interface TechnicianItemProps {
    className?: string;
    technician: Technician;
    editTechnician?: () => void;
    deleteTechnician?: () => void;
    viewTechnician?: () => void;
}

export const TechnicianItem = (props: TechnicianItemProps) => (
    <div className={`d-flex align-items-center justify-content-between${(props.className != null ? props.className : "")}`}>
        <div className="h5 mb-0">{props.technician.name}</div>
        <div>
            {props.viewTechnician != null && <Button color="success" className="lansoft-success" onClick={props.viewTechnician}><FontAwesomeIcon icon={["fas", "eye"]} /></Button>}
            {props.editTechnician != null && <Button color="primary" className="ml-3 lansoft-primary" onClick={props.editTechnician}><FontAwesomeIcon icon={["fas", "pencil-alt"]} /></Button>}
            {props.deleteTechnician != null && <Button color="danger" className="ml-3 lansoft-danger" onClick={props.deleteTechnician}><FontAwesomeIcon icon={["fas", "trash-alt"]} /></Button>}
        </div>
    </div>
);

export default TechnicianItem;

import * as React from 'react';
import { Client } from "../models";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

interface ClientDetailsModalProps {
    open: boolean;
    client: Client | null;
    close: () => void;
}

export const ClientDetailsModal = (props: ClientDetailsModalProps) => (
    <Modal isOpen={props.open} toggle={props.close} className="modal-dialog-centered">
        <ModalHeader className="lansoft-success" toggle={props.close}>Ügyfél részletei</ModalHeader>
        <ModalBody>
            {props.client != null && <React.Fragment>
                <div>
                    <label className="h6">Név</label>
                    <div>{props.client.name}</div>
                </div>
                <div className="mt-3">
                    <label className="h6">Cím</label>
                    <div>{props.client.address}</div>
                </div>
                {props.client.contactPhoneNumber != null && props.client.contactPhoneNumber.length > 0 && <div className="mt-3">
                    <label className="h6">Kapcsolati telefonszám</label>
                    <div>{props.client.contactPhoneNumber}</div>
                </div>}
                {props.client.users.length > 0 && <div className="mt-3">
                    <label className="h6">Felhasználó 1 e-mail címe</label>
                    <div>{props.client.users[0].email}</div>
                </div>}
                {props.client.users.length > 1 && <div className="mt-3">
                    <label className="h6">Felhasználó 2 e-mail címe</label>
                    <div>{props.client.users[1].email}</div>
                </div>}
            </React.Fragment>}
        </ModalBody>
    </Modal>
);

export default ClientDetailsModal;

import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as LanSoftStore from '../store/LanSoftStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { LoadingIndicator, ToastContainer } from "./common";
import { CopierTicketTypeCreatePayload, CopierTicketTypeUpdatePayload } from "../payloads";
import CopierTicketTypeItem from "./CopierTicketTypeItem";

type CopierTicketTypesPropsType = LanSoftStore.LanSoftState
    & typeof LanSoftStore.actionCreators
    & RouteComponentProps<{}>;

interface CopierTicketTypesState {
    result: boolean | null;
    toastOpen: boolean;
    editIndex: number;
    deleteIndex: number;
    validationErrors: string[];
    copierTicketTypeId: number;
    copierTicketType: string;
}

class CopierTicketTypes extends React.PureComponent<CopierTicketTypesPropsType, CopierTicketTypesState> {
    constructor(props: CopierTicketTypesPropsType) {
        super(props);

        this.state = {
            result: null,
            toastOpen: false,
            editIndex: NaN,
            deleteIndex: NaN,
            validationErrors: [],
            copierTicketTypeId: 0,
            copierTicketType: ""
        }
    }

    componentDidMount() {
        this.props.getAllCopierTicketTypes();
    }

    UNSAFE_componentWillReceiveProps(nextProps: CopierTicketTypesPropsType) {
        if (nextProps.result !== this.props.result && nextProps.result != null) {
            this.setState({
                result: nextProps.result,
                toastOpen: true
            });

            window.setTimeout(() => {
                this.setState({
                    toastOpen: false
                }, () => {
                    this.props.clearResult();
                });
            }, 3000);
        }
    }

    public render() {
        return (
            <React.Fragment>
                <LoadingIndicator show={this.props.loading > 0} />
                <h4 className="mb-3"><FontAwesomeIcon icon={["fas", "times"]} className="lansoft-text-success" /> <span className="lansoft-underline-success">Másolástechnikai hibatípusok {`(${this.props.copierTicketTypes.length})`}</span></h4>

                <div className="d-flex justify-content-end">
                    <Button onClick={this.handleCreateCopierTicketTypeClick} color="success" className="lansoft-success">
                        <FontAwesomeIcon icon={["fas", "plus"]} /> Új hibatípus
                    </Button>
                </div>

                {this.props.copierTicketTypes.length === 0 &&
                    <div className="d-flex justify-content-center text-white font-italic">
                        <span>Nem található hibatípus.</span>
                    </div>
                }

                <ul className="list-group mt-3 diagonal-background-flat diagonal-background-lansoft-primary position-relative no-left-border-radius">
                    {this.props.copierTicketTypes.map((ticketType, index) =>
                        <li className="list-group-item" key={`copier-ticket-type-list-item-${index}`}>
                            <CopierTicketTypeItem
                                ticketType={ticketType}
                                editCopierTicketType={() => this.handleEditCopierTicketTypeClick(index)}
                                deleteCopierTicketType={() => this.handleDeleteCopierTicketTypeClick(index)}
                            />
                        </li>
                    )}
                </ul>

                {this.getEditModal()}
                {this.getDeleteModal()}
                <ToastContainer open={this.state.toastOpen} color={this.state.result ? "success" : "danger"} success={this.state.result === true} />
            </React.Fragment>

        );
    }

    private getEditModal = () => {
        return (
            <Modal isOpen={!isNaN(this.state.editIndex) && !this.props.loading && this.props.copierTicketTypes.length > this.state.editIndex} toggle={this.closeEditModal} className="modal-dialog-centered">
                <ModalHeader className="lansoft-success" toggle={this.closeEditModal}>Másolástechnikai hibatípus {this.state.editIndex === -1 ? "létrehozása" : "szerkesztése"}</ModalHeader>
                <ModalBody>
                    <div className="form-group">
                        <label htmlFor="inputCopierTicketType">Típus</label>
                        <input type="text" className="form-control" id="inputCopierTicketType" autoComplete="off" placeholder="Típus" value={this.state.copierTicketType} onChange={this.handleCopierTicketTypeChange} />
                    </div>

                    {this.state.validationErrors.length > 0 &&
                        <Card className="mt-2 mb-2 p-2 bg-danger lansoft-danger text-white">
                            {this.state.validationErrors.map((error, index) => {
                                return (
                                    <div key={`copierModel-form-validation-error-${index}`} className="form-invalid">
                                        {error}
                                    </div>
                                );
                            })}
                        </Card>
                    }

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleSaveCopierTicketTypeClick} type="button" className="btn btn-primary lansoft-primary" disabled={!this.isValid()}>
                            <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Mentés
                        </button>

                        <button className="btn btn-secondary" onClick={this.handleCancelEditClick}>
                            <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private getDeleteModal = () => {
        return (
            <Modal isOpen={!isNaN(this.state.deleteIndex) && !this.props.loading} toggle={this.closeDeleteModal} className="modal-dialog-centered">
                <ModalHeader className="lansoft-success" toggle={this.closeDeleteModal}>Másolástechnikai hibatípus törlése</ModalHeader>
                <ModalBody>
                    <div>
                        <p>
                            Biztosan törölni szeretnéd az alábbi hibatípust?
                        </p>
                        <p>{!isNaN(this.state.deleteIndex) && <strong>{this.props.copierTicketTypes[this.state.deleteIndex].name}</strong>}</p>
                    </div>

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleConfirmDeleteCopierTicketTypeClick} type="button" className="btn btn-danger lansoft-danger">
                            <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Törlés
                        </button>

                        <button className="btn btn-secondary" onClick={this.handleCancelDeleteClick}>
                            <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private resetForm = () => {
        this.setState({
            copierTicketTypeId: 0,
            copierTicketType: "",
            editIndex: NaN,
            validationErrors: []
        });
    }

    private handleCancelEditClick = () => {
        this.setState({
            editIndex: NaN
        });
    }

    private handleCancelDeleteClick = () => {
        this.setState({
            deleteIndex: NaN
        });
    }

    private handleSaveCopierTicketTypeClick = () => {
        if (this.state.copierTicketTypeId === 0) {
            const payload: CopierTicketTypeCreatePayload = {
                name: this.state.copierTicketType
            };

            this.props.createCopierTicketType(payload);
        } else {
            const payload: CopierTicketTypeUpdatePayload = {
                name: this.state.copierTicketType
            };

            this.props.updateCopierTicketType(this.state.copierTicketTypeId, payload);
        }

        this.setState({
            editIndex: NaN
        });
    }

    private handleConfirmDeleteCopierTicketTypeClick = () => {
        const ticketType = this.props.copierTicketTypes[this.state.deleteIndex];
        this.props.deleteCopierTicketType(ticketType.id);
        this.setState({
            deleteIndex: NaN
        });
    }

    private handleCopierTicketTypeChange = (e: any) => {
        this.setState({
            copierTicketType: e.target.value
        }, () => { this.validate(true); });
    }

    private closeEditModal = () => {
        this.setState({
            editIndex: NaN
        });
    }

    private closeDeleteModal = () => {
        this.setState({
            deleteIndex: NaN
        });
    }

    private handleCreateCopierTicketTypeClick = () => {
        this.resetForm();
        this.setState({
            editIndex: -1,
            copierTicketTypeId: 0,
            copierTicketType: ""
        });
    }

    private handleEditCopierTicketTypeClick = (index: number) => {
        this.resetForm();
        const copierTicketType = this.props.copierTicketTypes[index];
        this.setState({
            editIndex: index,
            copierTicketTypeId: copierTicketType.id,
            copierTicketType: copierTicketType.name
        }, () => this.validate(true));
    }

    private handleDeleteCopierTicketTypeClick = (index: number) => {
        this.setState({
            deleteIndex: index
        });
    }

    private isValid = () => {
        return this.validate();
    }

    private validate = (shouldSetState: boolean = false) => {
        const validationErrors: string[] = [];

        if (this.state.copierTicketType.length === 0) {
            validationErrors.push("A típus megadása kötelező.");
        }

        if (shouldSetState) {
            this.setState({
                validationErrors: validationErrors
            });
        }

        return validationErrors.length === 0;
    }
}

export default connect(
    (state: ApplicationState) => state.lanSoft,
    LanSoftStore.actionCreators
)(CopierTicketTypes as any);

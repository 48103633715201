import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, CardBody, CardTitle, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Tooltip } from 'reactstrap';
import { CopierContract, CopierContractType, CopierCounterStatement, CopierSupportTicket, CopierTicketSolutionType, CopierTicketState, HistoryItemType } from "../models";
import { getFriendlyNameFromCopierContractType, getIndicatorClassFromHistoryItemType, getIndicatorClassFromTicket, getTooltipTextFromHistoryItemType, getTooltipTextFromTicket } from "../utils/EnumUtils";
import moment from 'moment';
import CopierSupportTicketModal from "./CopierSupportTicketModal";
import { CopierSupportTicketSolvePayload } from '../payloads';
import { getCopierContractHistory, getNumberInputValue, MAX_DEVICE_NAME_LENGTH_DISPLAY } from '../utils';
import { isMobileOnly } from "react-device-detect";
import Datetime from 'react-datetime';

interface CopierSupportTicketCardPropsType {
    isExpanded: boolean;
    ticket: CopierSupportTicket;
    contract: CopierContract;
    expandCollapse: () => void;
    ticketSolutionTypes: CopierTicketSolutionType[];
    handleSolveClick: (payload: CopierSupportTicketSolvePayload) => void;
    tickets: CopierSupportTicket[];
    statements: CopierCounterStatement[];
    contracts: CopierContract[];
}

interface CopierSupportTicketCardState {
    ticketSolutionTypeDropdownOpen: boolean;
    ticketSolutionTypeId: number;
    messageToClient: string;
    proposedState: CopierTicketState | null;
    solvedAt: Date;
    blackAndWhiteCounterValue: number;
    colorCounterValue: number | null;
    validationErrors: string[];
    ticketIndicatorTooltipOpen: boolean;
    contractNumberTooltipOpen: boolean;
    contractTypeTooltipOpen: boolean;
    deviceModelTooltipOpen: boolean;
    deviceSerialNumberTooltipOpen: boolean;
    memoTooltipOpen: boolean;
    historyItemIndicatorTooltipOpen: number;
    ticketHistoryItem: CopierSupportTicket | null;
    ticketHistoryItemContractNumber: string;
    ticketHistoryItemModalOpen: boolean;
    copierDeviceModelExpanded: boolean;
    historyItemContractNumberTooltipOpenIndex: number;
}

export class CopierSupportTicketCard extends React.PureComponent<CopierSupportTicketCardPropsType, CopierSupportTicketCardState> {
    constructor(props: CopierSupportTicketCardPropsType) {
        super(props);

        this.state = {
            ticketSolutionTypeDropdownOpen: false,
            ticketSolutionTypeId: props.ticket.solutionType != null ? props.ticket.solutionType.id : 0,
            messageToClient: props.ticket.messageToClient || "",
            proposedState: props.ticket.state === CopierTicketState.Open ? CopierTicketState.Solved : props.ticket.state,
            solvedAt: props.ticket.solvedAt != null ? new Date(props.ticket.solvedAt) : new Date(),
            blackAndWhiteCounterValue: props.ticket.blackAndWhiteCounterValue,
            colorCounterValue: props.ticket.colorCounterValue,
            validationErrors: [],
            ticketIndicatorTooltipOpen: false,
            contractNumberTooltipOpen: false,
            contractTypeTooltipOpen: false,
            deviceModelTooltipOpen: false,
            deviceSerialNumberTooltipOpen: false,
            memoTooltipOpen: false,
            historyItemIndicatorTooltipOpen: NaN,
            ticketHistoryItem: null,
            ticketHistoryItemContractNumber: "",
            ticketHistoryItemModalOpen: false,
            copierDeviceModelExpanded: false,
            historyItemContractNumberTooltipOpenIndex: NaN
        }
    }

    public render() {
        const deviceNameLong = this.props.contract.device.model.name.length > MAX_DEVICE_NAME_LENGTH_DISPLAY;
        const history = getCopierContractHistory(this.props.contract, this.props.tickets, this.props.statements, this.props.contracts);
        const addressSecondSpaceIndex = this.props.contract.client.address.indexOf(" ", this.props.contract.client.address.indexOf(" ") + 1);
        const firstAddressChunk = addressSecondSpaceIndex >= 0 ? this.props.contract.client.address.substr(0, addressSecondSpaceIndex).trim() : this.props.contract.client.address.substr(addressSecondSpaceIndex + 1).trim();
        let secondAddressChunk = "";
        if (addressSecondSpaceIndex >= 0) {
            secondAddressChunk = this.props.contract.client.address.substr(addressSecondSpaceIndex + 1).trim();
        }

        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <CardTitle className="mb-0">
                            {isMobileOnly &&
                                <div className="h4 d-flex justify-content-between pb-1 mobile row border-bottom lansoft-border-success lansoft-border-full">
                                    <div />
                                    <div className="d-flex align-items-center mb-3">
                                        <div id={`scheduled-scheduled-ticket-indicator-${this.props.ticket.id}`} className={`mr-1 ticket-indicator bg-${getIndicatorClassFromTicket(this.props.ticket)}`}></div>
                                        <Tooltip placement="top" isOpen={this.state.ticketIndicatorTooltipOpen} target={`scheduled-scheduled-ticket-indicator-${this.props.ticket.id}`} toggle={this.toggleTicketIndicatorTooltip}>
                                            {getTooltipTextFromTicket(this.props.ticket)}
                                        </Tooltip>
                                        <span id={`scheduled-contract-number-${this.props.ticket.id}`}>{this.props.contract.contractNumber}</span>
                                        <Tooltip placement="top" isOpen={this.state.contractNumberTooltipOpen} target={`scheduled-contract-number-${this.props.ticket.id}`} toggle={this.toggleContractNumberTooltip}>
                                            Szerződésszám
                                        </Tooltip>
                                    </div>
                                    <div className="cursor-pointer mr-3" onClick={() => this.handleExpandClick()}><FontAwesomeIcon icon={["fas", this.props.isExpanded ? "chevron-up" : "chevron-down"]} className="mr-1" /></div>
                                </div>
                            }
                            <div className={`h4 d-flex justify-content-between flex-same-width-container ${isMobileOnly ? "mobile row pt-3" : "mt-4 mb-4 pb-4"}`}>
                                {!isMobileOnly && <div className={isMobileOnly ? "col-6" : ""}>
                                    <div className={`d-flex align-items-center ${isMobileOnly ? "mb-3" : ""}`}>
                                        <div id={`scheduled-scheduled-ticket-indicator-${this.props.ticket.id}`} className={`mr-1 ticket-indicator bg-${getIndicatorClassFromTicket(this.props.ticket)}`}></div>
                                        <Tooltip placement="top" isOpen={this.state.ticketIndicatorTooltipOpen} target={`scheduled-scheduled-ticket-indicator-${this.props.ticket.id}`} toggle={this.toggleTicketIndicatorTooltip}>
                                            {getTooltipTextFromTicket(this.props.ticket)}
                                        </Tooltip>
                                        <span id={`scheduled-contract-number-${this.props.ticket.id}`}>{this.props.contract.contractNumber}</span>
                                        <Tooltip placement="top" isOpen={this.state.contractNumberTooltipOpen} target={`scheduled-contract-number-${this.props.ticket.id}`} toggle={this.toggleContractNumberTooltip}>
                                            Szerződésszám
                                        </Tooltip>
                                    </div>
                                </div>}
                                <div className={isMobileOnly ? "col-6 mb-3 d-flex" : ""}>
                                    <div className={isMobileOnly ? "d-flex justify-content-end" : ""}>
                                        <div id={`scheduled-contract-type-${this.props.ticket.id}`} className="d-inline-block">
                                            {getFriendlyNameFromCopierContractType(this.props.contract.contractType)}{this.props.contract.contractType === CopierContractType.Monthly && `/${this.props.contract.maintenanceIntervalMonths}`}
                                        </div>
                                        <Tooltip placement="top" isOpen={this.state.contractTypeTooltipOpen} target={`scheduled-contract-type-${this.props.ticket.id}`} toggle={this.toggleContractTypeTooltip}>
                                            Szerződés típusa
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className={isMobileOnly ? "col-6 mb-3 d-flex" : ""}>
                                    <div onClick={this.handleCopierDeviceExpandClick} className="cursor-pointer">
                                        <div id={`ticket-device-model-${this.props.ticket.id}`} className="d-inline-block">
                                            {!deviceNameLong && <React.Fragment>{this.props.contract.device.model.name}</React.Fragment>}
                                            {deviceNameLong && <React.Fragment>
                                                {!this.state.copierDeviceModelExpanded && <React.Fragment>
                                                    {this.props.contract.device.model.name.substring(0, MAX_DEVICE_NAME_LENGTH_DISPLAY - 2) + "..."}
                                                </React.Fragment>}
                                                {this.state.copierDeviceModelExpanded && <React.Fragment>
                                                    {this.props.contract.device.model.name.split(" ").map((fragment, index) =>
                                                        <span className="nowrap d-block" key={`device-model-name-fragment-${index}`}>{fragment}</span>)
                                                    }
                                                </React.Fragment>}
                                            </React.Fragment>}
                                        </div>
                                        <Tooltip placement="top" isOpen={this.state.deviceModelTooltipOpen} target={`ticket-device-model-${this.props.ticket.id}`} toggle={this.toggleDeviceModelTooltip}>
                                            Gép típusa
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className={isMobileOnly ? "col-6 mb-3 d-flex" : ""}>
                                    <div className={isMobileOnly ? "text-right" : ""}>
                                        <div id={`scheduled-device-serial-number-${this.props.ticket.id}`} className="d-inline-block">
                                            {this.props.contract.device.serialNumber}
                                        </div>
                                        <Tooltip placement="top" isOpen={this.state.deviceSerialNumberTooltipOpen} target={`scheduled-device-serial-number-${this.props.ticket.id}`} toggle={this.toggleDeviceSerialNumberTooltip}>
                                            Gép gyári száma
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className={`flex-half-width-column ${isMobileOnly ? "mobile col" : ""}`}>
                                    <div className={`d-flex ${!isMobileOnly ? "justify-content-end" : ""}`}>
                                        <div id={`scheduled-memo-${this.props.ticket.id}`}>
                                            {this.props.contract.memo}
                                        </div>
                                        <Tooltip placement="top" isOpen={this.state.memoTooltipOpen} target={`scheduled-memo-${this.props.ticket.id}`} toggle={this.toggleMemoTooltip}>
                                            Memo
                                        </Tooltip>
                                        {!isMobileOnly && <div className="cursor-pointer ml-3" onClick={() => this.handleExpandClick()}><FontAwesomeIcon icon={["fas", this.props.isExpanded ? "chevron-up" : "chevron-down"]} className="mr-1" /></div>}
                                    </div>
                                </div>
                            </div>

                            <div className="mt-1 pt-1 border-top lansoft-border-success"></div>

                            {isMobileOnly && <div className="col-12 mb-2 mt-2 text-center">
                                <div className="h5 no-bottom-margin">
                                    Technikus: {this.props.contract.technician.name}
                                </div>
                            </div>}

                            <div className={`d-flex justify-content-between flex-same-width-container ${isMobileOnly ? "mobile row w-100 align-center-margin" : "mt-4 mb-4 pt-5"}`}>
                                <div className={isMobileOnly ? "col-12 mb-4 no-left-padding" : ""}>
                                    <div>
                                        <div className="h5 mb-3"><strong>{this.props.contract.client.name}</strong></div>
                                        <div className="h6 mb-3">
                                            {addressSecondSpaceIndex >= 0 ?
                                                <div>
                                                    <div>{firstAddressChunk}</div>
                                                    <div>{secondAddressChunk}</div>
                                                </div>
                                                :
                                                <div>
                                                    {this.props.contract.client.address}
                                                </div>
                                            }
                                        </div>
                                        <div className="h6">{this.props.contract.client.contactPhoneNumber}</div>
                                    </div>
                                </div>
                                <div className={isMobileOnly ? "col-6 mb-4 no-left-padding" : ""}>
                                    <div className="h5">
                                        <div className="mb-3">Kérés típusa:</div>
                                        <div><strong>{this.props.ticket.ticketType.name}</strong></div>
                                    </div>
                                </div>
                                <div className={isMobileOnly ? "col-6 no-left-padding" : ""}>
                                    <div>Fekete számláló: <strong>{this.props.ticket.blackAndWhiteCounterValue}</strong></div>
                                    {this.props.contract.device.model.color && <div>Színes számláló: <strong>{this.props.ticket.colorCounterValue}</strong></div>}
                                </div>
                                {!isMobileOnly &&
                                    <div>
                                        <div className="h5">
                                            <div className="d-flex">Technikus: {this.props.contract.technician.name}</div>
                                        </div>
                                    </div>
                                }
                                <div className="flex-half-width-column"></div>
                            </div>
                        </CardTitle>

                        {this.props.isExpanded && <React.Fragment>
                            <div className={`border-top lansoft-border-success pt-4 ${isMobileOnly ? "" : "mt-5"}`}>
                                <div className={`d-flex justify-content-between mt-3 flex-same-width-container border-bottom lansoft-border-success ${isMobileOnly ? "mobile row w-100 align-center-margin" : "pb-5"}`}>
                                    <div className={`form-group mr-3 ${isMobileOnly ? "col-12 no-left-padding" : ""}`}>
                                        <label>Megoldás</label>
                                        <Dropdown isOpen={this.state.ticketSolutionTypeDropdownOpen} toggle={this.toggleticketSolutionTypeDropdown}>
                                            <DropdownToggle className="lansoft-success" caret={!this.props.ticket.isSolved} disabled={this.props.ticket.isSolved}>
                                                {this.state.ticketSolutionTypeId === 0 ? "Válassz" : this.props.ticketSolutionTypes.find(x => x.id === this.state.ticketSolutionTypeId)!!.name}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {this.props.ticketSolutionTypes.map((solutionType, index) =>
                                                    <DropdownItem active={this.state.ticketSolutionTypeId === solutionType.id} onClick={() => this.handleTicketSolutionTypeChange(solutionType.id)} key={`copier-ticket-solution-type-dropdown-item-${index}`}>{solutionType.name}</DropdownItem>
                                                )}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                    <div className={`form-group flex-double-width-column ${isMobileOnly ? "col-12 no-left-padding" : ""}`}>
                                        <label>Ügyfél kérése</label>
                                        <div>
                                            <textarea rows={4} className="form-control message-textarea" value={this.props.ticket.messageToTechnician} disabled />
                                        </div>
                                    </div>
                                    <div className={`h5 ${isMobileOnly ? "col-12 no-left-padding" : ""}`}>
                                        <div className="mb-3">Készült: {moment(this.props.ticket.createdAt).format("YYYY.MM.DD HH:mm")}</div>
                                        <div className="form-group">
                                            <label htmlFor={`inputSolvedAt-${this.props.ticket.id}`}>Megoldva</label>
                                            <Datetime
                                                closeOnClickOutside={true}
                                                closeOnSelect={true}
                                                dateFormat="YYYY.MM.DD"
                                                timeFormat={false}
                                                utc={true}
                                                value={this.state.solvedAt}
                                                inputProps={{ disabled: this.props.ticket.solvedAt != null }}
                                                onChange={this.handleSolvedAtChange} />
                                        </div>
                                    </div>
                                    <div className="flex-half-width-column"></div>
                                </div>
                                <div className={`d-flex justify-content-between mt-5 flex-same-width-container border-bottom lansoft-border-success ${isMobileOnly ? "mobile row w-100 align-center-margin pt-3 pb-3" : ""}`}>
                                    <div className={isMobileOnly ? "col-12 mb-2 no-left-padding" : ""}>
                                        <div className="form-group">
                                            <label htmlFor={`inputBlackAndWhiteCounter-${this.props.ticket.id}`}>Fekete számláló</label>
                                            <input disabled={this.props.ticket.solvedAt != null} type="text" className="form-control numeric-input" id={`inputBlackAndWhiteCounter-${this.props.ticket.id}`} autoComplete="off" placeholder="Fekete számláló" value={getNumberInputValue(this.state.blackAndWhiteCounterValue)} onChange={this.handleBlackAndWhiteCounterChange} />
                                        </div>
                                        {this.props.contract.device.model.color && <div className="form-group mt-2">
                                            <label htmlFor={`inputColorCounter-${this.props.ticket.id}`}>Színes számláló</label>
                                            <input disabled={this.props.ticket.solvedAt != null} type="text" className="form-control numeric-input" id={`inputColorCounter-${this.props.ticket.id}`} autoComplete="off" placeholder="Színes számláló" value={getNumberInputValue(this.state.colorCounterValue || 0)} onChange={this.handleColorCounterChange} />
                                        </div>}
                                    </div>
                                    <div className={`flex-double-width-column ${isMobileOnly ? "col-12 mobile mb-2 no-left-padding" : ""}`}>
                                        <div className="d-flex justify-content-start">
                                            <div className="d-flex flex-column justify-content-center">
                                                <label className="mr-2 mb-0">Célállapot</label>
                                            </div>
                                            <Button color="success" className={`lansoft-success ticket-proposed-state ${this.state.proposedState === CopierTicketState.Solved ? "ticket-proposed-state-selected" : ""}`} outline={true} onClick={this.handleSolvedClick} disabled={this.props.ticket.state !== CopierTicketState.Open}>KÉSZ</Button>
                                            <Button color="success" className={`lansoft-success ticket-proposed-state ${this.state.proposedState === CopierTicketState.Suspended ? "ticket-proposed-state-selected" : ""}`} outline={true} onClick={this.handleSuspendedClick} disabled={this.props.ticket.state !== CopierTicketState.Open}>FELFÜGGESZTVE</Button>
                                        </div>
                                    </div>
                                    <div className={isMobileOnly ? "col-12 no-left-padding" : ""}>
                                        <div className="form-group">
                                            <label className="mr-2" htmlFor="inputNewTicketMessageToClient">Üzenet az ügyfélnek</label>
                                            <textarea maxLength={250} rows={4} className="form-control message-textarea" id="inputNewTicketMessageToClient" autoComplete="off" placeholder={this.props.ticket.state === CopierTicketState.Open ? "Üzenet" : undefined} value={this.state.messageToClient} onChange={this.handleMessageToClientChange} disabled={this.props.ticket.state !== CopierTicketState.Open} />
                                            {this.props.ticket.state === CopierTicketState.Open &&
                                                <div className="mt-4 d-flex justify-content-end">
                                                    <Button color="primary" className="lansoft-primary" onClick={this.handleSolveClick} disabled={!this.isValid()}>Elküld</Button>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="flex-half-width-column"></div>
                                </div>
                            </div>
                        </React.Fragment>}

                        {this.props.isExpanded && <div className={isMobileOnly ? "mt-2" : "mt-5"}>
                            <div className="h5">Előzmények</div>
                            {history.length === 0 && <div>Nem található elem.</div>}
                            {history.length > 0 &&
                                <React.Fragment>
                                    <table className="table history-table">
                                        <thead>
                                            <tr>
                                                <th scope="col"></th>
                                                <th scope="col">Dátum</th>
                                                <th scope="col">Fekete sz.</th>
                                                {this.props.contract.device.model.color && <th scope="col">Színes sz.</th>}
                                                {!isMobileOnly && <th scope="col">Ügyfél kérése</th>}
                                                {!isMobileOnly && <th scope="col">Megoldás</th>}
                                                <th />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {history.map((historyItem, index) => {
                                                const blackCounter = historyItem.statement == null ? historyItem.ticket!!.blackAndWhiteCounterValue : historyItem.statement.blackAndWhiteCounterValue;
                                                const colorCounter = historyItem.statement == null ? historyItem.ticket!!.colorCounterValue : historyItem.statement.colorCounterValue;
                                                const messageToTechnician = historyItem.ticket != null ? historyItem.ticket!!.messageToTechnician : "Számláló";
                                                const messageToClient = historyItem.ticket != null ? historyItem.ticket!!.messageToClient : "";

                                                const historyItemContract = this.props.contracts.find(x => x.id === (historyItem.statement == null ? historyItem.ticket!!.contractId : historyItem.statement.contractId));
                                                if (historyItemContract == null) {
                                                    return null;
                                                }

                                                const contractNumberTooltipTargetId = `copier-support-ticket-card-contract-number-icon-${index}`;

                                                return (
                                                    <tr key={`contract-history-row-${index}`} className={historyItem.ticket == null ? "" : "cursor-pointer"} onClick={() => { if (historyItem.ticket != null) this.onTicketHistoryItemClick(historyItem.ticket); }}>
                                                        <td>
                                                            <div id={`contract-history-item-indicator-${index}`} className={`contract-history-item-indicator bg-${getIndicatorClassFromHistoryItemType(historyItem.statement != null ? HistoryItemType.CounterStatement : (historyItem.ticket!!.isSolved ? HistoryItemType.SolvedTicket : HistoryItemType.OpenTicket))}`}></div>
                                                            <Tooltip placement="top" isOpen={this.state.historyItemIndicatorTooltipOpen === index} target={`contract-history-item-indicator-${index}`} toggle={() => this.toggleHistoryItemIndicatorTooltipOpen(index)}>
                                                                {getTooltipTextFromHistoryItemType(historyItem.ticket == null ? HistoryItemType.CounterStatement : (historyItem.ticket.isSolved ? (historyItem.ticket.state === CopierTicketState.Solved ? HistoryItemType.SolvedTicket : HistoryItemType.SuspendedTicket) : HistoryItemType.OpenTicket))}
                                                            </Tooltip>
                                                        </td>
                                                        <td>{moment(historyItem.date).format(isMobileOnly ? "MM.DD" : "YYYY.MM.DD HH:mm")}</td>
                                                        <td>{blackCounter}</td>
                                                        {this.props.contract.device.model.color && <td>{colorCounter}</td>}
                                                        {!isMobileOnly && <td>{messageToTechnician}</td>}
                                                        {!isMobileOnly && <td>{messageToClient}</td>}
                                                        {!isMobileOnly && <td className="history-table-contract-icon-column">
                                                            <div className="cursor-pointer d-flex align-items-center align-content-center justify-content-center" id={contractNumberTooltipTargetId}>
                                                                <FontAwesomeIcon icon={["fas", "file-contract"]} />
                                                            </div>
                                                            <Tooltip placement="left" isOpen={this.state.historyItemContractNumberTooltipOpenIndex === index} target={contractNumberTooltipTargetId} toggle={() => this.toggleStatementContractNumberTooltipIndex(index)}>
                                                                Szerződésszám: {historyItemContract.contractNumber}
                                                            </Tooltip>
                                                        </td>}
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    <div>
                                        <Button color="primary" className="lansoft-primary" onClick={() => this.handleDownloadHistoryExportClick(this.props.contract.id)}>Mentés Excelbe</Button>
                                    </div>
                                </React.Fragment>
                            }
                        </div>}
                    </CardBody>
                </Card>

                <CopierSupportTicketModal
                    ticket={this.state.ticketHistoryItem}
                    contractNumber={this.state.ticketHistoryItemContractNumber}
                    open={this.state.ticketHistoryItemModalOpen}
                    close={this.closeTicketHistoryItemModal}
                    color={this.props.contract.device.model.color}
                />
            </React.Fragment>

        );
    }

    private toggleStatementContractNumberTooltipIndex = (index: number) => {
        this.setState({
            historyItemContractNumberTooltipOpenIndex: index === this.state.historyItemContractNumberTooltipOpenIndex ? NaN : index
        });
    }

    private handleSolvedAtChange = (date: any) => {
        this.setState({
            solvedAt: moment.utc(date).startOf('day').toDate()
        });
    }

    private handleBlackAndWhiteCounterChange = (e: any) => {
        if (!isNaN(e.target.value)) {
            this.setState({
                blackAndWhiteCounterValue: parseInt(e.target.value.replace(/\D/, ""))
            });
        }
    }

    private handleColorCounterChange = (e: any) => {
        if (!isNaN(e.target.value)) {
            this.setState({
                colorCounterValue: parseInt(e.target.value.replace(/\D/, ""))
            });
        }
    }

    private handleDownloadHistoryExportClick = (contractId: number) => {
        window.location.href = `/api/excel/copier-contract/${contractId}/history`;
    }

    private handleCopierDeviceExpandClick = () => {
        this.setState({
            copierDeviceModelExpanded: !this.state.copierDeviceModelExpanded
        });
    }

    private onTicketHistoryItemClick = (ticket: CopierSupportTicket) => {
        const contract = this.props.contracts.find(x => x.id === ticket.contractId);
        this.setState({
            ticketHistoryItem: ticket,
            ticketHistoryItemContractNumber: contract == null ? "" : contract.contractNumber,
            ticketHistoryItemModalOpen: true
        });
    }

    private closeTicketHistoryItemModal = () => {
        this.setState({
            ticketHistoryItemModalOpen: false
        });
    }

    private toggleHistoryItemIndicatorTooltipOpen = (index: number) => {
        this.setState({
            historyItemIndicatorTooltipOpen: this.state.historyItemIndicatorTooltipOpen === index ? NaN : index
        });
    }

    private toggleMemoTooltip = () => {
        this.setState({
            memoTooltipOpen: !this.state.memoTooltipOpen
        });
    }

    private toggleDeviceSerialNumberTooltip = () => {
        this.setState({
            deviceSerialNumberTooltipOpen: !this.state.deviceSerialNumberTooltipOpen
        });
    }

    private toggleDeviceModelTooltip = () => {
        this.setState({
            deviceModelTooltipOpen: !this.state.deviceModelTooltipOpen
        });
    }

    private toggleContractTypeTooltip = () => {
        this.setState({
            contractTypeTooltipOpen: !this.state.contractTypeTooltipOpen
        });
    }

    private toggleContractNumberTooltip = () => {
        this.setState({
            contractNumberTooltipOpen: !this.state.contractNumberTooltipOpen
        });
    }

    private toggleTicketIndicatorTooltip = () => {
        this.setState({
            ticketIndicatorTooltipOpen: !this.state.ticketIndicatorTooltipOpen
        });
    }

    private handleSolvedClick = () => {
        this.setState({
            proposedState: CopierTicketState.Solved
        });
    }

    private handleSuspendedClick = () => {
        this.setState({
            proposedState: CopierTicketState.Suspended
        });
    }

    private handleSolveClick = () => {
        const payload = {
            ticketSolutionTypeId: this.state.ticketSolutionTypeId,
            proposedState: this.state.proposedState!!,
            messageToClient: this.state.messageToClient,
            solvedAt: this.state.solvedAt,
            blackAndWhiteCounterValue: this.state.blackAndWhiteCounterValue,
            colorCounterValue: this.state.colorCounterValue
        };

        this.props.handleSolveClick(payload);
    }

    private handleMessageToClientChange = (e: any) => {
        this.setState({
            messageToClient: e.target.value
        });
    }

    private handleTicketSolutionTypeChange = (id: number) => {
        this.setState({
            ticketSolutionTypeId: id
        });
    }

    private toggleticketSolutionTypeDropdown = () => {
        this.setState({
            ticketSolutionTypeDropdownOpen: !this.state.ticketSolutionTypeDropdownOpen
        });
    }

    private handleExpandClick = () => {
        this.props.expandCollapse();
    }

    private isValid = () => {
        return this.validate();
    }

    private validate = (shouldSetState: boolean = false) => {
        const validationErrors: string[] = [];

        if (this.state.ticketSolutionTypeId === 0) {
            validationErrors.push("A megoldás kiválasztása kötelező.");
        }

        if (this.state.proposedState == null) {
            validationErrors.push("A célállapot kiválasztása kötelező.");
        }

        if (shouldSetState) {
            this.setState({
                validationErrors: validationErrors
            });
        }

        return validationErrors.length === 0;
    }
}

export default CopierSupportTicketCard;

import * as React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Technician } from "../models";

interface TechnicianInfoProps {
    technician: Technician;
    activeTicketCount: number;
    scheduledTicketCount: number;
}

export const TechnicianInfo = (props: TechnicianInfoProps) => (
    <div className={`container ${isMobileOnly ? "mb-5" : "mb-3"}`}>
        <div className={`d-flex ${isMobileOnly ? "slant-container justify-content-between" : "align-items-center justify-content-between"}`}>
            <div className={`h5 ${isMobileOnly ? "technician-info-name mobile left-rectangle d-flex align-items-center no-bottom-margin" : ""}`}>Technikus: {props.technician.name}</div>
            {isMobileOnly &&
                <div className="triangle-container">
                    <div className="left-triangle">
                    </div>
                    <div className="right-triangle">
                    </div>
                </div>
            }
            <div className={`${isMobileOnly ? "text-right right-rectangle d-flex flex-column justify-content-center" : "p-3 border lansoft-border-success"}`}>
                <div>Aktív hibajegyek: {props.activeTicketCount}</div>
                <div>Ütemezett karbantartás: {props.scheduledTicketCount}</div>
            </div>
        </div>
    </div>
);

export default TechnicianInfo;

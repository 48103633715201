import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as LanSoftStore from '../store/LanSoftStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { LoadingIndicator, ToastContainer } from "./common";
import { ItTicketTypeCreatePayload, ItTicketTypeUpdatePayload } from "../payloads";
import ItTicketTypeItem from "./ItTicketTypeItem";

type ItTicketTypesPropsType = LanSoftStore.LanSoftState
    & typeof LanSoftStore.actionCreators
    & RouteComponentProps<{}>;

interface ItTicketTypesState {
    result: boolean | null;
    toastOpen: boolean;
    editIndex: number;
    deleteIndex: number;
    validationErrors: string[];
    itTicketTypeId: number;
    itTicketType: string;
}

class ItTicketTypes extends React.PureComponent<ItTicketTypesPropsType, ItTicketTypesState> {
    constructor(props: ItTicketTypesPropsType) {
        super(props);

        this.state = {
            result: null,
            toastOpen: false,
            editIndex: NaN,
            deleteIndex: NaN,
            validationErrors: [],
            itTicketTypeId: 0,
            itTicketType: ""
        }
    }

    componentDidMount() {
        this.props.getAllItTicketTypes();
    }

    UNSAFE_componentWillReceiveProps(nextProps: ItTicketTypesPropsType) {
        if (nextProps.result !== this.props.result && nextProps.result != null) {
            this.setState({
                result: nextProps.result,
                toastOpen: true
            });

            window.setTimeout(() => {
                this.setState({
                    toastOpen: false
                }, () => {
                    this.props.clearResult();
                });
            }, 3000);
        }
    }

    public render() {
        return (
            <React.Fragment>
                <LoadingIndicator show={this.props.loading > 0} />
                <h4 className="mb-3"><FontAwesomeIcon icon={["fas", "times"]} className="lansoft-text-success" /> <span className="lansoft-underline-success">Informatikai hibatípusok {`(${this.props.itTicketTypes.length})`}</span></h4>

                <div className="d-flex justify-content-end">
                    <Button onClick={this.handleCreateItTicketTypeClick} color="success" className="lansoft-success">
                        <FontAwesomeIcon icon={["fas", "plus"]} /> Új hibatípus
                    </Button>
                </div>

                {this.props.itTicketTypes.length === 0 &&
                    <div className="d-flex justify-content-center text-white font-italic">
                        <span>Nem található hibatípus.</span>
                    </div>
                }

                <ul className="list-group mt-3 diagonal-background-flat diagonal-background-lansoft-primary position-relative no-left-border-radius">
                    {this.props.itTicketTypes.map((ticketType, index) =>
                        <li className="list-group-item" key={`it-ticket-type-list-item-${index}`}>
                            <ItTicketTypeItem
                                ticketType={ticketType}
                                editItTicketType={() => this.handleEditItTicketTypeClick(index)}
                                deleteItTicketType={() => this.handleDeleteItTicketTypeClick(index)}
                            />
                        </li>
                    )}
                </ul>

                {this.getEditModal()}
                {this.getDeleteModal()}
                <ToastContainer open={this.state.toastOpen} color={this.state.result ? "success" : "danger"} success={this.state.result === true} />
            </React.Fragment>

        );
    }

    private getEditModal = () => {
        return (
            <Modal isOpen={!isNaN(this.state.editIndex) && !this.props.loading && this.props.itTicketTypes.length > this.state.editIndex} toggle={this.closeEditModal} className="modal-dialog-centered">
                <ModalHeader className="lansoft-success" toggle={this.closeEditModal}>Informatikai hibatípus {this.state.editIndex === -1 ? "létrehozása" : "szerkesztése"}</ModalHeader>
                <ModalBody>
                    <div className="form-group">
                        <label htmlFor="inputItTicketType">Típus</label>
                        <input type="text" className="form-control" id="inputItTicketType" autoComplete="off" placeholder="Típus" value={this.state.itTicketType} onChange={this.handleItTicketTypeChange} />
                    </div>

                    {this.state.validationErrors.length > 0 &&
                        <Card className="mt-2 mb-2 p-2 bg-danger lansoft-danger text-white">
                            {this.state.validationErrors.map((error, index) => {
                                return (
                                    <div key={`it-ticket-type-form-validation-error-${index}`} className="form-invalid">
                                        {error}
                                    </div>
                                );
                            })}
                        </Card>
                    }

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleSaveItTicketTypeClick} type="button" className="btn btn-primary lansoft-primary" disabled={!this.isValid()}>
                            <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Mentés
                        </button>

                        <button className="btn btn-secondary" onClick={this.handleCancelEditClick}>
                            <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private getDeleteModal = () => {
        return (
            <Modal isOpen={!isNaN(this.state.deleteIndex) && !this.props.loading} toggle={this.closeDeleteModal} className="modal-dialog-centered">
                <ModalHeader className="lansoft-success" toggle={this.closeDeleteModal}>Informatikai hibatípus törlése</ModalHeader>
                <ModalBody>
                    <div>
                        <p>
                            Biztosan törölni szeretnéd az alábbi hibatípust?
                        </p>
                        <p>{!isNaN(this.state.deleteIndex) && <strong>{this.props.itTicketTypes[this.state.deleteIndex].name}</strong>}</p>
                    </div>

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleConfirmDeleteItTicketTypeClick} type="button" className="btn btn-danger lansoft-danger">
                            <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Törlés
                        </button>

                        <button className="btn btn-secondary" onClick={this.handleCancelDeleteClick}>
                            <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private resetForm = () => {
        this.setState({
            itTicketTypeId: 0,
            itTicketType: "",
            editIndex: NaN,
            validationErrors: []
        });
    }

    private handleCancelEditClick = () => {
        this.setState({
            editIndex: NaN
        });
    }

    private handleCancelDeleteClick = () => {
        this.setState({
            deleteIndex: NaN
        });
    }

    private handleSaveItTicketTypeClick = () => {
        if (this.state.itTicketTypeId === 0) {
            const payload: ItTicketTypeCreatePayload = {
                name: this.state.itTicketType
            };

            this.props.createItTicketType(payload);
        } else {
            const payload: ItTicketTypeUpdatePayload = {
                name: this.state.itTicketType
            };

            this.props.updateItTicketType(this.state.itTicketTypeId, payload);
        }

        this.setState({
            editIndex: NaN
        });
    }

    private handleConfirmDeleteItTicketTypeClick = () => {
        const ticketType = this.props.itTicketTypes[this.state.deleteIndex];
        this.props.deleteItTicketType(ticketType.id);
        this.setState({
            deleteIndex: NaN
        });
    }

    private handleItTicketTypeChange = (e: any) => {
        this.setState({
            itTicketType: e.target.value
        }, () => { this.validate(true); });
    }

    private closeEditModal = () => {
        this.setState({
            editIndex: NaN
        });
    }

    private closeDeleteModal = () => {
        this.setState({
            deleteIndex: NaN
        });
    }

    private handleCreateItTicketTypeClick = () => {
        this.resetForm();
        this.setState({
            editIndex: -1,
            itTicketTypeId: 0,
            itTicketType: ""
        });
    }

    private handleEditItTicketTypeClick = (index: number) => {
        this.resetForm();
        const itTicketType = this.props.itTicketTypes[index];
        this.setState({
            editIndex: index,
            itTicketTypeId: itTicketType.id,
            itTicketType: itTicketType.name
        }, () => this.validate(true));
    }

    private handleDeleteItTicketTypeClick = (index: number) => {
        this.setState({
            deleteIndex: index
        });
    }

    private isValid = () => {
        return this.validate();
    }

    private validate = (shouldSetState: boolean = false) => {
        const validationErrors: string[] = [];

        if (this.state.itTicketType.length === 0) {
            validationErrors.push("A típus megadása kötelező.");
        }

        if (shouldSetState) {
            this.setState({
                validationErrors: validationErrors
            });
        }

        return validationErrors.length === 0;
    }
}

export default connect(
    (state: ApplicationState) => state.lanSoft,
    LanSoftStore.actionCreators
)(ItTicketTypes as any);

import { CopierContract, CopierCounterStatement } from "../models";
import moment from 'moment';

const chartLength = 12;

export const copierContractPrintHistoryBarChartOptions = {
    title: {
        display: true,
        text: 'Nyomat',
        position: 'left' as 'left',
        fontSize: 48
    },
    scales: {
        yAxes: [
            {
                ticks: {
                    beginAtZero: true,
                },
            },
        ],
    }
}

export const copierContractPrintHistoryBarChartOptionsForMobile = {
    ...copierContractPrintHistoryBarChartOptions,
    title: {
        ...copierContractPrintHistoryBarChartOptions.title,
        display: false
    }
}

export function getCopierContractPrintHistoryBarChartData(contract: CopierContract) {
    let reversedStatements: CopierCounterStatement[] = [];
    reversedStatements = reversedStatements.concat(contract.statements).reverse().slice(0, chartLength);
    const labels = reversedStatements.map(statement => moment(statement.createdAt).format("YYYY.MM.DD"));
    while (labels.length < chartLength) {
        labels.push("");
    }

    const barChartData = {
        labels: labels,
        datasets: [
            {
                label: 'Fekete',
                data: reversedStatements.map((statement, index) => {
                    return statement.blackAndWhitePrints
                }),
                backgroundColor: 'black',
            }
        ]
    }

    if (contract.device.model.color) {
        barChartData.datasets.push({
            label: 'Színes',
            data: reversedStatements.map((statement, index) => {
                return statement.colorPrints || 0
            }),
            backgroundColor: 'rgb(248, 85, 114)'
        });
    }

    return barChartData;
}

import { ProtectedRestApi } from "./";
import { CopierCounterStatement, ServiceResult } from "../models";

export class CopierCounterStatementApi extends ProtectedRestApi {
    private static baseUrl = "/api/copier-counter-statement";

    public getAllForHistory(): Promise<ServiceResult<CopierCounterStatement[]>> {
        return this.httpGet(`${CopierCounterStatementApi.baseUrl}/all-for-history`) as Promise<ServiceResult<CopierCounterStatement[]>>;
    }

    public getAll(onlyMine: boolean): Promise<ServiceResult<CopierCounterStatement[]>> {
        return this.httpGet(`${CopierCounterStatementApi.baseUrl}/${onlyMine ? "own" : "all"}`) as Promise<ServiceResult<CopierCounterStatement[]>>;
    }

    public getNotAnnounced(onlyMine: boolean): Promise<ServiceResult<CopierCounterStatement[]>> {
        return this.httpGet(`${CopierCounterStatementApi.baseUrl}/not-announced/${onlyMine ? "own" : "all"}`) as Promise<ServiceResult<CopierCounterStatement[]>>;
    }
}

import { ProtectedRestApi } from "./";
import { ServiceResult } from "../models";

export class EmailApi extends ProtectedRestApi {
    private static baseUrl = "/api/email";

    public sendStatementNotification(contractId: number): Promise<ServiceResult<boolean>> {
        return this.httpPost(`${EmailApi.baseUrl}/copier-contract/${contractId}/statement-notification`) as Promise<ServiceResult<boolean>>;
    }
}

import { ProtectedRestApi } from "./";
import { ItSupportTicket, ServiceResult } from "../models";

export class ItSupportTicketApi extends ProtectedRestApi {
    private static baseUrl = "/api/it-support-ticket";

    public getActive(onlyMine: boolean): Promise<ServiceResult<ItSupportTicket[]>> {
        return this.httpGet(`${ItSupportTicketApi.baseUrl}/active/${onlyMine ? "own" : "all"}`) as Promise<ServiceResult<ItSupportTicket[]>>;
    }

    public getInactive(onlyMine: boolean): Promise<ServiceResult<ItSupportTicket[]>> {
        return this.httpGet(`${ItSupportTicketApi.baseUrl}/inactive/${onlyMine ? "own" : "all"}`) as Promise<ServiceResult<ItSupportTicket[]>>;
    }

    public getScheduled(onlyMine: boolean): Promise<ServiceResult<ItSupportTicket[]>> {
        return this.httpGet(`${ItSupportTicketApi.baseUrl}/scheduled/${onlyMine ? "own" : "all"}`) as Promise<ServiceResult<ItSupportTicket[]>>;
    }
}

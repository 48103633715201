import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as LanSoftStore from '../store/LanSoftStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card } from 'reactstrap';
import { LoadingIndicator, ToastContainer } from "./common";
import { DataImportResult } from '../models';

type DataImportPropsType = LanSoftStore.LanSoftState
    & typeof LanSoftStore.actionCreators
    & RouteComponentProps<{}>;

interface DataImportState {
    importFile: File | null;
    result: boolean | null;
    toastOpen: boolean;
    validationErrors: string[];
    importResult: DataImportResult | null;
}

class DataImport extends React.PureComponent<DataImportPropsType, DataImportState> {
    constructor(props: DataImportPropsType) {
        super(props);

        this.state = {
            importFile: null,
            result: null,
            toastOpen: false,
            validationErrors: [],
            importResult: props.dataImportResult
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: DataImportPropsType) {
        if (nextProps.result !== this.props.result && nextProps.result != null) {
            this.setState({
                result: nextProps.result,
                toastOpen: true
            });

            window.setTimeout(() => {
                this.setState({
                    toastOpen: false
                }, () => {
                    this.props.clearResult();
                });
            }, 3000);
        }

        if ((this.props.dataImportResult == null && nextProps.dataImportResult != null) || (this.props.dataImportResult != null && nextProps.dataImportResult == null)) {
            this.setState({
                importResult: nextProps.dataImportResult
            });
        }
    }

    public render() {
        return (
            <React.Fragment>
                <LoadingIndicator show={this.props.loading > 0} />
                <h4 className="mb-3"><FontAwesomeIcon icon={["fas", "file-upload"]} className="lansoft-text-success" /> <span className="lansoft-underline-success">Importálás</span></h4>

                <div className="form-group mt-4">
                    <label htmlFor="inputImportFile">Importálandó fájl</label>
                    <input type="file" className="form-control" id="inputImportFile" onChange={this.handleImportFileChange} />
                </div>

                {this.state.validationErrors.length > 0 &&
                    <Card className="mt-2 mb-2 p-2 bg-danger lansoft-danger text-white">
                        {this.state.validationErrors.map((error, index) => {
                            return (
                                <div key={`data-import-form-validation-error-${index}`} className="form-invalid">
                                    {error}
                                </div>
                            );
                        })}
                    </Card>
                }

                <div className="d-flex justify-content-between align-items-center mt-4">
                    <button onClick={this.handleImportDataClick} type="button" className="btn btn-primary lansoft-primary" disabled={!this.isValid()}>
                        <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Feltöltés
                    </button>
                </div>
                <div className="mt-4">
                    <Button color="primary" className="lansoft-primary" onClick={this.handleDownloadSampleFileClick}>Mintafájl letöltése</Button>
                </div>

                {this.state.importResult != null &&
                    <Card className={`mt-4 mb-2 p-2 bg-danger ${(this.state.importResult.errorMessage != null && this.state.importResult.errorMessage.length > 0) ? "lansoft-danger" : "lansoft-success"} text-white`}>
                        <h5>{(this.state.importResult.errorMessage != null && this.state.importResult.errorMessage.length > 0) ? "Hiba történt az importálás során." : "Sikeres importálás"}</h5>
                        {(this.state.importResult.errorMessage != null && this.state.importResult.errorMessage.length > 0) && <div>{this.state.importResult.errorMessage}</div>}
                        {!(this.state.importResult.errorMessage != null && this.state.importResult.errorMessage.length > 0) && <div>
                            <div>Importált ügyfelek: {this.state.importResult.clientCount}</div>
                            <div>Importált technikusok: {this.state.importResult.technicianCount}</div>
                            <div>Importált másolástechnikai géptípusok: {this.state.importResult.copierModelCount}</div>
                            <div>Importált másolástechnikai gépek: {this.state.importResult.copierDeviceCount}</div>
                            <div>Importált másolástechnikai szerződések: {this.state.importResult.copierContractCount}</div>
                            <div>Importált másolástechnikai számlálóállások: {this.state.importResult.copierCounterStatementCount}</div>
                        </div>}
                    </Card>
                }

                <ToastContainer open={this.state.toastOpen} color={this.state.result ? "success" : "danger"} success={this.state.result === true} />
            </React.Fragment>

        );
    }

    private handleDownloadSampleFileClick = () => {
        window.location.href = `/assets/import_sample.xlsx`;
    }

    private handleImportFileChange = (e: any) => {
        this.setState({
            importFile: e.target.files[0]
        }, () => this.validate(true));
    }

    private handleImportDataClick = () => {
        this.props.importData(this.state.importFile!!);
    }

    private isValid = () => {
        return this.validate();
    }

    private validate = (shouldSetState: boolean = false) => {
        const validationErrors: string[] = [];

        if (this.state.importFile == null) {
            validationErrors.push("A fájl megadása kötelező.");
        }

        if (shouldSetState) {
            this.setState({
                validationErrors: validationErrors
            });
        }

        return validationErrors.length === 0;
    }
}

export default connect(
    (state: ApplicationState) => state.lanSoft,
    LanSoftStore.actionCreators
)(DataImport as any);

import { ProtectedRestApi } from "./";
import { ItTicketSolutionType, ServiceResult } from "../models";
import { ItTicketSolutionTypeCreatePayload, ItTicketSolutionTypeUpdatePayload } from "../payloads";

export class ItTicketSolutionTypeApi extends ProtectedRestApi {
    private static baseUrl = "/api/it-ticket-solution-type";

    public getAll(): Promise<ServiceResult<ItTicketSolutionType[]>> {
        return this.httpGet(`${ItTicketSolutionTypeApi.baseUrl}/all`) as Promise<ServiceResult<ItTicketSolutionType[]>>;
    }

    public create(payload: ItTicketSolutionTypeCreatePayload): Promise<ServiceResult<ItTicketSolutionType>> {
        return this.httpPut(`${ItTicketSolutionTypeApi.baseUrl}`, payload) as Promise<ServiceResult<ItTicketSolutionType>>;
    }

    public update(id: number, payload: ItTicketSolutionTypeUpdatePayload): Promise<ServiceResult<ItTicketSolutionType>> {
        return this.httpPost(`${ItTicketSolutionTypeApi.baseUrl}/${id}`, payload) as Promise<ServiceResult<ItTicketSolutionType>>;
    }

    public delete(id: number): Promise<ServiceResult<number>> {
        return this.httpDelete(`${ItTicketSolutionTypeApi.baseUrl}/${id}`) as Promise<ServiceResult<number>>;
    }
}

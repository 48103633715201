import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store/index';
import * as LanSoftStore from '../store/LanSoftStore'
import { Navbar } from 'reactstrap';
import './NavMenu.css';
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants';
import { MenuItem } from "../models";
import NavMenuItem from "./NavMenuItem";
import NavMenuItemGroup from "./NavMenuItemGroup";
import { Link } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';

type NavMenuProps = LanSoftStore.LanSoftState
    & typeof LanSoftStore.actionCreators
    & RouteComponentProps<{}>;

interface NavMenuState {
}

const menuItems: MenuItem[] = [
    {
        icon: "file-contract",
        text: "Szerződéseim",
        target: "/my"
    }
];

const profileMenu: MenuItem = {
    icon: "user",
    text: "Profil"
};

const profileMenuItems: MenuItem[] = [
    {
        icon: "envelope",
        text: "E-mail változtatás",
        target: "/Identity/Account/Manage/Email"
    }, {
        icon: "key",
        text: "Jelszóváltoztatás",
        target: "/Identity/Account/Manage/ChangePassword"
    }, {
        icon: "sign-out-alt",
        text: "Kijelentkezés",
        target: ApplicationPaths.LogOut
    }
];

const pixelOffset = 10;

class NavMenu extends React.PureComponent<NavMenuProps, NavMenuState> {
    constructor(props: NavMenuProps) {
        super(props);

        this.state = {
        }
    }

    public render() {
        if (isMobileOnly) {
            return (
                <React.Fragment>
                    <nav className="navbar navbar-toggleable-md navbar-light bg-faded">
                        <Link to="/" className="pl-3">
                            <img className="nav-logo nav-logo-mobile" src="images/Lansoft_logo_mobile.png" alt="logo" />
                        </Link>
                        <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNavDropdown">
                            <ul className="navbar-nav mr-auto">
                                {menuItems.map((menuItem, index) => {
                                    return (
                                        <NavMenuItem
                                            mobile={true}
                                            key={`nav-menu-item-${index}`}
                                            menuItem={menuItem}
                                        />
                                    );
                                })}
                                <NavMenuItemGroup
                                    mobile={true}
                                    menuItem={profileMenu}
                                    items={profileMenuItems}
                                    right={true}
                                    isLast={true}
                                />
                            </ul>
                        </div>
                    </nav>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <header>
                    <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3 w-100 main-nav-container" light>
                        <div className="w-100 d-flex justify-content-between align-items-center">
                            <div className="mx-3 logo-container">
                                <Link to="/">
                                    <img className="nav-logo" src="images/Lansoft_logo.png" alt="logo" />
                                </Link>
                            </div>
                            <div className="d-flex justify-content-center nav-menu-container pl-0" style={{ paddingBottom: `${pixelOffset}px`, paddingTop: `${pixelOffset * 1.5}px` }}>
                                {menuItems.map((menuItem, index) => {
                                    return (
                                        <NavMenuItem
                                            key={`nav-menu-item-${index}`}
                                            menuItem={menuItem}
                                            className="mr-4"
                                        />
                                    );
                                })}
                                <NavMenuItemGroup
                                    menuItem={profileMenu}
                                    items={profileMenuItems}
                                    right={true}
                                    isLast={true}
                                    className="mr-5"
                                />
                            </div>
                        </div>
                    </Navbar>
                </header>
            </React.Fragment>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.lanSoft,
    LanSoftStore.actionCreators
)(NavMenu as any);

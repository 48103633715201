import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store/index';
import * as LanSoftStore from '../store/LanSoftStore'
import { Navbar } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants';
import TechnicianInfo from "./TechnicianInfo";
import { MenuItem } from "../models";
import NavMenuItem from "./NavMenuItem";
import NavMenuItemGroup from "./NavMenuItemGroup";
import { isMobileOnly } from "react-device-detect";

type NavMenuTechnicianProps = LanSoftStore.LanSoftState
    & typeof LanSoftStore.actionCreators
    & RouteComponentProps<{}>;

interface NavMenuTechnicianState {
    techniciansRequested: boolean;
}

const menuItems: MenuItem[] = [
    {
        icon: "wrench",
        text: "Technikusok",
        target: "/admin/technicians"
    }, {
        icon: "users",
        text: "Ügyfelek",
        target: "/admin/clients"
    }, {
        icon: "file-contract",
        text: "Szerződések",
        target: "/admin/contracts"
    }, {
        icon: "exclamation-circle",
        text: "Hibák",
        target: "/admin/tickets"
    }, {
        icon: "calculator",
        text: "Számlálók",
        target: "/admin/copier-counter-statements"
    }
];

const baseDataMenu: MenuItem = {
    icon: "database",
    text: "Törzsadat"
};

const profileMenu: MenuItem = {
    icon: "user",
    text: "Profil"
};

const profileMenuItems: MenuItem[] = [
    {
        icon: "envelope",
        text: "E-mail változtatás",
        target: "/Identity/Account/Manage/Email"
    }, {
        icon: "key",
        text: "Jelszóváltoztatás",
        target: "/Identity/Account/Manage/ChangePassword"
    }, {
        icon: "sign-out-alt",
        text: "Kijelentkezés",
        target: ApplicationPaths.LogOut
    }
];

const baseDataMenuItems: MenuItem[] = [
    {
        icon: "hdd",
        text: "Géptípusok",
        target: "/admin/copier-models"
    }, {
        icon: "desktop",
        customIcon: "icons/printer-turquoise.svg",
        text: "Gépek",
        target: "/admin/copier-devices"
    },
    {
        icon: "times",
        text: "Másolástechnikai hibatípusok",
        target: "/admin/copier-ticket-types"
    }, {
        icon: "check-double",
        text: "Másolástechnikai megoldástípusok",
        target: "/admin/copier-ticket-solution-types"
    },
    {
        icon: "times",
        text: "Informatikai hibatípusok",
        target: "/admin/it-ticket-types"
    }, {
        icon: "check-double",
        text: "Informatikai megoldástípusok",
        target: "/admin/it-ticket-solution-types"
    }, {
        icon: "file-upload",
        text: "Importálás",
        target: "/admin/data-import"
    }, {
        icon: "file-download",
        text: "Exportálás",
        target: "/admin/data-export"
    }
];

const pixelOffset = 10;

class NavMenuTechnician extends React.PureComponent<NavMenuTechnicianProps, NavMenuTechnicianState> {
    constructor(props: NavMenuTechnicianProps) {
        super(props);

        this.state = {
            techniciansRequested: false
        }
    }

    componentDidMount() {
        if (!this.state.techniciansRequested && this.props.technicians.length === 0) {
            this.setState({
                techniciansRequested: true
            }, () => {
                this.props.getAllTechnicians();
            });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: NavMenuTechnicianProps) {
        if (nextProps.loggedInUser != null && nextProps.technicians.length > 0 && this.props.technicians.length === 0) {
            const technician = nextProps.technicians.find(x => x.user.id === nextProps.loggedInUser!!.id);
            if (technician != null) {
                this.props.getActiveCopierSupportTickets(!technician.displayItemsForAllTechnicians);
                this.props.getScheduledCopierSupportTickets(!technician.displayItemsForAllTechnicians);
                this.props.getActiveItSupportTickets(!technician.displayItemsForAllTechnicians);
                this.props.getScheduledItSupportTickets(!technician.displayItemsForAllTechnicians);
            }
        }
    }

    public render() {
        const technician = this.props.loggedInUser == null ? null : this.props.technicians.find(x => x.user.id === this.props.loggedInUser!!.id);

        if (isMobileOnly) {
            return (
                <React.Fragment>
                    <nav className="navbar navbar-toggleable-md navbar-light bg-faded">
                        <Link to="/" className="pl-3">
                            <img className="nav-logo nav-logo-mobile" src="images/Lansoft_logo_mobile.png" alt="logo" />
                        </Link>
                        <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNavDropdown">
                            <ul className="navbar-nav mr-auto">
                                <NavMenuItemGroup
                                    mobile={true}
                                    menuItem={baseDataMenu}
                                    items={baseDataMenuItems}
                                />
                                {menuItems.map((menuItem, index) => {
                                    return (
                                        <NavMenuItem
                                            mobile={true}
                                            key={`nav-menu-item-${index}`}
                                            menuItem={menuItem}
                                        />
                                    );
                                })}
                                <NavMenuItemGroup
                                    mobile={true}
                                    menuItem={profileMenu}
                                    items={profileMenuItems}
                                    right={true}
                                    isLast={true}
                                />
                            </ul>
                        </div>
                    </nav>
                    {technician != null && <TechnicianInfo
                        technician={technician}
                        activeTicketCount={this.props.activeCopierSupportTickets.length + this.props.activeItSupportTickets.length}
                        scheduledTicketCount={this.props.scheduledCopierSupportTickets.length + this.props.scheduledItSupportTickets.length}
                    />}
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <header>
                    <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3 w-100 main-nav-container" light>
                        <div className="w-100 d-flex justify-content-between align-items-center">
                            <div className="mx-3 logo-container">
                                <Link to="/">
                                    <img className="nav-logo" src="images/Lansoft_logo.png" alt="logo" />
                                </Link>
                            </div>
                            <div className="d-flex justify-content-between nav-menu-container" style={{ paddingBottom: `${pixelOffset}px`, paddingTop: `${pixelOffset * 1.5}px` }}>
                                <NavMenuItemGroup
                                    menuItem={baseDataMenu}
                                    items={baseDataMenuItems}
                                />
                                {menuItems.map((menuItem, index) => {
                                    return (
                                        <NavMenuItem
                                            key={`nav-menu-item-${index}`}
                                            menuItem={menuItem}
                                        />
                                    );
                                })}
                                <NavMenuItemGroup
                                    menuItem={profileMenu}
                                    items={profileMenuItems}
                                    right={true}
                                    isLast={true}
                                />
                            </div>
                        </div>
                    </Navbar>
                </header>
                {technician != null && <TechnicianInfo
                    technician={technician}
                    activeTicketCount={this.props.activeCopierSupportTickets.length + this.props.activeItSupportTickets.length}
                    scheduledTicketCount={this.props.scheduledCopierSupportTickets.length + this.props.scheduledItSupportTickets.length}
                />}
            </React.Fragment>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.lanSoft,
    LanSoftStore.actionCreators
)(NavMenuTechnician as any);

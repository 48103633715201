import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as LanSoftStore from '../store/LanSoftStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingIndicator, ToastContainer } from "./common";
import { CopierCounterStatementCreatePayload } from '../payloads';
import CopierCounterStatementCard from "./CopierCounterStatementCard";
import { getIndicatorClassFromStatement, getMonthNameFromMonthNumber } from '../utils';
import { CopierCounterStatement, CopierCounterStatementTab, Technician } from "../models";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { isMobileOnly } from 'react-device-detect';

type CopierCounterStatementsPropsType = LanSoftStore.LanSoftState
    & typeof LanSoftStore.actionCreators
    & RouteComponentProps<{}>;

interface CopierCounterStatementsState {
    result: boolean | null;
    toastOpen: boolean;
    expandedIndexAnnounced: number;
    expandedIndexNotAnnounced: number;
    expandedIndexCurrentMonth: number;
    showAll: boolean;
    showAllSet: boolean;
    yearDropdownOpen: boolean;
    monthDropdownOpen: boolean;
    year: number;
    month: number;
    search: string;
    currentTechnician: Technician | null;
    filteredStatements: CopierCounterStatement[];
    filteredNotAnnouncedStatements: CopierCounterStatement[];
}

class CopierCounterStatements extends React.PureComponent<CopierCounterStatementsPropsType, CopierCounterStatementsState> {
    constructor(props: CopierCounterStatementsPropsType) {
        super(props);

        this.state = {
            result: null,
            toastOpen: false,
            expandedIndexAnnounced: NaN,
            expandedIndexNotAnnounced: NaN,
            expandedIndexCurrentMonth: NaN,
            showAll: false,
            showAllSet: false,
            yearDropdownOpen: false,
            monthDropdownOpen: false,
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            search: "",
            currentTechnician: null,
            filteredStatements: [],
            filteredNotAnnouncedStatements: []
        }
    }

    componentDidMount() {
        this.props.getAllCopierContracts();
        this.props.getAllCopierCounterStatementsForHistory();
    }

    UNSAFE_componentWillReceiveProps(nextProps: CopierCounterStatementsPropsType) {
        if (nextProps.result !== this.props.result && nextProps.result != null) {
            this.setState({
                result: nextProps.result,
                toastOpen: true
            });

            window.setTimeout(() => {
                this.setState({
                    toastOpen: false
                }, () => {
                    this.props.clearResult();
                });
            }, 3000);
        }

        if (!this.state.showAllSet && nextProps.loggedInUser != null && nextProps.technicians.length > 0) {
            const technician = nextProps.technicians.find(x => x.user.id === nextProps.loggedInUser!!.id);
            if (technician != null) {
                this.setState({
                    showAll: technician.displayItemsForAllTechnicians,
                    showAllSet: true,
                    currentTechnician: technician,
                    filteredStatements: this.filterStatements(nextProps.copierCounterStatements, this.state.search, technician.displayItemsForAllTechnicians, technician.id),
                    filteredNotAnnouncedStatements: this.filterStatements(nextProps.notAnnouncedCopierCounterStatements, this.state.search, technician.displayItemsForAllTechnicians, technician.id)
                });

                this.props.getAllCopierCounterStatements(!technician.displayItemsForAllTechnicians);
                this.props.getNotAnnouncedCopierCounterStatements(!technician.displayItemsForAllTechnicians);
            }
        }

        if (this.state.currentTechnician != null && (nextProps.copierCounterStatements.length !== this.props.copierCounterStatements.length || nextProps.notAnnouncedCopierCounterStatements.length !== this.props.notAnnouncedCopierCounterStatements.length)) {
            this.setState({
                filteredStatements: this.filterStatements(nextProps.copierCounterStatements, this.state.search, this.state.currentTechnician.displayItemsForAllTechnicians, this.state.currentTechnician.id),
                filteredNotAnnouncedStatements: this.filterStatements(nextProps.notAnnouncedCopierCounterStatements, this.state.search, this.state.currentTechnician.displayItemsForAllTechnicians, this.state.currentTechnician.id)
            });
        }
    }

    public render() {
        const allStatements = this.props.copierCounterStatements.concat(this.props.notAnnouncedCopierCounterStatements);
        const announcedStatements = this.state.filteredStatements;
        const notAnnouncedStatements = this.state.filteredNotAnnouncedStatements;
        const currentMonthStatements = announcedStatements
            .concat(notAnnouncedStatements)
            .sort((a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf())
            .filter(x => new Date(x.createdAt).getFullYear() === this.state.year && (new Date(x.createdAt).getMonth() + 1 === this.state.month || this.state.month === -1));

        const years = [...new Set(allStatements.map(statement => new Date(statement.createdAt).getFullYear()))];
        const months = [...new Set(allStatements.filter(statement => new Date(statement.createdAt).getFullYear() === this.state.year).map(statement => new Date(statement.createdAt).getMonth() + 1))];

        return (
            <React.Fragment>
                <LoadingIndicator show={this.props.loading > 0} />
                <h4 className="mb-3"><FontAwesomeIcon icon={["fas", "calculator"]} className="lansoft-text-success" /> <span className="lansoft-underline-success">Számlálók</span></h4>

                <div className={`d-flex justify-content-between align-items-center mt-4 ${isMobileOnly ? "mb-4" : ""}`}>
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className={`d-flex pl-0 ${isMobileOnly ? "col-12" : ""}`}>
                                <div className="form-group form-inline my-2">
                                    <Dropdown isOpen={this.state.yearDropdownOpen} toggle={this.toggleYearDropdown}>
                                        <DropdownToggle className="lansoft-success" caret>
                                            <span className="mr-2">{isNaN(this.state.year) ? "Év" : this.state.year}</span>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {years.sort().map((year, index) =>
                                                <DropdownItem active={this.state.year === year} onClick={() => this.handleYearChange(year)} key={`year-dropdown-item-${index}`}>{year}</DropdownItem>
                                            )}
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                                <div className="form-group form-inline ml-3 my-2">
                                    <Dropdown isOpen={this.state.monthDropdownOpen} toggle={this.toggleMonthDropdown}>
                                        <DropdownToggle className="lansoft-success w-120-px" caret>
                                            <span className="mr-2">{isNaN(this.state.month) ? "Hónap" : (this.state.month === -1 ? "Mind" : getMonthNameFromMonthNumber(this.state.month))}</span>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem active={this.state.month === -1} onClick={() => this.handleMonthChange(-1)} key={`month-dropdown-item--1`}>Mind</DropdownItem>
                                            {months.sort().map((month, index) =>
                                                <DropdownItem active={this.state.month === month} onClick={() => this.handleMonthChange(month)} key={`month-dropdown-item-${index}`}>{getMonthNameFromMonthNumber(month)}</DropdownItem>
                                            )}
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                            </div>
                            {isMobileOnly && <div className="col-12 no-left-padding mt-2">
                                <div className="form-group form-inline no-bottom-margin">
                                    <input type="text" className="form-control" id="inputSearch" autoComplete="off" placeholder="Keresés" value={this.state.search} onChange={this.handleSearchChange} />
                                </div>
                            </div>}
                            <div className={`pl-0 pr-0 ${isMobileOnly ? "col-12" : "text-right"}`}>
                                <div className="form-check mt-3">
                                    <input type="checkbox" className="form-check-input" id="inputShowAll" checked={this.state.showAll} onChange={this.handleShowAllChange} />
                                    <label className="form-check-label" htmlFor="inputShowAll">Az összes technikus számlálóinak listázása</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="tabbable full-width-tabs tickets-tabs">
                    <ul className={`nav nav-tabs mb-3 d-flex justify-content-start ${isMobileOnly ? "align-items-center" : "align-items-end"}`} id="statements-tab-list" role="tablist">
                        <li className="d-flex">
                            <a className="nav-link active" id="announced-tab" data-toggle="pill" href="#announced-statements" role="tab" aria-controls="announced" aria-selected="true">Bejelentett ({announcedStatements.length})</a>
                        </li>
                        <li className="d-flex">
                            <a className="nav-link" id="not-announced-tab" data-toggle="pill" href="#not-announced-statements" role="tab" aria-controls="not-announced" aria-selected="false">Nem bejelentett ({notAnnouncedStatements.length})</a>
                        </li>
                        <li className="d-flex">
                            <a className="nav-link" id="all-tab" data-toggle="pill" href="#all-statements" role="tab" aria-controls="all" aria-selected="false">Minden számláló ({currentMonthStatements.length})</a>
                        </li>
                        {!isMobileOnly && <div className="desktop-tab-search-bar-right">
                            <div className="form-group form-inline no-bottom-margin">
                                <input type="text" className="form-control" id="inputSearch" autoComplete="off" placeholder="Keresés" value={this.state.search} onChange={this.handleSearchChange} />
                            </div>
                        </div>}
                    </ul>
                </div>
                {this.props.copierContracts.length > 0 && <div className="tab-content" id="tickets-tab-content">
                    <div className="tab-pane fade show active" id="announced-statements" role="tabpanel" aria-labelledby="announced-statements-tab">
                        <ul className="list-group mt-3">
                            {announcedStatements.length === 0 &&
                                <div className="d-flex justify-content-center text-white font-italic mt-4">
                                    <span>Nem található számláló.</span>
                                </div>
                            }
                            {this.props.copierContracts.length > 0 && announcedStatements.map((statement, index) => {
                                const contract = this.props.copierContracts.find(x => x.id === statement.contractId);
                                const contractIds = this.props.copierContracts.filter(x => contract != null && x.device.id === contract.device.id).map(x => x.id);
                                const statementHistory = this.props.copierCounterStatementsForHistory.filter(x => contractIds.indexOf(x.contractId) > -1)
                                    .sort((a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf());

                                return (
                                    <li className="list-group-item-container" key={`statement-list-item-${index}-${statement.contractId}-${statement.id}`}>
                                        <div className={`list-group-item list-margin diagonal-background diagonal-background-${getIndicatorClassFromStatement(statement)}`}>
                                            <CopierCounterStatementCard
                                                contract={this.props.copierContracts.find(x => x.id === statement.contractId)!!}
                                                statement={statement}
                                                expandCollapse={() => this.handleAnnouncedCardExpandCollapseClick(index)}
                                                isExpanded={this.state.expandedIndexAnnounced === index}
                                                createInvoice={file => this.props.createCopierInvoice(statement.contractId, statement.id, file)}
                                                createStatement={payload => this.createStatement(statement.contractId, payload)}
                                                sendStatementNotification={() => this.props.sendStatementNotification(statement.contractId)}
                                                tab={CopierCounterStatementTab.Announced}
                                                contracts={this.props.copierContracts}
                                                statements={statementHistory}
                                            />
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <div className="tab-pane fade" id="not-announced-statements" role="tabpanel" aria-labelledby="not-announced-statements-tab">
                        <ul className="list-group mt-3">
                            {notAnnouncedStatements.length === 0 &&
                                <div className="d-flex justify-content-center text-white font-italic mt-4">
                                    <span>Nem található számláló.</span>
                                </div>
                            }
                            {this.props.copierContracts.length > 0 && notAnnouncedStatements.map((statement, index) => {
                                const contract = this.props.copierContracts.find(x => x.id === statement.contractId);
                                const contractIds = this.props.copierContracts.filter(x => contract != null && x.device.id === contract.device.id).map(x => x.id);
                                const statementHistory = this.props.copierCounterStatementsForHistory.filter(x => contractIds.indexOf(x.contractId) > -1)
                                    .sort((a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf());

                                return (
                                    <li className="list-group-item-container" key={`statement-list-item-${index}-${statement.contractId}-${statement.id}`}>
                                        <div className={`list-group-item list-margin diagonal-background diagonal-background-${getIndicatorClassFromStatement(statement)}`}>
                                            <CopierCounterStatementCard
                                                contract={this.props.copierContracts.find(x => x.id === statement.contractId)!!}
                                                statement={statement}
                                                expandCollapse={() => this.handleNotAnnouncedCardExpandCollapseClick(index)}
                                                isExpanded={this.state.expandedIndexNotAnnounced === index}
                                                createInvoice={file => this.props.createCopierInvoice(statement.contractId, statement.id, file)}
                                                createStatement={payload => this.createStatement(statement.contractId, payload)}
                                                sendStatementNotification={() => this.props.sendStatementNotification(statement.contractId)}
                                                tab={CopierCounterStatementTab.NotAnnounced}
                                                contracts={this.props.copierContracts}
                                                statements={statementHistory}
                                            />
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <div className="tab-pane fade" id="all-statements" role="tabpanel" aria-labelledby="all-statements-tab">
                        <ul className="list-group mt-3">
                            {currentMonthStatements.length === 0 &&
                                <div className="d-flex justify-content-center text-white font-italic mt-4">
                                    <span>Nem található számláló.</span>
                                </div>
                            }
                            {this.props.copierContracts.length > 0 && currentMonthStatements.map((statement, index) => {
                                const contract = this.props.copierContracts.find(x => x.id === statement.contractId);
                                const contractIds = this.props.copierContracts.filter(x => contract != null && x.device.id === contract.device.id).map(x => x.id);
                                const statementHistory = this.props.copierCounterStatementsForHistory.filter(x => contractIds.indexOf(x.contractId) > -1)
                                    .sort((a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf());

                                return (
                                    <li className="list-group-item-container" key={`statement-list-item-${index}-${statement.contractId}-${statement.id}`}>
                                        <div className={`list-group-item list-margin diagonal-background diagonal-background-${getIndicatorClassFromStatement(statement)}`}>
                                            <CopierCounterStatementCard
                                                contract={this.props.copierContracts.find(x => x.id === statement.contractId)!!}
                                                statement={statement}
                                                expandCollapse={() => this.handleCurrentMonthCardExpandCollapseClick(index)}
                                                isExpanded={this.state.expandedIndexCurrentMonth === index}
                                                createInvoice={file => this.props.createCopierInvoice(statement.contractId, statement.id, file)}
                                                createStatement={payload => this.createStatement(statement.contractId, payload)}
                                                sendStatementNotification={() => this.props.sendStatementNotification(statement.contractId)}
                                                tab={CopierCounterStatementTab.CurrentMonth}
                                                contracts={this.props.copierContracts}
                                                statements={statementHistory}
                                            />
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>}

                <ToastContainer open={this.state.toastOpen} color={this.state.result ? "success" : "danger"} success={this.state.result === true} />
            </React.Fragment>

        );
    }

    private handleSearchChange = (e: any) => {
        const searchString = e.target.value;
        this.setState({
            search: searchString,
            filteredStatements: this.filterStatements(this.props.copierCounterStatements, searchString, this.state.showAll, this.state.currentTechnician!!.id),
            filteredNotAnnouncedStatements: this.filterStatements(this.props.notAnnouncedCopierCounterStatements, searchString, this.state.showAll, this.state.currentTechnician!!.id)
        });
    }

    private filterStatements = (statements: CopierCounterStatement[], searchString: string, showAll: boolean, technicianId: number) => {
        return statements.filter(x => {
            const date = new Date(x.createdAt);
            const contract = this.props.copierContracts.find(y => y.id === x.contractId);

            return (date.getFullYear() === this.state.year && (date.getMonth() + 1 === this.state.month || this.state.month === -1)
                && (contract == null || ((contract.client.name.toLowerCase().indexOf(searchString.toLowerCase()) !== -1 ||
                    contract.contractNumber.toLowerCase().indexOf(searchString.toLowerCase()) !== -1 ||
                    contract.device.serialNumber.toLowerCase().indexOf(searchString.toLowerCase()) !== -1 ||
                    contract.device.model.name.toLowerCase().indexOf(searchString.toLowerCase()) !== -1 ||
                    (contract.memo != null && contract.memo.toLowerCase().indexOf(searchString.toLowerCase()) !== -1)) &&
                    (showAll || contract.technician === null || contract.technician.id === technicianId)))
            );
        });
    }

    private toggleYearDropdown = () => {
        this.setState({
            yearDropdownOpen: !this.state.yearDropdownOpen
        });
    }

    private toggleMonthDropdown = () => {
        this.setState({
            monthDropdownOpen: !this.state.monthDropdownOpen
        });
    }

    private handleYearChange = (year: number) => {
        const allStatements = this.props.copierCounterStatements.concat(this.props.notAnnouncedCopierCounterStatements);
        const months = [...new Set(allStatements.filter(statement => new Date(statement.createdAt).getFullYear() === year).map(statement => new Date(statement.createdAt).getMonth() + 1))];
        this.setState({
            year: year,
            month: months.length > 0 ? months[0] : this.state.month
        }, () => {
            this.setState({
                filteredStatements: this.filterStatements(this.props.copierCounterStatements, this.state.search, this.state.currentTechnician!!.displayItemsForAllTechnicians, this.state.currentTechnician!!.id),
                filteredNotAnnouncedStatements: this.filterStatements(this.props.notAnnouncedCopierCounterStatements, this.state.search, this.state.currentTechnician!!.displayItemsForAllTechnicians, this.state.currentTechnician!!.id)
            });
        });
    }

    private handleMonthChange = (month: number) => {
        this.setState({
            month: month
        }, () => {
            this.setState({
                filteredStatements: this.filterStatements(this.props.copierCounterStatements, this.state.search, this.state.currentTechnician!!.displayItemsForAllTechnicians, this.state.currentTechnician!!.id),
                filteredNotAnnouncedStatements: this.filterStatements(this.props.notAnnouncedCopierCounterStatements, this.state.search, this.state.currentTechnician!!.displayItemsForAllTechnicians, this.state.currentTechnician!!.id)
            });
        });
    }

    private createStatement = (contractId: number, payload: CopierCounterStatementCreatePayload) => {
        this.props.createCopierCounterStatement(contractId, payload);
    }

    private handleShowAllChange = (e: any) => {
        this.setState({
            showAll: e.target.checked
        }, () => {
            this.props.getAllCopierCounterStatements(!this.state.showAll);
            this.props.getNotAnnouncedCopierCounterStatements(!this.state.showAll);
        });
    }

    private handleAnnouncedCardExpandCollapseClick = (index: number) => {
        this.setState({
            expandedIndexAnnounced: this.state.expandedIndexAnnounced === index ? NaN : index
        });
    }

    private handleNotAnnouncedCardExpandCollapseClick = (index: number) => {
        this.setState({
            expandedIndexNotAnnounced: this.state.expandedIndexNotAnnounced === index ? NaN : index
        });
    }

    private handleCurrentMonthCardExpandCollapseClick = (index: number) => {
        this.setState({
            expandedIndexCurrentMonth: this.state.expandedIndexCurrentMonth === index ? NaN : index
        });
    }
}

export default connect(
    (state: ApplicationState) => state.lanSoft,
    LanSoftStore.actionCreators
)(CopierCounterStatements as any);

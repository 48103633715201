import { ItContract, ItTicketKind, ItTicketState } from "../models";

export function getItContractDeviceCount(contract: ItContract) {
    return (
        contract.pcCount +
        contract.notebookCount +
        contract.networkingDeviceCount +
        contract.serverCount +
        contract.nasCount +
        contract.miscellaneousCount
    );
}

export function getActiveItTicketCount(copierContracts: ItContract[]) {
    return copierContracts.reduce((a, b) => (a + b.tickets.filter(x => x.kind === ItTicketKind.Submitted && x.state === ItTicketState.Open).length), 0);
}

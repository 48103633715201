import * as React from 'react';
import { CopierSupportTicket } from "../models";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import moment from 'moment';

interface CopierSupportTicketModalProps {
    open: boolean;
    ticket: CopierSupportTicket | null;
    contractNumber: string;
    color: boolean;
    close: () => void;
}

export const CopierSupportTicketModal = (props: CopierSupportTicketModalProps) => (
    <Modal isOpen={props.open} toggle={props.close} className="modal-dialog-centered">
        <ModalHeader className="lansoft-success" toggle={props.close}>Hibajegy részletei</ModalHeader>
        <ModalBody>
            {props.ticket != null &&
                <React.Fragment>
                    <div>
                        <label className="h6">Szerződésszám</label>
                        <div>{props.contractNumber}</div>
                    </div>
                    <div className="mt-3">
                        <label className="h6">Létrehozva</label>
                        <div>{moment(props.ticket.createdAt).format("YYYY.MM.DD HH:mm")}</div>
                    </div>
                    <div className="mt-3">
                        <label className="h6">Fekete számláló</label>
                        <div>{props.ticket.blackAndWhiteCounterValue}</div>
                    </div>
                    {props.color &&
                        <div className="mt-3">
                            <label className="h6">Színes számláló</label>
                            <div>{props.ticket.colorCounterValue}</div>
                        </div>
                    }
                    <div className="mt-3">
                        <label className="h6">Ügyfél kérése</label>
                        <div>{props.ticket.ticketType!!.name}</div>
                    </div>
                    <div className="mt-3">
                        <label className="h6">Üzenet a technikusnak</label>
                        <div>{props.ticket.messageToTechnician}</div>
                    </div>
                    {props.ticket.solutionType != null &&
                        <div className="mt-3">
                            <label className="h6">Megoldás típusa</label>
                            <div>{props.ticket.solutionType!!.name}</div>
                        </div>}
                    {props.ticket.isSolved &&
                        <div className="mt-3">
                            <label className="h6">Megoldva</label>
                            <div>{moment(props.ticket.solvedAt).format("YYYY.MM.DD HH:mm")}</div>
                        </div>}
                    {props.ticket.isSolved &&
                        <div className="mt-3">
                            <label className="h6">Üzenet az ügyfélnek</label>
                            <div>{props.ticket.messageToClient}</div>
                        </div>}
                </React.Fragment>
            }
        </ModalBody>
    </Modal>
);

export default CopierSupportTicketModal;
